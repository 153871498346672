import validate from "../../../helpers/validate.ts";
import DirectorModel from "../models/Director.ts";

export default async function validateDirector(
  director: DirectorModel
): Promise<true | string> {

  /* Validate data */
  for(const validation of [
    validate("personName", director.fullName, "Director full name"),
    validate("dateOfBirth", new Date(director.dateOfBirth), "Director date of birth"),

    validate("comment256", director.personalData.citizenShip, "Director citizenship"),
    validate("comment256", director.personalData.passport.number, "Director passport number"),
    validate("pastDate", new Date(director.personalData.passport.issuedAt), "Director passport issuing date"),
    validate("comment256", director.personalData.passport.issuedBy, "Director passport IA"),
    validate("date", new Date(director.personalData.passport.expiringDate), "Director passport expiry date"),
    validate("comment256", director.personalData.residenceAddress, "Director residence address"),
    validate("comment256", director.personalData.residenceCountry, "Director residence country"),
  ]) {
    if(typeof validation === "string") {
      return validation;
    }
  }

  /* Check passport dates */
  const passportIssuedAt = new Date(director.personalData.passport.issuedAt);
  const passportExpiryDate = new Date(director.personalData.passport.expiringDate);

  if(passportExpiryDate < passportIssuedAt || passportExpiryDate < new Date()) {
    return 'Director passport is expired'
  }

  /* Return true if all correct */
  return true;
}
