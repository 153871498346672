import { fetch, FetchParams } from './../../utils/fetch.ts';
import {
  CardStatus,
  ICardBalanceDto,
  ICardDataDto,
  ICardDto,
  ICardTransactionDto
} from './interfaces';
import apiClient from '../../helpers/apiClient.ts';
import onError from '../../helpers/onError.ts';
import { IDepositDto } from './interfaces/deposit.interface.ts';
import { AxiosResponse } from 'axios';
import { detailCardStatusToCardStatusMap } from './consts';

export class CardApiService {
  public async getCards(updateCardsStatuses: boolean = true): Promise<ICardDto[]> {
    const response = await fetch('/cards', {
      method: 'GET'
    });

    if (!response?.ok) {
      throw new Error(`Failed to fetch cards: ${response.statusText}`);
    }
    
    const cards = (await response.json())?.cards ?? [];
    if (!updateCardsStatuses) return cards;
    return this._updateCardsStatuses(cards);
  }
  
  public async getTransactions(cardId: string, params?: FetchParams): Promise<ICardTransactionDto[]> {
    const response = await fetch(`/cards/${cardId}/transactions`, {
      method: 'GET',
      ...params,
    });

    if (!response?.ok) {
      throw new Error(`Failed to fetch transactions: ${response.statusText}`);
    }

    return (await response.json())?.transactions ?? [];
  }

  public async getBalance(cardId: string, params?: FetchParams): Promise<ICardBalanceDto | null> {
    const response = await fetch(`/cards/${cardId}/balance`, {
      method: 'GET',
      ...params,
    });

    if (!response?.ok) {
      throw new Error(`Failed to fetch balance: ${response.statusText}`);
    }

    return (await response.json()) ?? null;
  }

  public async getDetailsCardData(cardId: string, params?: FetchParams): Promise<ICardDataDto | null> {
    const response = await fetch(`/cards/${cardId}`, {
      method: 'GET',
      ...params,
    });

    if (!response?.ok) {
      throw new Error(`Failed to freeze card details: ${response.statusText}`);
    }

    return (await response.json()) ?? null;
  }
  
  public async lockCard(cardId: string): Promise<void> {
    const response = await fetch(`/cards/${cardId}/freeze`, {
      method: 'POST',
      successMessage: 'Card successfully locked!'
    });
    
    if (!response?.ok) {
      throw new Error(`Failed lock: ${response.statusText}`);
    }
    
    return;
  }
  
  public async unlockCard(cardId: string): Promise<void> {
    const response = await fetch(`/cards/${cardId}/unfreeze`, {
      method: 'POST',
      successMessage: 'Card successfully unlocked!'
    });
    
    if (!response?.ok) {
      throw new Error(`Failed unlock card: ${response.statusText}`);
    }
    
    return;
  }
  
  public async setCardPin(cardId: string, pin: string): Promise<void> {
    const response = await fetch(`/cards/${cardId}/pin`, {
      method: 'POST',
      body: JSON.stringify({ pin }),
      successMessage: 'Pin was successfully changed!',
    });
    
    if (!response?.ok) throw new Error(response.statusText);
    return;
  }
  
  public async getDeposit(cardId: string): Promise<IDepositDto> {
    try {
      const { data } = await apiClient.post(`/cards/${cardId}/deposit`) as AxiosResponse<IDepositDto>;
      return data;
    } catch (err) {
      onError(err);
      throw err;
    }
  }
  
  private async _updateCardsStatuses(inputCards: ICardDto[]): Promise<ICardDto[]> {
    const cards = inputCards.map(c => ({ ...c }));
    const requests = cards
      .reduce((res, { id }) => {
        res.push(cardApiService.getDetailsCardData(id) as Promise<ICardDataDto>);
        return res;
      }, [] as Promise<ICardDataDto>[]);
    
    try {
      const results = await Promise.allSettled(requests);
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const findCard = cards.find(c => c.cardType === result.value.cardType);
          if (findCard) findCard.status = detailCardStatusToCardStatusMap[result.value.status] ?? findCard.status;
        }
      });
      return cards;
    } catch {
      return cards;
    }
  }
}

export const cardApiService: CardApiService = new CardApiService();
