import {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BgIcon from "../../../../../components/BgIcon";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import TextInput from "../../../../../components/TextInput";
import getMe from "../../../../../controllers/users/getMe.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import validate from "../../../../../helpers/validate.ts";
import styles from "./index.module.css";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function KycFinal() {
  if(!mainStore.user) return null;

  /* DOM */
  return (
    <div className={styles.KycFinal}>
      <img src="/images/vplus-3d.png" alt="" />

      <div className={styles.textContents}>

        <div className="big-heading">Congratulations!</div>

        <div style={{height: 10}} />
        <div style={{fontWeight: "bold"}}>
          We acknowledge the submission of your KYC form on our VPlus platform.
        </div>

        <div style={{height: 10}} />
        <div style={{fontWeight: "bold"}}>
          Our compliance team will now conduct a review of the provided information. Please expect this process to be
          completed within the next 3-5 business days. You will receive further communication via email once the review
          is finalized.
        </div>

        <div style={{height: 10}} />
        <div style={{fontWeight: "bold"}}>
          Please press the 'Back to Profile' button to return to the account's home page.
        </div>
      </div>
    </div>
  );
}
