import classNames from "classnames";
import React, {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BgIcon from "../../../../../components/BgIcon";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import TextInput from "../../../../../components/TextInput";
import getMe from "../../../../../controllers/users/getMe.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import validate from "../../../../../helpers/validate.ts";
import styles from "./index.module.css";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function IGeneralStep() {
  if(!mainStore.user || (mainStore.user.type !== "individual")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    fullName: mainStore.user.fullName,
    dateOfBirth: mainStore.user.dateOfBirth,
    email: mainStore.user.email,
    phoneNumber: mainStore.user.phoneNumber,
  };
  /* Vars */
  const isSubmitAvailable = true

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [verificationCodes, setVerificationCodes] = useState({
    email: '',
    phoneNumber: '',

    isFormShown: false as boolean,

    isEmailSent: false as boolean,
    isSmsSent: false as boolean,
  });

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
      .then(() => {

        /* Validate client data */
        for(const validation of [
          validate("fullName", formState.fullName, "Full name"),
          validate("dateOfBirth", new Date(formState.dateOfBirth), "Date of birth")
        ]) {
          if(typeof validation === "string") {
            throw new Error(validation);
          }
        }
      })
      .then(() => {

        /* Send API request */
        return apiClient.post("/users/edit", {
          fullName: formState.fullName,
          dateOfBirth: formState.dateOfBirth
        });
      })
      .then(() => {

        /* Send submit request */
        return apiClient.post("/users/submitKyc", {
          kycStep: "general"
        });
      })
      .then(() => {

        /* On done */
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Success`,
          contents: `New data sent to our admins for verification`
        });

        /* Change step */
        $setSearchParams(new URLSearchParams({
          ...$searchParams,
          step: "personal"
        }));

        /* Update user data */
        getMe()
          .catch(onError);
      })
      .catch(onError)
      .finally(() => {
        setIsFormPending(false);
      });
  }

  /* Send E-Mail verification function */
  function sendEmail() {

    if(isFormPending) return
    setIsFormPending(true)

    apiClient.post('/emailConfirmations/create', {
      email: formState.email
    })
      .then(() => {
        setVerificationCodes({
          ...verificationCodes,
          isEmailSent: true
        })
      })
      .catch()
      .finally(() => {
        setIsFormPending(false)
      })
  }

  /* Send SMS verification function */
  function sendSms() {

    if(isFormPending) return
    setIsFormPending(true)

    apiClient.post('/smsConfirmations/create', {
      phoneNumber: formState.phoneNumber,
    })
      .then(() => {
        setVerificationCodes({
          ...verificationCodes,
          isSmsSent: true
        })
      })
      .catch()
      .finally(() => {
        setIsFormPending(false)
      })
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          General Information
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Full Name"
              value={formState.fullName}
              onChange={(value) => setFormState({...formState, fullName: value})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Date of Birth (YYYY-MM-DD)"
              value={formState.dateOfBirth}
              onChange={(value) => setFormState({...formState, dateOfBirth: value})}
              isReadOnly={isFormPending || !isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">
            <TextInput
              placeholder="E-Mail"
              value={formState.email}
              onChange={(value) => setFormState({...formState, email: value})}
              // isReadOnly={isFormPending || !isSubmitAvailable}
              isReadOnly={true}
            />

            <TextInput
              placeholder="Phone Number"
              value={formState.phoneNumber}
              onChange={(value) => setFormState({...formState, phoneNumber: value})}
              // isReadOnly={isFormPending || !isSubmitAvailable}
              isReadOnly={true}
            />
          </div>

          {/* <div style={{height: 20}} />

          {verificationCodes.isFormShown ? (
            <React.Fragment>
              <div style={{height: 30}} />
              <div className={classNames("white-block", styles.verificationBlock)}>
                <TextInput
                  placeholder="E-Mail verify"
                  rightContents={
                    !verificationCodes.isEmailSent ? (
                      <Button
                        type="button"
                        additional={{isZeroed: true}}
                        onClick={() => sendEmail()}
                      >
                        Send code
                      </Button>
                    ) : null
                  }
                  isReadOnly={isFormPending || !verificationCodes.isEmailSent}
                  value={verificationCodes.email}
                  onChange={(value) => setVerificationCodes({...verificationCodes, email: value})}
                />

                <div style={{height: 20}} />

                <TextInput
                  placeholder="Phone verify"
                  rightContents={
                    !verificationCodes.isSmsSent ? (
                      <Button
                        type="button"
                        additional={{isZeroed: true}}
                        onClick={() => sendSms()}
                      >
                        Send code
                      </Button>
                    ) : null
                  }
                  isReadOnly={isFormPending || !verificationCodes.isSmsSent}
                  value={verificationCodes.phoneNumber}
                  onChange={(value) => setVerificationCodes({...verificationCodes, phoneNumber: value})}
                />
              </div>
            </React.Fragment>
          ) : (
            <Button
              additional={{isAutoWidth: true, isGray: true}} onClick={() => {
              setVerificationCodes({...verificationCodes, isFormShown: true})
            }}
            >
              Verify
            </Button>
          )} */}
        </div>
      </div>

      <div style={{height: 60, flexShrink: 0}} />
      <div style={{height: "100%"}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon
              icon={isSubmitAvailable ? "arrow-right-lined" : "check-mark-lined"}
              background="var(--soft-green)"
            />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
