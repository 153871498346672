import apiClient from '../../helpers/apiClient.ts';
import onError from '../../helpers/onError.ts';

export class ChangePasswordApiService {
  public async changePassword(payload: IChangePasswordPayload): Promise<void> {
    try {
      await apiClient.post('/users/changePassword', { ...payload });
    } catch (err) {
      onError(err);
      throw err;
    }
  }
  
  public async validatePassword(password: string): Promise<void> {
    try {
      await apiClient.post('/users/validateCurrentPassword', { password });
    } catch (err) {
      onError(err);
      throw err;
    }
  }
}

export interface IChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const changePasswordApiService: ChangePasswordApiService = new ChangePasswordApiService();
