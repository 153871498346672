import apiClient from "../../helpers/apiClient.ts";
import mainStore from "../../store/main.ts";

export default async function logout() {

 try {
    await apiClient.post("/users/logout");
  }
  catch {}

  mainStore.setSessionToken(null);
  mainStore.setUser(null);
}
