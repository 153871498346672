import { makeAutoObservable, runInAction } from "mobx";
import { IDepositDto, cardApiService } from '../services/card-api';

export class DepositStore {
  public isLoading = false;
  public data: IDepositDto | null = null;
  
  public constructor() {
    makeAutoObservable(this);
  }
  
  public async loadWalletAddress(cardId: string) {
    this.setLoading(true);
    try {
      const depositData = await cardApiService.getDeposit(cardId);
      runInAction(() => {
        this.setData(depositData);
      });
    } catch {
      runInAction(() => {
        this.setData(null);
      });
    } finally {
      this.setLoading(false);
    }
  }
  
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  
  public setData(data: IDepositDto | null) {
    this.data = data;
  }
}

export function createDepositStore(): DepositStore {
  return new DepositStore();
}
