import {DateTime} from "luxon";

export type ValidationRule =
  | "phoneNumber"
  | "email"
  | "password"
  | "userType"
  | "personName"
  | "fullName"
  | "date"
  | "dateOfBirth"
  | "pastDate"
  | "futureDate"
  | "comment256"
  | "comment0256"
  | "documentNumber"
  | "twoFaCode"
  | "entityId"
  | "float"
  | "clientFloat"
  | "integer"
  | "name";

export default function validate(
  rule: ValidationRule,
  entity: unknown,
  displayRuleName?: string
): true | string {

  if(rule === "phoneNumber") {
    return (
      (typeof entity === "string" && /^\+?\d{1,15}$/.test(entity)) ||
      "Invalid phone number"
    );
  }

  else if(rule === "email") {
    return (
      (
        typeof entity === "string"
        &&
        (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(
          entity)
      )
      ||
      "Invalid E-Mail"
    );
  }

  else if(rule === "password") {
    return (
      (typeof entity === "string" && (/^.{8,64}$/).test(entity)) ||
      "Password can be 8-64 symbols long"
    );
  }

  else if(rule === "userType") {
    return (
      (typeof entity === "string" && ["individual", "business"].includes(entity)) ||
      "Invalid userType"
    );
  }

  else if(rule === "personName") {
    return (
      (
        typeof entity === "string"
        &&
        (/[a-zа-яё][a-zа-яё\-]+[a-zа-яё]$/i).test(entity)
      )
      ||
      `${displayRuleName} is not valid`
    );
  }

  else if(rule === "fullName") {
    return (
      (
        typeof entity === "string"
        &&
        (/[a-zа-яё][a-zа-яё\-]+[a-zа-яё]\s[a-zа-яё][a-zа-яё\-]+[a-zа-яё]$/i).test(entity)
      )
      ||
      `${displayRuleName} is not valid`
    );
  }

  else if(rule === "date") {
    return (
      entity instanceof Date &&
      !isNaN(entity as any) ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else if(rule === "dateOfBirth") {

    return (
      entity instanceof Date &&
      !isNaN(entity as any) &&
      DateTime.fromJSDate(entity).diffNow('years').years < -18 ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else if(rule === "pastDate") {

    return (
      entity instanceof Date &&
      !isNaN(entity as any) &&
      DateTime.fromJSDate(entity) <= DateTime.now() ||
      `${displayRuleName ?? rule} is not valid`
    );
  }
  
  else if(rule === "futureDate") {
    
    return (
      entity instanceof Date &&
      !isNaN(entity as any) &&
      DateTime.fromJSDate(entity) > DateTime.now() ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else if(rule === "comment256") {
    return (
      (typeof entity === "string" &&
        entity.length >= 1 &&
        entity.length <= 256) ||
      `${displayRuleName ?? rule} can be 1-256 symbols long`
    );
  }

  else if(rule === "comment0256") {
    return (
      (typeof entity === "string" &&
        entity.length >= 0 &&
        entity.length <= 256) ||
      `${displayRuleName ?? rule} can be 0-256 symbols long`
    );
  }

  else if(rule === "documentNumber") {
    return (
      (
        typeof entity === "string"
        &&
        (/[\d\-\s]$/i).test(entity)
      )
      ||
      `${displayRuleName} is not valid`
    );
  }

  else if(rule === "twoFaCode") {
    return (
      (
        typeof entity === "string"
        &&
        (/^\d{6}$/).test(entity)
      ) ||
      `${displayRuleName ?? rule} must be 6-digit string`
    );
  }

  else if(rule === "integer") {
    return (
      (typeof entity === "number" && /\d{1,16}/.test(String(entity))) ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else if(rule === "float") {
    return (
      (typeof entity === "number" && /\d{1,16}(\.\d{1,6})?/.test(String(entity))) ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else if(rule === "clientFloat") {
    return (
      (typeof entity === "number" && /\d{1,16}(\.\d{1,2})?/.test(String(entity))) ||
      `${displayRuleName ?? rule} is not valid`
    );
  }
  
  else if (rule === "name") {
    return (
      (
        typeof entity === "string"
        &&
        (/^[a-zA-Zа-яА-ЯёЁ\-]{2,32}$/).test(entity)
      )
      ||
      `${displayRuleName ?? rule} is not valid`
    );
  }

  else {
    return `Unknown validation rule ${rule} (${displayRuleName}). Entity: ${entity}`;
  }
}
