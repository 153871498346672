import { makeAutoObservable } from 'mobx';
import { ICardTransactionDto, ICardTransactionsParams, cardApiService } from '../../../../../services/card-api';

export class CardTransactionsStore {
  public transactions: ICardTransactionDto[] = [];
  public filters: ICardTransactionsParams = {};
  public loading: boolean = false;
  
  private _abortController: AbortController | null = null;
  
  public constructor() {
    makeAutoObservable(this)
  }
  
  public async loadCardTransactions(cardId: string, params?: ICardTransactionsParams): Promise<void> {
    this.setLoading(true);
    this._resetRequest();
    try {
      const { signal } = this._abortController ?? {};
      const transactions = await cardApiService.getTransactions(cardId, { queryParams: params, signal });
      this.setTransactions(transactions);
    } catch {
      this.setTransactions([]);
    } finally {
      this.setLoading(false);
    }
  }
  
  public setFilters(filters: Partial<ICardTransactionsParams>): void {
    this.filters = { ...this.filters, ...filters };
  }
  
  public setTransactions(transactions: ICardTransactionDto[]): void {
    this.transactions = transactions;
  }
  
  public setLoading(value: boolean): void {
    this.loading = value;
  }
  
  private _resetRequest(): void {
    if (this._abortController) this._abortController.abort();
    this._abortController = new AbortController();
  }
}

export function createCardTransactionsStore() {
  return new CardTransactionsStore();
}
