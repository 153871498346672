import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {Navigate} from "react-router-dom";
import Button from "../../../../components/Button";
import getMyAccounts, {MyAccountsObj} from "../../../../controllers/accounts/getList.ts";
import onError from "../../../../helpers/onError.ts";
import AccountBlock from "../components/AccountBlock";
import styles from "./index.module.css";

export default function PaymentsLayout() {

  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams()

  /* State */
  const [accounts, setAccounts] = useState<MyAccountsObj[]>([]);

  /* Load accounts */
  useEffect(() => {
    loadAccounts()
  }, []);

  /* Load accounts function */
  function loadAccounts() {
    getMyAccounts()
    .then((accounts) => {

      if(!accounts.length) {
        return $navigate('/app/vlrestricted')
      }

      setAccounts(accounts)
    })
    .catch(onError)
  }

  /* Vars */
  const selectedAccountId = $searchParams.get('selectedAccountId');
  if(!selectedAccountId && accounts.length) {

    // loadAccounts()
    return <Navigate to={`./?selectedAccountId=${accounts[0]._id}`} />
  }

  /* DOM */
  return (
    <div className={classNames("container", "regular-page", styles.PaymentsLayout)}>

      <div style={{display: "flex"}}>
        <div className="big-heading" style={{flexShrink: 0}}>
          Payments
        </div>

        <div style={{width: "100%"}} />

        <Button additional={{isZeroed: true}} onClick={() => $navigate("/app/profile")}>
          Back to Profile
        </Button>
      </div>

      <div style={{height: 30}} />

      <div style={{display: "flex", gap: 30}}>
        <div className={classNames(styles.pageLeft)}>

          {/* <div className="flex flex-aic flex-jcsb"> */}

          {/*   <div style={{fontSize: '16pt', fontWeight: 'bold'}}>My Accounts</div> */}

          {/*   <Button */}
          {/*     additional={{isAutoWidth: true}} */}
          {/*     style={{height: 30}} */}
          {/*   > */}
          {/*     <span style={{fontSize: "10pt"}}>View details</span> */}
          {/*   </Button> */}
          {/* </div> */}

          {/* <div style={{height: 10}} /> */}

          {accounts.map((accountObj, i) => (
            <React.Fragment key={accountObj._id}>

              {i > 0 ? (<div style={{height: 20}} />) : null}

              <AccountBlock
                accountObj={accountObj}
                isSelected={selectedAccountId === accountObj._id}
                onClick={() => $setSearchParams(new URLSearchParams({
                  ...$searchParams,
                  selectedAccountId: accountObj._id,
                }))}
              />
            </React.Fragment>
          ))}
        </div>

        <div className={styles.pageRight}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
