import { FC } from 'react';
import classNames from 'classnames';
import styles from './TokenCard.module.css';

interface ITokenCardProps {
  name: string;
  imgSrc: string;
  isChain?: boolean;
  className?: string;
}

export const TokenCard: FC<ITokenCardProps> = ({ name, imgSrc, isChain, className }) => {
  return (
    <div
      className={classNames(
        styles.TokenCard,
        "tw-flex tw-flex-col tw-justify-between tw-rounded-1.5xl tw-p-5 tw-bg-secondary-200",
        className,
    )}>
      <div className="tw-flex tw-gap-1 tw-items-center tw-font-semibold">
        <img src={imgSrc} className={`tw-size-7`} alt={`token-${name}-icon`}/>
        {name}
      </div>
      <div className="tw-text-sm tw-font-semibold">{isChain ? 'Chain type' : 'Coin'}</div>
  </div>
  )
};
