import styles from "./Landing.module.scss";
import { Header } from './Header/Header.tsx';
import classNames from 'classnames';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { generateKey } from 'otp-io';
import { useNavigate } from 'react-router-dom';
import mainStore from '../../store/main.ts';

export default function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className={styles.PageContainer}>
      <div className={styles.MainBgContainerWrapper}>
        <div className={classNames(styles.MainBgContainer, 'container-xl tw-mx-auto')}></div>
      </div>
      <Header></Header>
      <section className={classNames(styles.MainSection, 'tw-relative tw-px-7.5')}>
        <div className={classNames(styles.SaveMoreSection, 'container-xl tw-mx-auto tw-relative tw-z-1')}>
          <div className={styles.SaveMore}>
            <h1 className={classNames(styles['SaveMore-Title'], 'tw-text-black-700')}>Save more, pay less</h1>
            <p className="tw-font-normal tw-mt-4.5 tw-leading-1.5 tw-text-2sm xl:tw-text-xl">
              Get your card in just a few minutes and unlock <br /> a new level of freedom with VPlus
            </p>
            <Button
              className={classNames(styles['GetMyCardBtn'], 'tw-mt-3.5 xl:tw-mt-6')}
              additional={{ isSoftGreen: true }}
              onClick={() => navigate(mainStore.user ? '/app/cards/get-my-card' : '/login/?action=signUpPhone')}
            >
              Get my card
            </Button>
          </div>
        </div>
      </section>
      <section className="tw-px-7.5">
        <div className={classNames(styles.SectionCards, 'container-xl tw-mx-auto')}>
          <div className={classNames(styles.CardGroupInfoBlock, 'tw-bg-secondary-75 tw-relative tw-z-2')}>
            <h5 className="tw-text-lg xl:tw-text-2.75xl">Virtual Cards:</h5>
            <p className="tw-font-normal tw-text-sm tw-mt-2.5 xl:tw-text-xl xl:tw-mt-5">
              Online solutions for secure and convenient <br/> transactions over the internet.
            </p>
            <div className={styles.CardGroupWatchBg}></div>
          </div>
          <div className={classNames(styles.CardGroupInfoBlock, 'tw-bg-secondary-75 tw-relative tw-z-2')}>
            <h5 className="tw-text-lg xl:tw-text-2.75xl">Physical Cards:</h5>
            <p className="tw-font-normal tw-text-sm tw-mt-2.5 xl:tw-text-xl xl:tw-mt-5">
              Offline solutions providing convenience <br/> for purchases and cash withdrawals.
            </p>
            <div className={styles.CardGroupPhoneAppBg}></div>
          </div>
        </div>
      </section>
      <section className={styles.InstallSettingsWrapper}>
        <div className="container-xl tw-mx-auto">
          <div className="tw-flex tw-gap-4 tw-items-baseline">
            <Icon icon="thin-arrow-right" className="tw-w-3.5 xl:tw-w-4.5"/>
            <div>
              <h6 className="tw-text-lg xl:tw-text-2.75xl">Instant Setup:</h6>
              <p className="tw-text-2sm tw-mt-3.5 tw-font-normal xl:tw-mt-5 xl:tw-text-xl">
                The card application process is simplified <br/>
                to a straightforward KYC form, enabling <br/>
                rapid access to the card.
              </p>
            </div>
          </div>
          <div className="tw-flex tw-gap-4 tw-items-baseline tw-mt-9">
            <Icon icon="thin-arrow-right" className="tw-w-3.5 xl:tw-w-4.5"/>
            <div>
              <h6 className="tw-text-lg xl:tw-text-2.75xl">
                Card Issuance Decision <br/>
                within 2 Days:
              </h6>
              <p className="tw-text-2sm tw-mt-3.5 tw-font-normal xl:tw-mt-5 xl:tw-text-xl">
                Prompt processing of incoming applications, <br/>
                with a card issuance decision within 48 hours.
              </p>
              <Button
                className={classNames(styles['GetMyCardBtn'], 'tw-mt-5.5 xl:tw-mt-6')}
                onClick={() => navigate(mainStore.user ? '/app/cards/get-my-card' : '/login/?action=signUpPhone')}
              >
                Get my card
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
