import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import { IconsCollection } from "./components/Icon";
import * as Sentry from "@sentry/react";

import "swiper/css";
import "./styles/variables.scss";
import "./styles/index.scss";
import "./styles/classes.scss";
import config from "./config.ts";

// Ініціалізація Sentry
if (config.nodeEnv !== "local") {
  Sentry.init({
    dsn: config.reactAppSentryDNS,
    environment: config.nodeEnv,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, // Захоплює 100% транзакцій
    tracePropagationTargets: [
      /api\.vplus\.awadakedawra\.com/,
      /api\.stage\.vpluspay\.online/,
      /api\.cards\.vpluspay\.hk/
    ],
    replaysSessionSampleRate: 0.1, // Встановлює частоту вибірки на 10%
    replaysOnErrorSampleRate: 1.0, // Вибірка при помилках на 100%
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <IconsCollection />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
