import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './index.module.css';

interface THeadProps {
  classname?: string;
  children?: ReactNode;
}

export const THead: FC<THeadProps> = (props) => {
  return (
    <th className={classNames(props.classname, styles.thead)}>
      {props.children}
    </th>
  );
};
