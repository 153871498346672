import {
  CardType, CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  CardTypeVisaVirtual
} from '../services/card-api/interfaces/card.interface.ts';

const virtualCardTypes: Partial<CardType>[] = [
  ...Object.values(CardTypeVisaVirtual),
  ...Object.values(CardTypeMastercardVirtual),
  CardTypeUnionpay.VirtualUnionpay,
  CardTypeApplePay.VirtualApplePay,
] as const;

const physicalCardTypes: Partial<CardType>[] = [
  ...Object.values(CardTypeVisaPhysical),
  ...Object.values(CardTypeMastercardPhysical)
] as const;

export const isVirtualCard = (cardType?: CardType): boolean => {
  return !!cardType && virtualCardTypes.includes(cardType);
};

export const isPhysicalCard = (cardType?: CardType): boolean => {
  return !!cardType && physicalCardTypes.includes(cardType);
};

export const cardKindString = (cardType?: CardType): 'Physical' | 'Virtual' | '' => {
  if (isPhysicalCard(cardType)) return 'Physical';
  if (isVirtualCard(cardType)) return 'Virtual';
  return '';
};
