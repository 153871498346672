import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical
} from '../../../../../services/card-api';
import styles from './CardBg.module.css';
import Loader from '../../../../../components/Loader';

interface CardBgProps {
  cardType?: CardType;
  isLoading?: boolean;
  className?: string;
  children?: ReactNode;
}

export const CardBg: FC<CardBgProps> = ({ cardType, isLoading, className, children }) => {
  return (
    <div
      className={classNames(
        styles.CardBg,
        cardType === CardTypeMastercardPhysical.PhysicalMastercard && styles.msCard,
        cardType === CardTypeVisaPhysical.PhysicalVisa && styles.visaCard,
        cardType === CardTypeUnionpay.VirtualUnionpay && styles.unionpayCard,
        cardType === CardTypeApplePay.VirtualApplePay && styles.visaApplePayCard,
        cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer && styles.mastercardVirtual,
        isLoading && styles.CardBgLoading,
        className,
        "tw-relative tw-overflow-hidden",
      )}
    >
      {isLoading && (
        <div className="tw-position-centering">
          <Loader/>
        </div>
      )}
      { children }
    </div>
  );
};
