import { makeAutoObservable, runInAction } from "mobx";
import { cardKycApiService, ICountryDto, ICountryListDto } from '../services/card-kyc-api';

class CardKycCountriesStore {
  public countries: ICountryDto[] = [];
  private isLoading: boolean = false;
  
  public constructor() {
    makeAutoObservable(this);
  }
  
  public async getCountries(): Promise<ICountryDto[]> {
    if (this.countries.length) return this.countries;
    this._setLoading(true);
    try {
      const { countries } = await cardKycApiService.getCountries() as ICountryListDto;
      runInAction(() => {
        this._setCountries(countries);
        this._setLoading(false);
      });
      return countries;
    } catch {
      runInAction(() => {
        this._setLoading(false);
        this._setCountries([]);
      });
      return [];
    }
  }
  
  private _setLoading(value: boolean): void {
    this.isLoading = value;
  }
  
  private _setCountries(value: ICountryDto[]): void {
    this.countries = value;
  }
}

export const cardKYCCountriesStore = new CardKycCountriesStore();