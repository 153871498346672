import classNames from "classnames";
import {ReactNode} from "react";
import styles from "./index.module.css";

export type Option = {
  value: string,
  contents: ReactNode
}

export default function RowSelect(props: {
  values: Option["value"][],
  options: Option[],
  onChange(values: Option["value"][]): void,
  isSingleSelect?: boolean,
  isReadOnly?: boolean
}) {

  /* Function onTrigger */
  function onTrigger(value: Option["value"]) {

    if(props.isSingleSelect) {
      return props.onChange([value]);
    }

    if(props.values.includes(value)) {
      props.onChange(props.values.filter((_value) => _value !== value));
    }
    else {
      props.onChange([...props.values, value]);
    }
  }

  /* DOM */
  return (
    <div className={classNames(styles.RowSelect, {isReadOnly: props.isReadOnly})}>

      {props.options.map((option, i) => (
        <div
          key={option.value}
          className={classNames(
            styles.pill,
            {isActive: props.values.includes(option.value)}
          )}
          onClick={() => {
            if(props.isReadOnly) return

            onTrigger(option.value)
          }}
        >
          {option.contents}
        </div>
      ))}
    </div>
  );
}
