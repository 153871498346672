import React, { FormEvent, useCallback, useImperativeHandle, useRef, forwardRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Select, { SelectOptionModel } from './../../../../../../../../components/Select2';
import TextInput from './../../../../../../../../components/TextInput';
import styles from './KYCStepThree.module.css';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import Button2 from '../../../../../../../../components/Button2';
import { cardKYCCountriesStore } from '../../../../../../../../store/card-kyc-countries.store.ts';

// @ts-ignore
const KYCStepThree = observer(forwardRef<any, any>(({ onNextStepClick, formState }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [data, setFieldValue] = formState;
  const [countryOptions, setCountryOptions] = useState<SelectOptionModel<string>[]>([]);
  
  useEffect(() => {
    setCountryOptions(cardKYCCountriesStore.countries.map(({ id, name: label }) => ({ value: id, label })));
  }, [cardKYCCountriesStore.countries]);
  
  useEffect(() => {
    cardKYCCountriesStore.getCountries();
  }, []);
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
      
    if (!data.address.country) {
      showDangerToast('Please select a address country');
      return;
    }
      
    onNextStepClick();
  };

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState[0];

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setFieldValue({ ...formState[0] });
  };
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));
  
  return (
    <div className={styles.kycStepThree}>
      <form className={styles.kycContainer} onSubmit={handleSubmit}>
        <div className={styles.leftColumn}>
          <h2>Delivery Address</h2>
        </div>
        <div className={styles.rightColumn}>
          <p className={styles.description}>
            To ensure swift delivery of your card, please provide a detailed
            address including the apartment number, if applicable. Incomplete
            addresses may lead to delivery delays.
          </p>
          <div className={styles.kycGrid}>
            <div className={styles.inputWrapper}>
              <Select
                label="Country"
                options={countryOptions}
                selected={countryOptions.find(
                  (opt) => opt.value === data.address.country
                )}
                onSelect={(option) =>
                  setNestedFieldValue('address.country', option.value)
                }
                required
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextInput
                type="text"
                name="address.line1"
                label="Delivery Address"
                value={data.address.line1}
                onChange={setFieldValue}
                minLength={3}
                required
              />
            </div>
          </div>
        </div>
        <div className={styles.controls}>
          <Button2
            ref={nextButtonRef}
            className={classNames(styles.kycBtn, 'BorderNone')}
            text="Next Step"
            icon="custom-arrow-1"
            buttonProps={{ type: 'submit' }}
            isDuctile
          />
        </div>
      </form>
    </div>
  );
}));

export default KYCStepThree;
