export const cardNumberChunks = (cardNumber: string) => {
  return cardNumber.match(/.{1,4}/g) ?? [];
}

export const formatCardNumber = (cardNumber: string) => {
  return cardNumberChunks(cardNumber).join(' ') ?? '';
}

export const maskCardNumber = (cardNumber?: string) => {
  const chunks: string[] = (cardNumber ?? '').match(/.{1,4}/g) ?? [];
  const maskedChunks = chunks.map((chunk: string, i) => {
    return i === chunks.length - 1 ? chunk : '••••';
  });
  return maskedChunks.join('');
}