import classNames from "classnames";
import {CSSProperties, useState} from "react";
import {getRandomNumber} from "../../helpers/misc.ts";
import onError from "../../helpers/onError.ts";
import Button from "../Button";
import Icon from "../Icon";
import styles from "./index.module.css";

import mainStore from "../../store/main.ts";

export default function ClickToCopy(props: {
  displayValue: string,
  valueToCopy: string,
}) {

  /* On click handler */
  function onClick() {
    window.navigator.clipboard.writeText(props.valueToCopy)
      .then(() => {
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Copied!`,
          contents: props.valueToCopy
        });
      })
      .catch(onError);
  }

  /* DOM */
  return (
    <Button
      additional={{isZeroed: true}}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      className={styles.ClickToCopy}
    >
      <Icon icon="copy-lined" />
      <span>{props.displayValue}</span>
    </Button>
  );
}
