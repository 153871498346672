import classNames from 'classnames';
import { CSSProperties, FC, HTMLAttributes, ReactNode, TdHTMLAttributes } from 'react';
import styles from './index.module.css';

interface TCellProps extends TdHTMLAttributes<unknown> {
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

export const TCell: FC<TCellProps> = (props) => {
  const { className, children, style, ...restProps } = props;
  return (
    <td
      style={props.style}
      className={classNames(props.className, styles.tCell)}
      {...restProps}
    >
      {props.children}
    </td>
  );
};
