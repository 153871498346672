import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/Loader';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createCardsStore } from '../store';
import { CardStatus } from '../../../../../services/card-api';
interface MyCardsPageGuardProps {
  children: ReactNode;
}

export const MyCardsPageGuard: FC<MyCardsPageGuardProps> = observer(({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const cardsStore = useLocalObservable(() => createCardsStore());
  
  useEffect(() => {
    const loadCards = async () => {
      const cards = await cardsStore.loadCards();
      if (cards.filter(card => card.status === CardStatus.ACTIVE).length) {
        setLoading(false);
      } else {
        navigate('/app/cards/get-my-card');
      }
    };
    
    loadCards();
  }, []);
  
  if (loading) {
    return <div className="tw-flex-centering tw-h-full tw-min-h-64">
      <Loader size={30}></Loader>
    </div>;
  }
  
  return children;
});
