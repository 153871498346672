// @ts-nocheck
import mainStore from './../store/main';
import queryString from 'query-string';
import config from '../config';
import { showDangerToast, showSuccessToast } from './../helpers/toasts.helper.ts';
import * as Sentry from "@sentry/react";

export type FetchParams = RequestInit
  & {
  queryParams?: string | Record<string, any>,
  showErrorToast?: boolean,
  successMessage?: string,
}

export const validateStatus = async (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  throw await response.text();
};

const apiFetch: (input: RequestInfo | URL, init?: FetchParams) => Promise<Response> = async (
  input: RequestInfo | URL,
  init: FetchParams = {}
) => {
  const absoluteUrl = [config.apiUrl, input.toString()]
    .map((segment) => (segment.charAt(0) === '/' ? segment.slice(1) : segment))
    .join('/');
  let body = init.body;

  const sessionData = mainStore.sessionToken;
  let headers;
  if (typeof sessionData === 'string') {
    headers = {
      ...init.headers,
      Authorization: `Bearer ${sessionData}`,
    };
  } else {
    headers = {
      ...init.headers,
      // @ts-ignore
      Authorization: `Bearer ${sessionData?.token}`
    };
  }
  
  // By default application/json
  if (init.method === 'POST' && !headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }
  
  // By default if application/json and empty body - set empty json object
  if (headers['Content-Type'] === 'application/json' && !init.body) {
    body = JSON.stringify({});
  }
  
  const qParamsString = queryString.stringify(init.queryParams ?? {}, { skipNull: false });

  // Логуємо початок запиту
  Sentry.addBreadcrumb({
    category: "fetch",
    message: `Request to ${absoluteUrl}`,
    data: {
      method: init.method || 'GET',
      url: absoluteUrl,
      queryParams: init.queryParams,
      requestBody: body,
    },
    level: "info",
  });

  const res = await fetch(absoluteUrl + (qParamsString.length ? `?${qParamsString}` : ''), { ...init, body, headers })
    .then((res) => {
      if (init.successMessage && res.ok) showSuccessToast(init.successMessage);
      return res;
    })
    .catch((error) => {
      Sentry.captureException(error); // Логуємо помилку у Sentry
      throw error;
    });

  // Логуємо відповідь
  if (res.ok) {
    Sentry.addBreadcrumb({
      category: "fetch-response",
      message: `Response from ${absoluteUrl}`,
      data: {
        url: absoluteUrl,
        responseStatus: res.status,
        responseBody: await res.clone().text(), // Клонування для отримання тіла
      },
      level: "info",
    });
  } else {
    const errorText = await res.text();
    Sentry.captureException(new Error(errorText)); // Логуємо помилкову відповідь
    if (!('showErrorToast' in init) || init?.showErrorToast) showDangerToast(errorText);
    return Promise.reject(new Error(errorText));
  }

  return res;
};

export { apiFetch as fetch };
