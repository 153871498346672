export function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min)) + min;
}

export function parseFloatFromString(value: string): {
  string: string,
  float: number
} {
  const $value = value.replace(/[^\.\d]/g, '')

  const amount = $value.replace(/\,/g, '.').endsWith('.') ? (
    $value.replace(/\./g, '')
  ) : (
    $value
  )

  return {
    string: $value,
    float: parseFloat($value)
  }
}
