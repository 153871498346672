import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styles from './PaymentForVirtualCard.module.css';
import { Link, useParams } from 'react-router-dom';
import { createCardDetailStore } from '../MyCards/store';
import { toCanvas } from 'qrcode';
import { createDepositStore } from '../../../../store/card-deposit.store.ts';
import Loader from '../../../../components/Loader';
import classNames from 'classnames';
import { CardBg } from '../components/CardBg/CardBg.tsx';
import CopyValue from '../../../../components/CopyValue';
import Icon from '../../../../components/Icon';
import { isVirtualCard } from '../../../../helpers/card-kind.helper.ts';
import { TokenCard } from '../components/TokenCard/TokenCard.tsx';

export const PaymentForVirtualCard = observer(() => {
  const { cardId } = useParams() as { cardId: string };
  const depositStore = useLocalObservable(() => createDepositStore());
  const cardStore = useLocalObservable(() => createCardDetailStore());
  
  useEffect(() => {
    depositStore.loadWalletAddress(cardId);
    cardStore.loadCardData(cardId, { loadCardData: true });
  }, []);
  
  useEffect(() => {
    const canvas = document.getElementById('WalletQrCode');
    if (depositStore.data?.usdtTrcAddress) {
      toCanvas(canvas, depositStore.data.usdtTrcAddress ?? '', { width: 108, margin: 3 });
    }
  }, [depositStore.data]);
  
  return (
    <div className={classNames(styles.PaymentForPage, 'regular-page')}>
      <section className="tw-px-10 tw-py-10 tw-rounded-2xl tw-bg-secondary-75 tw-font-semibold tw-relative">
        <h2 className={styles.SectionTitle}>Congratulations!</h2>
        {cardStore.cardData && <div className="tw-mt-6">
            <p className="tw-mb-0">
                Your virtual card is almost ready.
            </p>
            <p className="tw-mt-4 tw-mb-0">
                To proceed with your card issuance, please make a payment of
            </p>
            <div
                className="tw-flex-centering tw-rounded-1.5xl tw-py-1 tw-px-2.5 tw-w-max tw-bg-secondary-200 tw-gap-2 tw-mt-3 tw-text-3xl"
            >
                <img className="tw-size-7" src="/images/usdt-icon.svg" alt="usdt-icon"/>
                15 USDT
            </div>
            <p className="tw-mt-4 tw-mb-0">
                A decision regarding your card will be made within the next 12 hours.
            </p>
            <div className={classNames(styles.CardWrapper, 'tw-flex-centering tw-mt-7')}>
                <CardBg cardType={cardStore.cardData.cardType} />
            </div>
        </div>
        }
        {cardStore.loading && <div className="tw-position-centering"><Loader /></div> }
      </section>
      <section
        className="tw-rounded-1.5xl tw-px-10 tw-py-10 tw-bg-secondary-75 tw-font-semibold"
      >
        <h2 className={styles.SectionTitle}>
          Below are the <br />
          payment details:
        </h2>
        <div className="tw-flex tw-gap-4 tw-mt-6">
          <TokenCard name="USDT" imgSrc="/images/usdt-icon.svg" />
          <TokenCard name="TRON (TRC20)" imgSrc="/images/tron-icon.svg" isChain />
        </div>
        <div className="tw-mt-6.5">
          <div className="tw-mt-9 tw-text-sm tw-text-body">
            To deposit USDT please go to your wallet and send to the Interlace USDT wallet address below.
            To avoid typing address, copy and paste.
          </div>
          
          <div className="tw-flex tw-items-center tw-gap-4 tw-mt-5">
            {
              depositStore.isLoading
                ? <div className="tw-flex-centering tw-w-full"><Loader/></div>
                : <>
                  <div className={classNames(
                    styles.WalletQrCodeWrapper,
                    'tw-flex-centering tw-border tw-border-dark tw-rounded-2xl tw-overflow-hidden'
                  )}>
                    <canvas id="WalletQrCode"></canvas>
                  </div>
                  <div>
                    <div className="tw-text-sm tw-font-semibold tw-text-secondary">Wallet address</div>
                    <div className="tw-flex tw-gap-2.5 tw-font-semibold tw-text-body tw-mt-2.5">
                      {depositStore.data?.usdtTrcAddress}
                      <CopyValue value={depositStore.data?.usdtTrcAddress ?? ''} className={styles.CopyWalletIcon}/>
                    </div>
                  </div>
                </>
            }
          </div>
          <div className="tw-flex-1 tw-mt-6.5 tw-text-body tw-font-semibold tw-text-sm">
            <p>Be aware!</p>
            <div className="tw-mt-5">
              <p>1. Verify the deposit chain matches the chain you are sending form. Funds sent
                to an incorrect address may be irrecoverably lost.
              </p>
              <p>2. Only send USDT to this address. Depositing any other token may result in permanent
                of funds.
              </p>
            </div>
          </div>
          <div className="tw-flex tw-justify-end tw-mt-16">
            <Link
              className="tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-font-semibold"
              to="/app/cards/get-my-card"
            >
              Submit
              <div className="tw-flex-centering tw-size-8.5 tw-bg-softGreen tw-rounded-7px">
                <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
});
