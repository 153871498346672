import classNames from "classnames";
import {Navigate, useSearchParams} from "react-router-dom";
import ChangePasswordAction from "./components/ChangePasswordAction/ChangePasswordAction.tsx";
import EmailSentAction from "./components/EmailSentAction/EmailSentAction.tsx";
import PasswordResetAction from "./components/PasswordResetAction/PasswordReset.tsx";
import SignInAction from "./components/SignInAction/SignInAction.tsx";
import SignUpFinishAction from "./components/SignUpFinishAction/SignUpFinish.tsx";
import SignUpPhoneAction from "./components/SignUpPhoneAction/SignUpPhoneAction.tsx";
import SignUpSmsCodeAction from "./components/SignUpSmsCodeAction/SignUpSmsCodeAction.tsx";
import TwoFaAction from "./components/TwoFaAction/TwoFaAction.tsx";
import styles from "./Login.module.scss";

import mainStore from '../../store/main.ts'
import { PageContainer } from '../../components/PageContainer/PageContainer.tsx';

type Action =
  | "signIn"
  | "passwordReset"
  | "twoFa"
  | "signUpPhone"
  | "signUpSmsCode"
  | "signUpFinish"
  | "changePassword"
  | "emailSent"

/* Component */
export default function LoginPage() {

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const action: Action = ($searchParams.get("action") as Action | undefined) || "signIn";

  /* If already authorized, redirect to /app/profile */
  if(mainStore.sessionToken && mainStore.user) {
    return (<Navigate to="/app/profile" />);
  }

  /* DOM */
  return (
    <div className={classNames(styles.LoginPage, {
      [styles.SignInAction]: action === "signIn",
      [styles.PasswordResetAction]: action === "passwordReset",
      [styles.TwoFaAction]: action === "twoFa",
      [styles.SignUpPhoneAction]: action === "signUpPhone",
      [styles.SignUpSmsCodeAction]: action === "signUpSmsCode",
      [styles.SignUpFinishAction]: action === "signUpFinish",
      [styles.ChangePasswordAction]: action === "changePassword",
      [styles.EmailSentAction]: action === "emailSent",
    }, 'tw-mx-auto')}>
      {action === "signIn" ? (<SignInAction/>) : (
        action === "passwordReset" ? (<PasswordResetAction/>) : (
          action === "twoFa" ? (<TwoFaAction/>) : (
            action === "signUpPhone" ? (<SignUpPhoneAction/>) : (
              action === "signUpSmsCode" ? (<SignUpSmsCodeAction/>) : (
                action === "signUpFinish" ? <SignUpFinishAction/> : (
                  action === "changePassword" ? (<ChangePasswordAction/>) : (
                    action === "emailSent" ? (<EmailSentAction/>) : (
                      <Navigate to="./"/>
                    )
                  )
                )
              )
            )
          )
        )
      )}
    </div>
  );
}
