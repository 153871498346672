import { IPagination } from '../../../interfaces/pagination.interface.ts';

export enum TransactionStatus {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export enum TransactionType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
  Unknown = 'UNKNOWN',
  NewAccount = 'NEW_ACCOUNT',
  CardSale = 'CARD_SALE',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  AccountFunding = 'ACCOUNT_FUNDING',
  BalanceInquiry = 'BALANCE_INQUIRY',
  P2P = 'P2P',
  WalletTransfer = 'WALLET_TRANSFER',
  Exchange = 'EXCHANGE',
  ExchangeMargin = 'EXCHANGE_MARGIN',
  SecondPresentment = 'SECOND_PRESENTMENT',
  Chargeback = 'CHARGEBACK',
  Adjustment = 'ADJUSTMENT',
  ExchangeAdjustment = 'EXCHANGE_ADJUSTMENT',
  Maintenance = 'MAINTENANCE',
  Chargeoff = 'CHARGEOFF',
  Withholding = 'WITHHOLDING',
  CloseAccount = 'CLOSE_ACCOUNT',
  Reward = 'REWARD',
  Shipping = 'SHIPPING',
  ExpeditedShipping = 'EXPEDITED_SHIPPING',
  Addressing = 'ADDRESSING',
  Settlement = 'SETTLEMENT',
  Consolidated = 'CONSOLIDATED',
  Interest = 'INTEREST',
  Cashback = 'CASHBACK',
  Fee = 'FEE',
  MonthlyFee = 'MONTHLY_FEE',
  AnnualFee = 'ANNUAL_FEE',
  FastFund = 'FAST_FUND',
  LoadReversal = 'LOAD_REVERSAL',
  Expiry = 'EXPIRY',
  MoneyReceive = 'MONEY_RECEIVE',
  LatePaymentFee = 'LATE_PAYMENT_FEE'
}

export interface ICardTransactionsParams extends IPagination {
  fromDate?: string;
  toDate?: string;
}

export interface ICardTransactionDto {
  id: string;
  amount: number;
  localAmount: number;
  time: string;
  description: string;
  type: TransactionType
  status: TransactionStatus;
  currency: string;
  localCurrency: string;
}
