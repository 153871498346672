import {observer} from "mobx-react-lite";
import {NavLink, useNavigate} from "react-router-dom";
import Icon from "../Icon";
import styles from "./index.module.scss";

import mainStore from "../../store/main.ts";
import { PageContainer } from '../PageContainer/PageContainer.tsx';
import Button from '../Button';
import { burgerMenuStore } from '../BurgerMenu/BurgerMenu.store.ts';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

function Header() {
  const $navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className={classNames(styles.Header, {[styles.HeaderScrolled]: scrolled })}>
      <PageContainer
        innerContainerClassName="tw-flex tw-items-center tw-justify-between xl:tw-justify-start tw-gap-4 xl:tw-gap-0"
      >
        <div
          className="tw-flex tw-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => $navigate("/")}
        >
          <img src="/vplus-dark-full.svg" className="tw-h-6.25 tw-w-auto" alt="vplus-logo"/>
        </div>

        {mainStore.sessionToken &&
            <div className="tw-hidden tw-items-center tw-flex-1 xl:tw-flex">
                <nav className={styles.mainNavigation}>
                    <NavLink to="/app/cards">Cards</NavLink>
                    <NavLink to="/app/cards/get-my-card">Get my card</NavLink>
                    <NavLink to="/app/cards/compare">Compare</NavLink>
                </nav>
                <nav className={styles.topRightNavigation}>
                    <NavLink to="/app/profile" className="tw-pe-0 tw-gap-2.5">
                        <Icon icon="profile-circle" className="tw-w-6"/>
                        <span>User profile</span>
                    </NavLink>
                </nav>
            </div>
        }
        <div className="xl:tw-hidden">
          <Button
            className="tw-h-auto tw-w-auto tw-p-0 tw-bg-transparent tw-border-none"
            onClick={() => burgerMenuStore.toggleBurgerMenu()}
          >
            <Icon icon="menu" className="tw-size-4.5 tw-fill-black-700"></Icon>
          </Button>
        </div>
      </PageContainer>
    </div>
  );

  /* Return no header otherwise */
  return null;
}

export default observer(Header);
