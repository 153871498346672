import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { observer, useLocalObservable } from 'mobx-react-lite';
import Button from './../../../../../../components/Button';
import { CardStatusOverlay } from './../../components/CardStatusOverlay/CardStatusOverlay.tsx';
import { createCardsStore } from '../../../../Cards/MyCards/store';
import {
  CardStatus,
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  ICardDto
} from '../../../../../../services/card-api';
import styles from './index.module.css';
import { cardKindString, isPhysicalCard, isVirtualCard } from '../../../../../../helpers/card-kind.helper.ts';
import Loader from '../../../../../../components/Loader';

export interface CardProps {
  status: string;
  kind: string;
  cardType: CardType;
}

type CardsByTypeMap = Partial<Record<CardType, ICardDto>>;

const SelectCardType: React.FC<{
  initialCardType: string | null;
  onCardSelect: (card: CardProps) => void;
  isLoading: boolean;
}> = observer(({ initialCardType, onCardSelect, isLoading }) => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState<CardProps | null>(null);
  const [cardStatuses] = useState<{ [key: string]: string }>({});
  const cardsStore = useLocalObservable(createCardsStore);
  const [existedCardByTypeMap, setExistCardByTypeMap] =
    useState<CardsByTypeMap>({});

  const updateExistedCardStatuses = () => {
    const cardsByTypeMap: CardsByTypeMap = cardsStore.cards.reduce((res, card) => {
      res[card.cardType] = { ...card };
      return res;
    }, {} as CardsByTypeMap);
    setExistCardByTypeMap(cardsByTypeMap);
  };

  useEffect(() => {
    updateExistedCardStatuses();
  }, [cardsStore.cards]);

  useEffect(() => {
    cardsStore.loadCards();
  }, []);

  const cardNetworkByCardTypeMap: Partial<Record<CardType, string>> = {
    [CardTypeMastercardPhysical.PhysicalMastercard]: 'Mastercard Platinum',
    [CardTypeVisaPhysical.PhysicalVisa]: 'VISA Platinum',
    [CardTypeUnionpay.VirtualUnionpay]: 'UnionPay',
    [CardTypeApplePay.VirtualApplePay]: 'Mastercard Apple Pay',
    [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: 'Mastercard',
  };
  
  const handleCardClick = (cardType: CardType) => {
    if (existedCardByTypeMap[cardType] || cardsStore.loading || isLoading)
      return;
    const status = cardStatuses[cardType] || 'available';
    const card: CardProps = {
      cardType: cardType,
      kind: cardKindString(cardType),
      status,
    };
    if (status !== 'pending') {
      setSelectedCard(card);
      onCardSelect(card);
    }
  };
  
  const createNextBtnLink = (card?: ICardDto) => {
    if (!card) return null;
    const { cardType, id, status } = card;
    if (isVirtualCard(cardType) && status === CardStatus.WAITING_FOR_PAYMENT) {
      return `/app/cards/${id}/payment-for-virtual-card`;
    }
    const physicalCardMatchStatuses = status === CardStatus.WAITING_FOR_PAYMENT
      || status === CardStatus.KYCPending || status === CardStatus.KYCFailed;
    if (isPhysicalCard(cardType) && physicalCardMatchStatuses) {
      return `/app/cards/${id}/payment-for-physical-card`;
    }
    return null;
  };
  
  const createHeaderBtnLink = (card?: ICardDto) => {
    if (!card) return null;
    const { id, status } = card;
    if (status === CardStatus.ACTIVE)  return `/app/cards?cardId=${id}`;
    return null;
  };
  
  return (
    <section>
      <h2 className={styles.header}>Select the type of debit card</h2>
      <div className={styles.selectCardType}>
        {selectedCard ? (
          <div className={styles.selectedCardType}>
            <div className={styles.leftColumn}>
              <div className={styles.unselectedCards}>
                <div className={styles.miniCards}>
                  {[
                    CardTypeMastercardPhysical.PhysicalMastercard,
                    CardTypeVisaPhysical.PhysicalVisa,
                    CardTypeApplePay.VirtualApplePay,
                    CardTypeMastercardVirtual.VirtualMastercardSuperTransfer,
                  ]
                    .filter((cardType) => !existedCardByTypeMap[cardType])
                    .map((cardType) => (
                      <button
                        key={cardType}
                        className={classNames(
                          styles.miniCard,
                          cardType === CardTypeMastercardPhysical.PhysicalMastercard && styles.msCard,
                          cardType === CardTypeVisaPhysical.PhysicalVisa && styles.visaCard,
                          cardType === CardTypeApplePay.VirtualApplePay && styles.visaApplePayCard,
                          cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer && styles.mastercardVirtual,
                          selectedCard.cardType === cardType && styles.selectedMiniCard,
                        )}
                        disabled={isLoading}
                        onClick={() => handleCardClick(cardType)}
                      />
                    ))}
                </div>
              </div>
              <div className={styles.selectedCard}>
                <div
                  className={classNames(
                    styles.selectedCardImage,
                    selectedCard.cardType === CardTypeMastercardPhysical.PhysicalMastercard && styles.msCard,
                    selectedCard.cardType === CardTypeVisaPhysical.PhysicalVisa && styles.visaCard,
                    selectedCard.cardType === CardTypeApplePay.VirtualApplePay && styles.visaApplePayCard,
                    selectedCard.cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer && styles.mastercardVirtual,
                    isLoading && styles.selectedCardImageLoading,
                    "tw-relative tw-overflow-hidden",
                  )}
                >
                  { isLoading &&
                      <div className="tw-position-centering">
                        <Loader />
                      </div>
                  }
                </div>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <h2 className={styles.rightColumnTitle}>
                {selectedCard.kind} card
              </h2>
              <div className={styles.infoBlocksContainer}>
                <div
                  className={classNames(
                    styles.infoBlock,
                    styles.cardTypeBlock
                  )}>
                  <div className={styles.infoBlockText}>
                    Card network: <br />
                    {cardNetworkByCardTypeMap[selectedCard.cardType] ?? ''}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.infoBlock,
                    styles.fundingMethodBlock
                  )}>
                  <div className={styles.infoBlockText}>
                    Funding method: <br />
                    USD Tether (USDT)
                  </div>
                </div>
                <div
                  className={classNames(styles.infoBlock, styles.paymentBlock)}>
                  <div className={styles.infoBlockText}>
                    {isPhysicalCard(selectedCard.cardType)
                      ? 'Payment for goods and services, cash withdrawals'
                      : 'Payment for goods and services'
                    }
                  </div>
                </div>
              </div>

              <div>
                <Button
                  className="tw-text-sm"
                  style={{width: '120px', height: '30px'}}
                  onClick={() => navigate('/app/cards/compare')}
                  additional={{ isSoftGreen: true }}
                >
                  Learn more
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.cardColumn}>
              <h3 className={styles.cardTitle}>Physical cards</h3>
              <div className={styles.cardsList}>
                <div
                  className={classNames(styles.msCard, styles.card, {
                    [styles.CardSelectBlocked]:
                      cardsStore.loading || existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]
                  })}
                  onClick={() => handleCardClick(CardTypeMastercardPhysical.PhysicalMastercard)}>
                  {existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]?.status && (
                    <CardStatusOverlay
                      size={'sm'}
                      status={existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]!.status}
                      headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard])}
                      nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard])}
                    />
                  )}
                </div>
                <div
                  className={classNames(styles.visaCard, styles.card, {
                    [styles.CardSelectBlocked]:
                      cardsStore.loading || existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa]
                  })}
                  onClick={() => handleCardClick(CardTypeVisaPhysical.PhysicalVisa)}>
                  {existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa] && (
                    <CardStatusOverlay
                      size={'sm'}
                      status={existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa]!.status}
                      headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa])}
                      nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa])}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.cardColumn}>
              <h3 className={styles.cardTitle}>Virtual cards</h3>
              <div className={styles.cardsList}>
                <div
                  className={classNames(styles.visaApplePayCard, styles.card, {
                    [styles.CardSelectBlocked]:
                    cardsStore.loading ||
                    existedCardByTypeMap[CardTypeApplePay.VirtualApplePay]
                  })}
                  onClick={() => handleCardClick(CardTypeApplePay.VirtualApplePay)}>
                  {existedCardByTypeMap[CardTypeApplePay.VirtualApplePay] && (
                    <CardStatusOverlay
                      size={'sm'}
                      status={ existedCardByTypeMap[CardTypeApplePay.VirtualApplePay]!.status }
                      headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeApplePay.VirtualApplePay])}
                      nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeApplePay.VirtualApplePay])}
                    />
                  )}
                </div>
                <div
                  className={classNames(styles.mastercardVirtual, styles.card, {
                    [styles.CardSelectBlocked]:
                    cardsStore.loading ||
                      existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]
                  })}
                  onClick={() => handleCardClick(CardTypeMastercardVirtual.VirtualMastercardSuperTransfer)}>
                  {existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer] && (
                    <CardStatusOverlay
                      size={'sm'}
                      status={ existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]!.status}
                      headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer])}
                      nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer])}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
});

export default SelectCardType;
