import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import Icon from "../Icon";
import styles from "./index.module.css";

export type Option = {
  value: string,
  element: React.ReactNode,
}

export default function Select(props: {
  options: Option[],
  value?: Option["value"],
  onSelect(value: Option["value"]): void,
  placeholder?: string,
  isReadOnly?: boolean
}) {

  /* Refs */
  const selectVisibleRef = useRef<HTMLDivElement>(null);

  /* State */
  const [isActive, setIsActive] = useState<boolean>(false);

  /* Handle outside click */
  useEffect(() => {

    const handler = (e: MouseEvent) => {

      if(!selectVisibleRef.current?.contains(e.target as HTMLDivElement)) {
        setIsActive(false);
      }
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('click', handler)
    }

  }, [selectVisibleRef]);

  /* Vars */
  const selectedOption = (
    props.options.find(option => option.value === props.value)
  ) ?? (
    {value: '', element: ''}
  )

  /* DOM */
  return (
    <div className={classNames(styles.Select, {
      isActive,
      isReadOnly: props.isReadOnly
    })}>

      <div
        ref={selectVisibleRef}
        className={styles.selectVisible}
        onClick={() => {
          if(props.isReadOnly) return

          setIsActive(!isActive)
        }}
      >
        {props.placeholder ? (
          <div className={classNames(styles.selectPlaceholder, {isInTop: !!selectedOption.value})}>
            {props.placeholder}
          </div>
        ) : null}

        <div className={styles.selectVisibleContents}>
          {selectedOption.element}
        </div>

        <div className={styles.selectVisibleArrow}>
          <Icon icon="arrow-65" />
        </div>
      </div>

      <div className={styles.selectOptions}>

        {props.options.map((option) => (
          <div
            key={option.value}
            className={styles.selectOptionsOption}
            onClick={() => props.onSelect(option.value)}
          >
            {option.element}
          </div>
        ))}
      </div>
    </div>
  );
}
