
import { FC, ReactNode, useEffect, useState } from 'react';
import styles from './Card.module.css';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  CardTypeVisaVirtual,
  ICardBalanceDto,
  ICardDataActiveDto,
} from '../../../../../services/card-api';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import formatBalance from './../../../../../helpers/formatBalance.ts';
import { getCardTier } from './../../../../../helpers/card-tier.helper.ts';
import CopyValue from './../../../../../components/CopyValue';
import { formatCardNumber, maskCardNumber } from './../../../../../helpers/card-number.helper.ts';
import { cardKindString, isPhysicalCard, isVirtualCard } from './../../../../../helpers/card-kind.helper.ts';
import { convertCentsToCurrency } from '../../../../../helpers/convert-cents-to-currency.helper.ts';

interface CardProps {
  cardType: CardType;
  cardData?: ICardDataActiveDto | null;
  cardBalance?: ICardBalanceDto | null;
  cardSize?: 'xs' | 'sm' | 'md' | 'lg';
  styled?: 'MyCardsSelector' | 'TopUpCard' | 'Default',
 
  // Shows other parts
  isShowBalance?: boolean;
  isShowKindCard?: boolean;
  isShowCardNumber?: boolean;
  isShowCvvAndDate?: boolean;
  isShowTier?: boolean;
  isShowCvv?: boolean;
  
  // Show additional features
  isLocked?: boolean;
  isMaskedData?: boolean;
  containerClassNames?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const logoByCardTypeMap: Record<CardType, string> = {
  [CardTypeVisaVirtual.VirtualVisaSilver]: '/images/visa-icon.svg',
  [CardTypeVisaVirtual.VirtualVisaGold]: '/images/visa-icon.svg',
  [CardTypeVisaVirtual.VirtualVisaPlatinum]: '/images/visa-icon.svg',
  [CardTypeVisaPhysical.PhysicalVisa]: '/images/visa-icon.svg',
  
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardGlobalTransfer]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardSilver]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardGold]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardPlatinum]: '/images/mastercard-icon.svg',
  [CardTypeMastercardPhysical.PhysicalMastercard]: '/images/mastercard-icon.svg',
  
  [CardTypeUnionpay.VirtualUnionpay]: '/images/unionpay-icon.svg',
  [CardTypeApplePay.VirtualApplePay]: '/images/mastercard-icon.svg',
};

const cardClassNamesByCardTypeMap: Record<CardType, string> = {
  [CardTypeVisaVirtual.VirtualVisaSilver]: 'Card_Visa',
  [CardTypeVisaVirtual.VirtualVisaGold]: 'Card_Visa',
  [CardTypeVisaVirtual.VirtualVisaPlatinum]: 'Card_Visa',
  [CardTypeVisaPhysical.PhysicalVisa]: 'Card_Visa',
  
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: 'Card_VirtualMastercard',
  [CardTypeMastercardVirtual.VirtualMastercardGlobalTransfer]: 'Card_VirtualMastercard',
  [CardTypeMastercardVirtual.VirtualMastercardSilver]: 'Card_Mastercard',
  [CardTypeMastercardVirtual.VirtualMastercardGold]: 'Card_Mastercard',
  [CardTypeMastercardVirtual.VirtualMastercardPlatinum]: 'Card_Mastercard',
  [CardTypeMastercardPhysical.PhysicalMastercard]: 'Card_Mastercard',
  
  [CardTypeUnionpay.VirtualUnionpay]: 'Card_Unionpay',
  [CardTypeApplePay.VirtualApplePay]: 'Card_VisaApplePay',
};

const additionalLogoByCardTypeMap: Partial<Record<CardType, string>> = {
  [CardTypeApplePay.VirtualApplePay]: '/images/apple-pay-icon.svg',
};

export const Card: FC<CardProps> = observer(({
  cardType,
  cardData,
  cardBalance,
  cardSize = 'lg',
  isShowCardNumber = true,
  isShowCvvAndDate = true,
  isMaskedData = true,
  isShowBalance = true,
  isShowKindCard = false,
  isShowTier = true,
  isLocked = false,
  isShowCvv = false,
  styled = 'Default',
  onClick,
  children,
  containerClassNames,
}) => {
  const [cardClass, setCardClass] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  
  useEffect(() => {
    setCardClass(
      cn(styles.Card, styles['Card_' + (cardSize ?? 'lg')], styles[cardClassNamesByCardTypeMap[cardType]], {
          [styles['Card_locked']]: isLocked,
      })
    );
  }, [cardType, cardSize, isLocked]);
  
  useEffect(() => {
    const cardNumber = isMaskedData ? maskCardNumber(cardData?.number ?? '') : cardData?.number ?? '';
    setCardNumber(formatCardNumber(cardNumber));
  }, [cardData?.number, isMaskedData]);
  
  return (
    <div className={cn(styles.CardContainer, styles[styled], containerClassNames)} onClick={onClick}>
      <div className={cardClass}>
        <div className={styles.CardHeader}>
          <div className={styles.CardHeaderPaymentInfo}>
            <div className={styles.CardHeaderWrapperLogo}>
              <img src={logoByCardTypeMap[cardType]} className={styles.CardHeaderPaymentLogo} alt="Card brand logo"/>
              { additionalLogoByCardTypeMap[cardType] &&
                  <>
                      <span className={styles.VerticalLine}></span>
                      <img
                          src={additionalLogoByCardTypeMap[cardType]}
                          className={styles.CardHeaderAdditionalLogo}
                          alt="Additional logo"
                      />
                  </>
              }
            </div>
            
            {isShowTier &&
              <span className={styles.CardHeaderCardTier}>
                {cardType && getCardTier(cardType)}
              </span>
            }
          </div>
          <div className={styles.CardHeaderTitle}>
            {isShowBalance && cardBalance &&
                <>
                  {cardBalance?.originalCurrency} {formatBalance(convertCentsToCurrency(cardBalance?.amountInOriginalCurrency ?? 0))}
                </>
            }
            {isShowKindCard && cardKindString(cardType)}
          </div>
        </div>
        <div className={cn(styles.CardFooter, { [styles.CardFooter_withCvv]: isShowCvv && isVirtualCard(cardType) })}>
          {
            isShowCardNumber && <div>
              <div className={cn(styles.CardFooterItemLabel, 'tw-uppercase')}>
                CARD NUMBER
              </div>
              <div className={styles.CardFooterItemData}>
                {
                  !!cardNumber.length
                    && <>{cardNumber}
                        {!isMaskedData && <CopyValue value={cardData?.number ?? ''} className={styles.CopyIcon}></CopyValue>}
                    </>
                }
              </div>
            </div>
          }
          {
           isShowCvvAndDate &&
              <div className={styles.CardCvvDateContainer}>
                <div>
                  <div className={styles.CardFooterItemLabel}>VALID THRU</div>
                  <div className={cn(styles.CardFooterItemData, styles.CardFooterDate)}>
                    {isMaskedData ? '••/••' : cardData?.expiryMonth + '/' + cardData?.expiryYear.slice(2, 4)}
                  </div>
                </div>
                {
                  isShowCvv && isVirtualCard(cardType) &&
                    <div className="FlexCentering">
                        <div className={styles.CardFooterItemLabel}>CVV</div>
                        <div className={styles.CardFooterItemData}>
                          {isMaskedData ? '•••' : cardData?.cvv}
                        </div>
                    </div>
                }
            </div>
          }
        </div>
      </div>
      {isLocked &&
          <div className={styles.ReportUsAboutLocked}>
              <img
                  loading="lazy"
                  src="/images/lock-in-green-container-icon.svg"
                  className={styles.ReportUsIcon}
              alt="Lock with green container"
            />
            <a className={styles.ReportUsText} href={'mailto:support@vpluspay.hk'}>
                Report lost or stolen
            </a>
          </div>
      }
      {children}
    </div>
  );
});
