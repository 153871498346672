import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GetMyCardBlock from './../GetMyCardPage/components/GetMyCardBlock';
import SelectCardType, { CardProps } from './../GetMyCardPage/components/SelectCardType';
import { showDangerToast } from '../../../../helpers/toasts.helper.ts';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical
} from '../../../../services/card-api/interfaces';
import { cardKycApiService, IAreaDto  } from '../../../../services/card-kyc-api';

const cardTypesWithKYCFrom = [
  CardTypeVisaPhysical.PhysicalVisa,
  CardTypeMastercardPhysical.PhysicalMastercard,
  CardTypeUnionpay.VirtualUnionpay,
];

export const checkIsNeedCardKYCForm = (cardType: CardType) => {
  return cardTypesWithKYCFrom.some(ct => ct === cardType);
}

export default function GetMyCardPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // State for initial card type from URL
  const [initialCardType, setInitialCardType] = useState<string | null>(null);
  const [selectedCard, setSelectedCard] = useState<CardProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const goToPaymentForCard = (cardId: string) => {
    navigate(`/app/cards/${cardId}/payment-for-virtual-card`);
  }
  
  const handleClickGetMyCard = async () => {
    const cardType = selectedCard?.cardType;
    if (!cardType) {
      showDangerToast('Please Select the type of debit card');
      return;
    }
    
    if (checkIsNeedCardKYCForm(cardType)) {
      navigate(`/app/cards/kyc/${cardType}`);
      return;
    }
    
    setIsLoading(true);
    
    if (cardType === CardTypeApplePay.VirtualApplePay) {
      await sendKycVirtualApplePay();
    } else if (cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer) {
      await sendKycMasterCardVirtual(cardType);
    }
  };
  
  const sendKycVirtualApplePay = async () => {
    try {
      const cardBins = await cardKycApiService.getCardBins();
      if (!cardBins.length) {
        showDangerToast('Do not have card bins!');
        return null;
      }
      const { cardId } = await cardKycApiService
        .sendCardKYC({ cardType: CardTypeApplePay.VirtualApplePay, cardBin: cardBins[0].cardBin });
      goToPaymentForCard(cardId);
    } finally {
      setIsLoading(false);
    }
  };
  
  const sendKycMasterCardVirtual = async (cardType: CardType) => {
    const { areas } = await cardKycApiService.getAreas(cardType);
    
    const filteredArea = (areas ?? []).reduce((res, area) => {
      if (area.name && area.locations.length) {
        const filteredLocation = area.locations.filter(loc => loc.name);
        if (filteredLocation.length) res.push({ name: area.name, locations: filteredLocation });
      }
      return res;
    }, [] as IAreaDto[]);
    
    if (!filteredArea.length) {
      showDangerToast('Do not have areas with headers!');
      setIsLoading(false);
      return null;
    }
    
    const cardArea = filteredArea[0].name;
    const cardHeaderName = filteredArea[0].locations[0].name;
    const { cardId } = await cardKycApiService
      .sendCardKYC({ cardType: CardTypeMastercardVirtual.VirtualMastercardSuperTransfer, cardArea, cardHeaderName });
    goToPaymentForCard(cardId);
  }

  useEffect(() => {
    // Extract cardType from query parameters
    const queryParams = new URLSearchParams(location.search);
    const cardType = queryParams.get('cardType');
    setInitialCardType(cardType);
  }, [location.search]);

  const handleCardSelect = (card: any) => {
    setSelectedCard(card);
  };

  return (
    <div className="regular-page">
      <SelectCardType
        initialCardType={initialCardType}
        onCardSelect={handleCardSelect}
        isLoading={isLoading}
      />
      <GetMyCardBlock onClickGetMyCard={handleClickGetMyCard} isDisabledBtn={isLoading} />
    </div>
  );
}
