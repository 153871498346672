import apiClient from '../../helpers/apiClient.ts';
import onError from '../../helpers/onError.ts';
import { IExchangeRateDto } from './exchange-rate.interface.ts';
import { AxiosResponse } from 'axios';

export class ExchangeApiService {
  public async exchangeRate(): Promise<IExchangeRateDto | null> {
    try {
      const { data } = await apiClient.get(`/exchangeRate`) as AxiosResponse<IExchangeRateDto>;
      return data;
    } catch (err) {
      onError(err);
      throw err;
    }
  }
}

export const exchangeRateService: ExchangeApiService = new ExchangeApiService();
