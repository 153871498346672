import {FormEvent, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import BgIcon from "../../../../components/BgIcon";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import apiClient from "../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import {parseFloatFromString} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";

import mainStore from "../../../../store/main.ts";
import {TransferTransactionModel} from "../../../../controllers/transactions";

/* Component */
export default function TransferFundsPage() {
  if(!mainStore.user) return null;

  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    toUserEmail: '',
    amount: '',
    referenceMessage: ''
  };
  const selectedAccountId = $searchParams.get('selectedAccountId')
  if(!selectedAccountId) {
    return null
  }

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("email", formState.toUserEmail, "Recipient E-Mail"),
        validate("clientFloat", +formState.amount, "Amount"),
        validate("comment256", formState.referenceMessage, "Reference message"),
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post<TransferTransactionModel>("/transactions/createTransfer", {
        toUserEmail: formState.toUserEmail,
        amount: +formState.amount * 100,
        referenceMessage: formState.referenceMessage,
        fromAccountId: selectedAccountId
      });
    })
    .then(({data: transaction}) => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `You successfully transferred funds to ${formState.toUserEmail}`
      });

      /* Navigate to profile page */
      $navigate(`/app/transactions?selectedTransactionId=${transaction._id}`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} className="white-block" style={{padding: 30}}>
      <div className="big-heading">
        Transfer Funds
      </div>

      <div style={{height: 20}} />

      <div className="page-form-segment">

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Recipient E-Mail"
              value={formState.toUserEmail}
              onChange={(value) => setFormState({...formState, toUserEmail: value})}
              isReadOnly={isFormPending}
            />

            <TextInput
              placeholder="Amount"
              value={formState.amount}
              onChange={(value) => setFormState({...formState, amount: parseFloatFromString(value).string})}
              isReadOnly={isFormPending}
            />
          </div>

          <div style={{height: 30}} />

          <TextInput
            placeholder="Reference message"
            value={formState.referenceMessage}
            onChange={(value) => setFormState({...formState, referenceMessage: value})}
            isReadOnly={isFormPending}
          />
        </div>
      </div>

      <div style={{height: 30}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon
              icon="check-mark-lined"
              background="var(--soft-green)"
            />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
