import apiClient from "../../helpers/apiClient.ts";
import {BusinessUserModel, ExtendedUserModel, IndividualUserModel} from "./index.ts";

import mainStore from "../../store/main.ts";

export default async function getMe(): Promise<ExtendedUserModel> {

  const {data: user} = await apiClient.get<ExtendedUserModel>("/users/getMe");

  mainStore.setUser(user);

  return user;
}
