import {NavLink, useNavigate} from 'react-router-dom';
import Button from '../../components/Button';
import chatImg from './assets/images/chat.svg';
import checkImg from './assets/images/check.png';
import graphImg from './assets/images/graph.png';
import heroBubbleImg from './assets/images/hero-bubble.png';
import leafImg from './assets/images/leaf.png';
import styles from './index.module.css';

import mainStore from '../../store/main'
import classNames from 'classnames';

export default function AboutPage() {

  /* Hooks */
  const $navigate = useNavigate()

  /* DOM */
  return (
    <div className={classNames(styles.AboutPage, 'regular-page')}>

      {/* Header */}
      <div className={styles.Header}>

        <div className="container">
          <div
            className="flex flex-aic"
            style={{cursor: "pointer"}}
            onClick={() => $navigate("/")}
          >
            <img src="/vplus-light-full.svg" alt="VPlus" />
          </div>

          <nav className={styles.mainNavigation}>
            <a href="/#">
              Home
            </a>

            <a href="#why-vplus">
              Why VPlus
            </a>

            <a href="#account-opening">
              Account Opening
            </a>

            <NavLink to="/about">
              <span>About</span>
            </NavLink>
          </nav>

          <nav className={styles.topRightNavigation}>
            {mainStore.user ? (
              <NavLink to="/app/profile">
                <span>User profile</span>
              </NavLink>
            ) : (
              <NavLink to="/login">
                <span>Log In / Sign Up</span>
              </NavLink>
            )}
          </nav>
        </div>
      </div>

      <div style={{height: 100}} />

      <section className={styles.hero}>
        <div className={styles.heroOffer}>
          <h1>Welcome to VPlus</h1>
          <p>
            Where innovation meets security in the world of finance. Our
            cutting-edge payment system seamlessly integrates with the SWIFT
            network, ensuring that each transaction you initiate is not only
            secure but also compliant with the highest international standards.
          </p>
          <div className={styles.heroOfferImg}>
            <img src={heroBubbleImg} alt="bubble" />
          </div>
        </div>

        <div className={styles.heroAdvantages}>
          <div className={styles.heroCard}>
            <p>
              Security is paramount at VPlus. Our dedicated team of
              professionals works tirelessly to safeguard your funds and
              maintain the integrity of every transaction. With robust security
              measures in place, your financial information remains protected at
              all times, providing you with absolute peace of mind.
            </p>
            <img src={checkImg} alt="security" />
          </div>
          <div className={styles.heroCard}>
            <img src={graphImg} alt="graph" />
            <p>
              With VPlus, managing your finances becomes an effortless
              experience. Whether you're depositing or withdrawing funds through
              our VPlus platform, rest assured that every transaction strictly
              adheres to SWIFT's stringent security protocols. With access to
              five of the most pertinent global currencies within a single
              account, you'll enjoy unparalleled flexibility, regardless of your
              location in the world.
            </p>
          </div>
          <div className={styles.heroCard}>
            <img src={chatImg} alt="chat" />
            <p>
              And if you ever find yourself in need of assistance, fear not! Our
              support team is on standby, ready to provide you with expert
              guidance and assistance at a moment notice. With our friendly and
              knowledgeable support staff just a click away, you can trust that
              VPlus is always here to support you through every step of your
              financial journey.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.notAll}>
        <div className={styles.notAllOffer}>
          <h2>But that's not all</h2>
          <p>
            VPlus offers more than just transactions. Our platform boasts
            competitive rates and seamless currency exchange services,
            empowering you to make the most of your finances wherever life takes
            you.
          </p>
          <p>
            Join VPlus today and unlock a world of financial freedom like never
            before.
          </p>
          <Button additional={{isAutoWidth: true}}>
            <span>Initiate Your Account</span>
          </Button>
        </div>
        <div className={styles.notAllImg}>
          <img src={leafImg} alt="leaf" />
        </div>
      </section>
    </div>
  );
}
