export type Params = {
  offset?: number,
  limit?: number,
  searchQuery?: string,
  [key: string]: unknown
}

type Opt = {
  [key: string]: (entity: unknown) => string | true
} | undefined

export default function validateGetListParams<AddType>(
  params: Params & AddType,
  opt: Opt
): (Params & AddType) | string {

  for(const validation of [

    typeof params.offset === "number" ? (
      params.offset >= 0 || "Invalid offset"
    ) : true,

    typeof params.limit === "number" ? (
      (params.limit >= 0 && params.limit <= 50) || "Invalid limit"
    ) : true,

    typeof params.searchQuery === "string" ? (
      (params.searchQuery.trim().length > 3) || "Invalid searchQuery"
    ) : true,

    ...(Object.entries(params).map(([key, value]) => {
      if(["offset", "limit", "searchQuery"].includes(key)) return true;

      const validateFn = opt?.[key];
      if(!validateFn) return true;

      return validateFn(value);
    }))

  ]) {
    if(typeof validation === "string") {
      return validation;
    }
  }

  return {
    ...params,
    offset: params.offset ?? 0,
    limit: params.limit ?? 50,
    searchQuery: params.searchQuery?.trim() ?? undefined
  };
}
