import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import RowSelect from "../../../../../components/RowSelect";
import getMe from "../../../../../controllers/users/getMe.ts";
import UboModel from "../../../../../controllers/users/models/Ubo.ts";
import {ExtendedUboModel} from "../../../../../controllers/users/models/Ubo.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import Ubo, {initialUbo} from "./components/Ubo";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function BUbosStep() {
  if(!mainStore.user || (mainStore.user.type !== "business")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    ubos: mainStore.user.ubos.length ? (
      mainStore.user.ubos
    ) : (
      [initialUbo as ExtendedUboModel]
    )
  };

  /* Vars */
  const isSubmitAvailable = !(
    mainStore.user.filledKycs.includes("ubos")
    ||
    mainStore.user.acceptedKycs.includes("ubos")
  );

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [selectedUboIndex, setSelectedUboIndex] = useState<number>(0);

  /* On form submit function */
  function onFormSubmit() {

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Send API request */
      return apiClient.post("/users/edit", {
        ubos: formState.ubos
      });
    })
    .then(() => {

      /* Send submit request */
      return apiClient.post("/users/submitKyc", {
        kycStep: "ubos"
      });
    })
    .then(() => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `New data sent to our admins for verification`
      });

      /* Change step */
      $setSearchParams(new URLSearchParams({
        ...$searchParams,
        step: "financial"
      }));

      /* Update user data */
      return getMe();
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* Vars */
  const selectedUbo = formState.ubos[selectedUboIndex] as UboModel | undefined

  /* DOM */
  return (
    <React.Fragment>

      <div className="flex">
        <RowSelect
          values={[String(selectedUboIndex)]}
          options={formState.ubos.map((ubom, i) => (
            {value: String(i), contents: `UBO ${i + 1}`}
          ))}
          onChange={([index]) => setSelectedUboIndex(+index)}
          isSingleSelect={true}
        />

        {formState.ubos.length < 5 && isSubmitAvailable ? (
          <Button
            additional={{isAutoWidth: true, isSoftGreen: true}}
            onClick={() => setFormState({...formState, ubos: [...formState.ubos, initialUbo]})}
          >
            <Icon icon="plus-lined" />
          </Button>
        ) : null}
      </div>

      <div style={{height: 30}} />
      {formState.ubos.map((ubo, i) => (
        <Ubo
          key={i}
          isActive={selectedUboIndex === i}
          ubo={ubo}
          isSubmitAvailable={isSubmitAvailable}
          onDelete={i > 0 ? (() => {
            const $ubos = [...formState.ubos]

            $ubos.splice(i, 1)

            setFormState({...formState, ubos: $ubos})

            if(i === selectedUboIndex) {
              setSelectedUboIndex(0)
            }
          }) : undefined}
          isPending={isFormPending}
          onUpdate={(ubo) => {
            formState.ubos[i] = ubo;
            onFormSubmit()
          }}
        />
      ))}
    </React.Fragment>
  );
}
