import classNames from "classnames";
import React, {FormEvent, useState} from "react";
import BgIcon from "../../../../../../../components/BgIcon";
import Button from "../../../../../../../components/Button";
import FileUploadBlock from "../../../../../../../components/FileUploadBlock";
import Loader from "../../../../../../../components/Loader";
import Select from "../../../../../../../components/Select";
import TextInput from "../../../../../../../components/TextInput";
import {IndividualUserModel} from "../../../../../../../controllers/users";
import validateDirector from "../../../../../../../controllers/users/helpers/validateDirector.ts";
import {ExtendedDirectorModel} from "../../../../../../../controllers/users/models/Director.ts";
import countriesOptions from "../../../../../../../helpers/countriesOptions.ts";
import onError from "../../../../../../../helpers/onError.ts";
import styles from "./index.module.css";

export const initialDirector = {

  fullName: "",
  dateOfBirth: "",

  personalData: {
    documentType: "",
    citizenShip: "",
    passport: {
      number: "",
      issuedAt: "",
      issuedBy: "",
      expiringDate: "",
    },
    residenceAddress: "",
    residenceCountry: "",
    documents: {
      documentProofFileId: null,
      addressProofFileId: null
    }
  } as ExtendedDirectorModel["personalData"]
};

/* Component */
export default function Director(props: {
  director: ExtendedDirectorModel,
  isSubmitAvailable: boolean,
  isActive: boolean,
  isPending?: boolean,
  onDelete?(): void,
  onUpdate(director: ExtendedDirectorModel): void
}) {

  /* State */
  const [director, setDirector] = useState<typeof props.director>(props.director);
  const [uploadedFiles, setUploadedFiles] = useState({
    personalData: {
      documents: {
        documentProof: director.personalData.documents.documentProof,
        addressProof: director.personalData.documents.addressProof
      }
    }
  });

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if(props.isPending) return

    Promise.resolve()
    .then(() => {

      /* Validate director */
      return validateDirector(director)
    })
    .then((validation) => {

      if(typeof validation === 'string') {
        throw new Error(validation)
      }
    })
    .then(() => {

      props.onUpdate({
        ...director,

        personalData: {
          ...director.personalData,

          documents: {
            ...director.personalData.documents,

            documentProofFileId: uploadedFiles.personalData.documents.documentProof?._id as string | null,
            addressProofFileId: uploadedFiles.personalData.documents.addressProof?._id as string | null,
          }
        }
      })
    })
    .catch(onError)
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} className={classNames(styles.Director, {isHidden: !props.isActive})}>

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Personal Information
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Full Name"
              value={director.fullName}
              onChange={(value) => setDirector({
                ...director,
                fullName: value
              })}
              isReadOnly={props.isPending}
            />

            <TextInput
              placeholder="Date of Birth (YYYY-MM-DD)"
              value={director.dateOfBirth}
              onChange={(value) => setDirector({
                ...director,
                dateOfBirth: value
              })}
              isReadOnly={props.isPending}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">

            <Select
              options={[
                {
                  value: "passport", element: "Passport"
                }, {
                  value: "idCard", element: "ID Card"
                }
              ]}
              onSelect={(type: IndividualUserModel["personalData"]["documentType"]) => setDirector({
                ...director,
                personalData: {
                  ...director.personalData,
                  documentType: type
                }
              })}
              value={director.personalData.documentType}
              placeholder={"Document Type"}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />

            <TextInput
              placeholder="Citizenship"
              value={director.personalData.citizenShip}
              onChange={(value) => setDirector({
                ...director,
                personalData: {...director.personalData, citizenShip: value}
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50, flexShrink: 0}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          {director.personalData.documentType === 'passport' ? 'Passport' : 'ID Card'}
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Number"
              value={director.personalData.passport.number}
              onChange={(value) => setDirector({
                ...director,
                personalData: {
                  ...director.personalData,
                  passport: {...director.personalData?.passport, number: value}
                }
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />

            <TextInput
              placeholder="Issuing Authority"
              value={director.personalData.passport.issuedBy}
              onChange={(value) => setDirector({
                ...director,
                personalData: {
                  ...director.personalData,
                  passport: {...director.personalData?.passport, issuedBy: value}
                }
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">
            <TextInput
              placeholder="Date of Issue (YYYY-MM-DD)"
              value={director.personalData.passport.issuedAt}
              onChange={(value) => setDirector({
                ...director,
                personalData: {
                  ...director.personalData,
                  passport: {...director.personalData?.passport, issuedAt: value}
                }
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />

            <TextInput
              placeholder="Date of Expiry (YYYY-MM-DD)"
              value={director.personalData.passport.expiringDate}
              onChange={(value) => setDirector({
                ...director,
                personalData: {
                  ...director.personalData,
                  passport: {...director.personalData?.passport, expiringDate: value}
                }
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">

            <Select
              options={countriesOptions}
              onSelect={(value) => {
                setDirector({
                  ...director,
                  personalData: {
                    ...director.personalData,
                    residenceCountry: value
                  }
                });
              }}
              placeholder="Country of Residence"
              value={director.personalData.residenceCountry}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />

            <TextInput
              placeholder="Residence address"
              value={director.personalData.residenceAddress}
              onChange={(value) => setDirector({
                ...director,
                personalData: {...director.personalData, residenceAddress: value}
              })}
              isReadOnly={props.isPending || !props.isSubmitAvailable}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50, flexShrink: 0}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Documents
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <FileUploadBlock
              file={uploadedFiles.personalData.documents.documentProof ?? undefined}
              title={"ID / Passport\nCopy"}
              icon="custom-scanner-1"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, personalData: {
                  ...uploadedFiles.personalData,
                  documents: {
                    ...uploadedFiles.personalData.documents,
                    documentProof: file
                  }
                }
              })}
            />

            <FileUploadBlock
              file={uploadedFiles.personalData.documents.addressProof ?? undefined}
              title={"Address\nproof"}
              icon="custom-scanner-1"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, personalData: {
                  ...uploadedFiles.personalData,
                  documents: {
                    ...uploadedFiles.personalData.documents,
                    addressProof: file
                  }
                }
              })}
            />
          </div>
        </div>
      </div>

      <div style={{height: 30, flexShrink: 0}} />

      <div className="flex">

        {props.onDelete ? (
          <Button
            additional={{isZeroed: true}}
            disabled={props.isPending || !props.isSubmitAvailable}
            onClick={() => props.onDelete?.()}
          >

            {!props.isPending ? (
              <BgIcon
                icon="trash-can-27"
                isLightIcon={true}
                background={props.isSubmitAvailable ? "var(--red)" : "#ddd"}
              />
            ) : (
              <Loader spinsPerSecond={3} />
            )}

            <span>Delete director</span>
          </Button>
        ) : null}

        <Button
          additional={{isZeroed: true}}
          disabled={props.isPending || !props.isSubmitAvailable}
          style={{marginLeft: "auto"}}
        >
          <span>Submit</span>

          {!props.isPending ? (
            <BgIcon icon="arrow-right-lined" background={props.isSubmitAvailable ? "var(--soft-green)" : "#ddd"} />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
