import { useState, useEffect } from 'react';
import { getBreakpoint } from '../helpers/breakpoints.ts';

export const useBreakpoint = () => {
  const getScreenSize = () => {
    return getBreakpoint(window.innerWidth);
  };

  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      const newScreenSize = getScreenSize();
      if (newScreenSize !== screenSize) {
        setScreenSize(newScreenSize);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize]);

  return screenSize;
};