import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './PaymentForPhysicalCard.module.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { createCardDetailStore } from '../MyCards/store';
import { toCanvas } from 'qrcode';
import { createDepositStore } from '../../../../store/card-deposit.store.ts';
import Loader from '../../../../components/Loader';
import classNames from 'classnames';
import { CardBg } from '../components/CardBg/CardBg.tsx';
import CopyValue from '../../../../components/CopyValue';
import Icon from '../../../../components/Icon';
import { TokenCard } from '../components/TokenCard/TokenCard.tsx';
import {
  CardDataActiveStatus,
  CardDataNotActiveStatus,
  detailCardStatusToCardStatusMap,
  ICardDataDto
} from '../../../../services/card-api';
import { StepBlockInfo } from './components/StepBlockInfo/StepBlockInfo.tsx';
import { CardStatusOverlay } from '../GetMyCardPage/components/CardStatusOverlay/CardStatusOverlay.tsx';

export const PaymentForPhysicalCard = observer(() => {
  const { cardId } = useParams() as { cardId: string };
  const depositStore = useLocalObservable(() => createDepositStore());
  const cardStore = useLocalObservable(() => createCardDetailStore<ICardDataDto>());
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [initialLoader, setInitialLoader] = useState<boolean>(true);
  
  useEffect(() => {
    let intervalId: number;
    let isFirstLoaded: boolean = false;
    const loadCardData = async () => {
      await cardStore.loadCardData(cardId, { loadCardData: true });
      setInitialLoader(false);
      if (!cardStore.cardData) return;
      
      if (!isFirstLoaded) {
        if (cardStore.cardData.status === CardDataActiveStatus.Active) {
          setActiveStep(3);
        } else if (
          cardStore.cardData.status === CardDataNotActiveStatus.WaitingForPayment
          && getPhysicalPaymentCards()[cardId]?.showDepositStep
        ) {
          setActiveStep(2);
        } else {
          setActiveStep(1);
        }
        isFirstLoaded = true;
      }
      
      if (cardStore.cardData.status === CardDataNotActiveStatus.VerificationFailed
        || cardStore.cardData.status === CardDataActiveStatus.Active
      ) {
        clearInterval(intervalId);
      }
    };
    
    const startPolling = () => {
      loadCardData();
      // Weird problem ts - Type 'Timeout' is not assignable to type 'number'.
      intervalId = setInterval(loadCardData, 1000 * 60) as unknown as number;
    };
    
    startPolling();
    
    return () => clearInterval(intervalId);
  }, []);
  
  useEffect(() => {
    if (activeStep === 2 && !depositStore.data) {
      depositStore.loadWalletAddress(cardId);
    }
  }, [activeStep]);
  
  useEffect(() => {
    const canvas = document.getElementById('WalletQrCode');
    if (depositStore.data?.usdtTrcAddress) {
      toCanvas(canvas, depositStore.data.usdtTrcAddress ?? '', { width: 108, margin: 3 });
    }
  }, [depositStore.data]);
  
  const getPhysicalPaymentCards: () => Record<string, { showDepositStep: boolean }> = () => {
    const storedCards = localStorage.getItem('physicalPaymentCards');
    return storedCards ? JSON.parse(storedCards) : {};
  };
  
  const updateStorePhysicalPaymentCards = (cards: Record<string, { showDepositStep: boolean }>) => {
    localStorage.setItem('physicalPaymentCards', JSON.stringify(cards));
  };
  
  const handleNextStep = () => {
    if (activeStep === 2) {
      const filteredCards = Object.fromEntries(Object.entries(getPhysicalPaymentCards())
        .filter(([key, _]) => key !== cardId));
      updateStorePhysicalPaymentCards(filteredCards);
      setActiveStep((prevState) => prevState + 1);
    } else if (activeStep === 1 && cardStore.cardData?.status === CardDataNotActiveStatus.WaitingForPayment) {
      updateStorePhysicalPaymentCards({...getPhysicalPaymentCards(), [cardId]: { showDepositStep: true }});
      setActiveStep((prevState) => prevState + 1);
    } else if (activeStep === 1 && cardStore.cardData?.status === CardDataNotActiveStatus.VerificationFailed) {
      navigate('/app/cards/get-my-card');
    }
  };
  
  const titleStepFirst = useCallback((status?: CardDataActiveStatus | CardDataNotActiveStatus) => {
    if (status === CardDataNotActiveStatus.VerificationFailed) return 'Dear Customer,';
    return 'Congratulations!'
  }, [cardStore.cardData?.status]);
  
  return (
    <div
      className={classNames(
        styles.PaymentPage,
        'regular-page tw-flex tw-gap-6 tw-justify-center'
      )}
    >
      {!initialLoader && <>
        <section
          className={classNames(
            styles.SectionStep,
            styles.SectionStepFirst,
            activeStep === 1 && styles.SectionActiveStep,
            'tw-flex tw-flex-col tw-rounded-2xl tw-bg-secondary-75 tw-font-semibold tw-relative tw-overflow-hidden'
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
            <h5 className={styles.SectionTitle}>
              {activeStep === 1 ? titleStepFirst(cardStore.cardData?.status) : 'Step'}
            </h5>
            {
              activeStep >= 2 &&
                <div className="tw-flex-centering tw-w-9.5 tw-h-8 tw-bg-softGreen tw-rounded-2lg tw-min-w-max">
                    <Icon icon={'custom-check-1'} className="tw-size-4"></Icon>
                </div>
            }
          </div>
          {activeStep === 1 &&
              <div className="tw-flex tw-flex-col tw-flex-1">
                  <div className={classNames(styles.StepFirstText, 'tw-mt-3 tw-flex-1')}>
                    {cardStore.cardData?.status === CardDataNotActiveStatus.VerificationFailed &&
                        <>
                            <p>We regret to inform you that, unfortunately, we could not approve your card
                                application.</p>
                            <p className="tw-mt-5">
                                For more detailed information, please reach out to our support team
                                at <a className="tw-text-blue tw-underline"
                                      href="mailto:support@vpluspay.hk">support@vpluspay.hk</a>.
                                We are here to assist you with any questions or concerns you may have.
                            </p>
                        </>
                    }
                    {cardStore.cardData?.status === CardDataNotActiveStatus.Pending &&
                        <>
                            <p>
                                We are pleased to inform you that you have successfully <br/>
                                completed the KYC process on our Vplus platform.
                            </p>
                            <p className="tw-mt-5">
                                Our compliance team will review your information, and you can expect a decision on your
                                card
                                within 48 hours. We will notify you via the email address you registered with.
                            </p>
                        </>
                    }
                    {cardStore.cardData?.status === CardDataNotActiveStatus.WaitingForPayment &&
                        <>
                            <p>
                                We are pleased to inform you that your card has been approved.
                            </p>
                            <p className="tw-mt-5">
                                You can now proceed to the next step and complete the payment for issuance.
                            </p>
                        </>
                    }
                  </div>
                {
                  (cardStore.cardData?.status === CardDataNotActiveStatus.WaitingForPayment
                    || cardStore.cardData?.status === CardDataNotActiveStatus.Pending) &&
                    <div
                      className={classNames(
                        styles.StepBlockList,
                        'tw-gap-4 tw-mt-7 tw-pt-9.5 tw-border-t tw-border-secondary-600 tw-w-max',
                      )}
                    >
                      {cardStore.cardData.status !== CardDataNotActiveStatus.WaitingForPayment &&
                        <StepBlockInfo icon={'clock'} iconClassName={'tw-size-3.5'}>
                          48-hour <br/>Verification
                        </StepBlockInfo>
                      }
                      <StepBlockInfo icon={'tether'} title={'150 USDT'} iconClassName={'tw-size-3'}>
                        Card<br/>issuance fee
                      </StepBlockInfo>
                      <StepBlockInfo icon={'rocket'} iconClassName={'tw-size-4'}>
                        Shipping is
                        <br/>calculated individually
                      </StepBlockInfo>
                    </div>
                }
                  <div className="tw-flex tw-justify-between tw-items-end tw-gap-4">
                      <div className={classNames(styles.CardWrapper, 'tw-flex-centering tw-relative tw-mt-6.5 tw-mb-2.5')}>
                          <CardBg cardType={cardStore.cardData?.cardType}>
                            {cardStore.cardData?.status &&
                                <CardStatusOverlay
                                    status={detailCardStatusToCardStatusMap[cardStore.cardData.status]}
                                    isShowLink={false}
                                />
                            }
                          </CardBg>
                      </div>
                      <button
                          className={classNames(
                            styles.BtnNextStep,
                            "tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-font-semibold",
                      )}
                      onClick={handleNextStep}
                      disabled={!(cardStore.cardData?.status === CardDataNotActiveStatus.WaitingForPayment
                        || cardStore.cardData?.status === CardDataNotActiveStatus.VerificationFailed)
                      }
                    >
                        {cardStore.cardData?.status === CardDataNotActiveStatus.VerificationFailed
                          ? 'Submit'
                          : 'Nest step'
                        }
                      <div className={classNames(styles.IconWrapper, 'tw-flex-centering tw-size-8.5 tw-rounded-7px')}>
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                      </div>
                    </button>
                  </div>
              </div>
          }
        </section>
      
        <section
          className={classNames(
            styles.SectionStep,
            styles.SectionStepSecond,
            activeStep === 2 && styles.SectionActiveStep,
            'tw-flex tw-flex-col tw-rounded-2xl tw-bg-secondary-75 tw-font-semibold tw-relative tw-overflow-hidden'
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
            <h5 className={styles.SectionTitle}>
              {activeStep === 2
                ? <>
                  Below are the <br/>
                  payment details:
                </>
                : 'Step'
              }
            </h5>
            {
              activeStep >= 3 &&
                <div className="tw-flex-centering tw-w-9.5 tw-h-8 tw-bg-softGreen tw-rounded-2lg tw-min-w-max">
                    <Icon icon={'custom-check-1'} className="tw-size-4"></Icon>
                </div>
            }
          </div>
          {activeStep === 2 &&
              <div className="tw-flex tw-flex-col tw-flex-1">
                  <div className="tw-flex tw-gap-4 tw-mt-6">
                    <TokenCard name="USDT" imgSrc="/images/usdt-icon.svg" className={styles.TokenCard} />
                    <TokenCard name="TRON (TRC20)" imgSrc="/images/tron-icon.svg" className={styles.TokenCard} isChain />
                  </div>
                  <div className="tw-mt-4 tw-flex-1">
                    <p>
                      To deposit USDT please go to your wallet and send to the Interlace USDT wallet address below.
                      To avoid typing address, copy and paste.
                    </p>
                    <div className="tw-flex tw-items-center tw-gap-4 tw-mt-4">
                      {
                        depositStore.isLoading
                          ? <div className="tw-flex-centering tw-w-full"><Loader/></div>
                          : <>
                            <div className={classNames(
                              styles.WalletQrCodeWrapper,
                              'tw-flex-centering tw-border tw-border-black tw-rounded-2xl tw-overflow-hidden'
                            )}>
                              <canvas id="WalletQrCode"></canvas>
                            </div>
                            <div>
                              <div className="tw-text-secondary tw-text-sm">Wallet address</div>
                              <div className="tw-flex tw-gap-2.5 tw-mt-1">
                                {depositStore.data?.usdtTrcAddress}
                                <CopyValue
                                  value={depositStore.data?.usdtTrcAddress ?? ''}
                                  className={styles.CopyWalletIcon}
                                />
                              </div>
                              <div
                                className="tw-flex-centering tw-rounded-1.5xl tw-py-1 tw-px-2.5 tw-w-max tw-bg-secondary-200 tw-gap-1 tw-mt-2 tw-text-1.5xl"
                              >
                                <img className="tw-size-5" src="/images/usdt-icon.svg" alt="usdt-icon"/>
                                150 USDT
                              </div>
                            </div>
                          </>
                      }
                    </div>
                      <div className="tw-flex-1 tw-mt-6.5 tw-text-secondary tw-leading-tight">
                          <p>Be aware!</p>
                          <p className={classNames(styles.BeAwareText, 'tw-mt-5')}>
                              1. Verify the deposit chain matches the chain you are sending form. Funds
                          sent to an incorrect address may be irrecoverably lost. <br/>
                          2. Only send USDT to this address. Depositing any other token may result
                          in permanent of funds. <br/>
                          3. Payment processing may take up to 10 minutes. Once the transaction
                          is confirmed, you will be able to proceed to the next step.
                        </p>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4">
                    <button
                      className={classNames(
                        styles.BtnNextStep,
                        "tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-font-semibold",
                      )}
                      disabled={cardStore.cardData?.status !== CardDataActiveStatus.Active}
                      onClick={handleNextStep}
                    >
                      Next step
                      <div className={classNames(styles.IconWrapper, 'tw-flex-centering tw-size-8.5 tw-rounded-7px')}>
                        <Icon icon="arrow-right-lined" className={'tw-size-6'}/>
                      </div>
                    </button>
                  </div>
              </div>
          }
        </section>
        <section
          className={classNames(
            styles.SectionStep,
            styles.SectionStepThird,
            activeStep === 3 && styles.SectionActiveStep,
            'tw-flex tw-flex-col tw-rounded-2xl tw-bg-secondary-75 tw-font-semibold tw-relative tw-overflow-hidden'
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
            <h5 className={styles.SectionTitle}>
              {activeStep === 3 ? 'Congratulations!' : 'Step'}
            </h5>
          </div>
          {activeStep === 3 &&
              <div className="tw-flex tw-flex-col tw-mt-4 tw-flex-1">
                  <div>
                      <p>
                          You have successfully paid for the card issuance. You are just one step away from receiving your
                          card!
                      </p>
                      <p className="tw-mt-4">
                          Once the card is sent to the address provided in your KYC application, we will send you the
                          tracking
                          number and payment details for the shipping.
                      </p>
                  </div>
                  <div className="tw-mt-9 tw-flex-1">
                      <h6 className={classNames(styles.SectionTitle, 'tw-leading-normal')}>
                          Important
                      </h6>
                      <p className="tw-mt-4">
                        Please be advised that all communications will be sent exclusively from
                        our official email address <a className="tw-text-blue tw-underline" href="mailto:cards@vpluspay.hk">cards@vpluspay.hk</a>.
                        We recommend remaining vigilant against potential scams and phishing attempts.
                      </p>
                  </div>
                  <div className="tw-flex tw-justify-end">
                    <NavLink
                      className="tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-font-semibold"
                      to={'/app/cards'}
                    >
                      Submit
                      <div className="tw-flex-centering tw-size-8.5 tw-bg-softGreen tw-rounded-7px">
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className={classNames(
                      styles.PhoneWithEnvelopeImgBlock,
                      'tw-absolute tw-bg-contain tw-bg-no-repeat'
                    )}
                  />
              </div>
          }
        </section>
        </>
      }
      {initialLoader && <div className="tw-flex-centering"><Loader /></div>}
    </div>
  );
});