import classNames from "classnames";
import {transaction} from "mobx";
import Icon from "../../../../../components/Icon";
import {ExtendedTransactionModel} from "../../../../../controllers/transactions";
import styles from "./index.module.css";

export default function TransactionRow(props: {
  transactionData: ExtendedTransactionModel,
  isActive?: boolean,
  onClick?(): void
}) {

  /* DOM */
  return (
    <div
      className={classNames(
        "white-block",
        styles.TransactionRow,
        {isActive: props.isActive}
      )}
      onClick={() => props.onClick?.()}
    >

      <div className="flex flex-aic">

        <div style={{fontWeight: 'bold'}}>{
          props.transactionData.fromUser?.email ?? (
            props.transactionData.type === 'deposit' ? 'Deposit' : 'VPlus'
          )
        }</div>

        <Icon icon="arrow-right-alt-filled" style={{width: 18}} />

        <div style={{fontWeight: 'bold'}}>{
          props.transactionData.toUser?.email ?? (
            props.transactionData.type === 'withdraw' ? 'Withdrawal' : 'VPlus'
          )
        }</div>
      </div>

      <div
        className={
        props.transactionData.status === 'completed' ? (
          'color-green'
        ) : props.transactionData.status === 'pending' ? (
          'color-yellow'
        ) : (
          'color-red'
        )}
        style={{marginLeft: "auto", fontSize: "21pt", fontWeight: "bold"}}
      >
        {props.transactionData.currency} {(props.transactionData.amount / 100).toFixed(2)}
      </div>
    </div>
  );
}
