import classNames from "classnames";
import { ChangeEvent, ComponentProps, CSSProperties, ReactNode, useRef, useState } from "react";
import Button from "../Button";
import styles from "./index.module.css";
import {IMaskInput} from "react-imask";

export default function TextInput(props: {
  name?: string;
  label?: string;
  value?: string | number,
  onChange?: (value: any, key?: any) => void;
  placeholder?: string,
  isPlaceholderPinned?: boolean,
  rightContents?: ReactNode,
  isBigger?: boolean,
  type?: "text" | "password" | "date" | "email",
  showPasswordButton?: boolean,
  isReadOnly?: boolean,
  maskOpt?: ComponentProps<typeof IMaskInput>,
  id?: string;
  style?: CSSProperties;
  successMessage?: string;
  errorMessage?: string;
  className?: string;
  containerClassName?: string;
  revealPasswordButton?: boolean;
  required?: boolean;
  max?: string;
  min?: string;
  disabled?: boolean;
  tabIndex?: number;
  minLength?: number;
  maxLength?: number;
  regex?: RegExp | string;
  floatingPlaceholder?: boolean;
}) {
  
  const { floatingPlaceholder = true } = props;

  /* Refs */
  const inputRef = useRef<HTMLInputElement>(null);

  /* State */
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  /* DOM */
  return (
    <div className={classNames(props.containerClassName, 'tw-w-full')}>
      {props.label && (
        <label htmlFor={props.id || props.name} className={styles.label}>
          {props.label}{' '}
          {props.required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div className={classNames(
        styles.TextInput, {
          isBigger: props.isBigger,
          isFloatingPlaceholder: floatingPlaceholder,
        })}
        style={props.style}
      >
        {props.maskOpt ? (
          <IMaskInput
            ref={inputRef}
            type={isPasswordVisible ? "text" : props.type}
            value={String(props.value)}
            onAccept={(value) => props.onChange?.(value)}
            readOnly={props.isReadOnly}
            {...props.maskOpt}
          />
        ) : (
          <input
            className={styles.InputControl}
            ref={inputRef}
            name={props.name}
            type={isPasswordVisible ? "text" : props.type}
            value={props.value}
            onChange={({ target: { value, name } }) => props.onChange?.(value, name)}
            min={props.min}
            max={props.max}
            required={props.required}
            readOnly={props.isReadOnly}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            minLength={props.minLength}
            maxLength={props.maxLength}
            placeholder={floatingPlaceholder ? '' : props.placeholder}
          />
        )}
        
        {props.placeholder && floatingPlaceholder ? (
          <div
            className={classNames(styles.placeholder, { isPinned: props.isPlaceholderPinned || props.value !== '' }, 'tw-truncate tw-max-w-full')}>
            {props.placeholder}
          </div>
        ) : null}
        
        {props.rightContents ? (
          <div className={styles.rightContents}>
            {props.rightContents}
          </div>
        ) : null}
        
        {props.showPasswordButton ? (
          <Button
            type="button"
            additional={{ isZeroed: true }}
            className={styles.showPasswordButton}
            onClick={(e) => {
              e.currentTarget.blur();
              inputRef.current?.focus();
              setIsPasswordVisible(!isPasswordVisible);
            }}
          >
            {isPasswordVisible ? "Hide" : "Show"} Password
          </Button>
        ) : null}
      </div>
    </div>
  );
}
