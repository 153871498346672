import { observer, useLocalObservable } from 'mobx-react-lite';
import { PinControl } from './../../../Cards/components/PinControl/PinControl.tsx';
import { createPinDialogContentStore } from './store/CardPinDialogContent.store.ts';
import styles from './CardPinDialogContent.module.css';
import cn from 'classnames';
import Button from '../../../../../components/Button';
import { useEffect } from 'react';

const cardPinLength = 6;

interface IParams {
  cardId: string;
  onChangedPin?: () => void;
}

export const CardPinDialogContent = observer<IParams>(({ cardId, onChangedPin }) => {
  const store = useLocalObservable(() => createPinDialogContentStore(cardId));
  
  useEffect(() => {
    if (store.successChanged && onChangedPin) onChangedPin();
  }, [store.successChanged]);
  
  return (
    <div className={styles.CardPinWrapper}>
      {
        !store.successChanged &&
          <>
            <h5 className={styles.CardPinTitle}>Please enter the 6-digit PIN code</h5>
            <div className={styles.CardPinControlWrapper}>
              <PinControl length={cardPinLength} onChange={(value) => store.setCardPinValue(value)}></PinControl>
            </div>
            <Button
                className={styles.CardPinSaveBtn}
                additional={{ isSoftGreen: true }}
                disabled={!store.isEnableBtnSave || store.isLoading}
                onClick={() => store.updateCardPin(store.cardPinValue)}
            >
                Save
            </Button>
        </>
      }
      {
        store.successChanged &&
          <>
              <div className={styles.IconsContainer}>
                  <div className={styles.IconStarLeft}></div>
                  <div className={styles.IconStarRight}></div>
                  <div className={styles.IconHandWithLock}></div>
              </div>
              <h5 className={cn(styles.CardPinTitle, styles.MessageSuccessChangePin)}>
                Your PIN code has been successfully set
              </h5>
          </>
      }
    </div>
  );
});