export default [
  {value: "Andorra", element: "Andorra"},
  {value: "United Arab Emirates", element: "United Arab Emirates"},
  {value: "Afghanistan", element: "Afghanistan"},
  {value: "Antigua and Barbuda", element: "Antigua and Barbuda"},
  {value: "Anguilla", element: "Anguilla"},
  {value: "Albania", element: "Albania"},
  {value: "Armenia", element: "Armenia"},
  {value: "Angola", element: "Angola"},
  {value: "Antarctica", element: "Antarctica"},
  {value: "Argentina", element: "Argentina"},
  {value: "American Samoa", element: "American Samoa"},
  {value: "Austria", element: "Austria"},
  {value: "Australia", element: "Australia"},
  {value: "Aruba", element: "Aruba"},
  {value: "Åland", element: "Åland"},
  {value: "Azerbaijan", element: "Azerbaijan"},
  {value: "Bosnia and Herzegovina", element: "Bosnia and Herzegovina"},
  {value: "Barbados", element: "Barbados"},
  {value: "Bangladesh", element: "Bangladesh"},
  {value: "Belgium", element: "Belgium"},
  {value: "Burkina Faso", element: "Burkina Faso"},
  {value: "Bulgaria", element: "Bulgaria"},
  {value: "Bahrain", element: "Bahrain"},
  {value: "Burundi", element: "Burundi"},
  {value: "Benin", element: "Benin"},
  {value: "Saint Barthélemy", element: "Saint Barthélemy"},
  {value: "Bermuda", element: "Bermuda"},
  {value: "Brunei", element: "Brunei"},
  {value: "Bolivia", element: "Bolivia"},
  {value: "Bonaire", element: "Bonaire"},
  {value: "Brazil", element: "Brazil"},
  {value: "Bahamas", element: "Bahamas"},
  {value: "Bhutan", element: "Bhutan"},
  {value: "Bouvet Island", element: "Bouvet Island"},
  {value: "Botswana", element: "Botswana"},
  {value: "Belarus", element: "Belarus"},
  {value: "Belize", element: "Belize"},
  {value: "Canada", element: "Canada"},
  {value: "Cocos [Keeling] Islands", element: "Cocos [Keeling] Islands"},
  {value: "Democratic Republic of the Congo", element: "Democratic Republic of the Congo"},
  {value: "Central African Republic", element: "Central African Republic"},
  {value: "Republic of the Congo", element: "Republic of the Congo"},
  {value: "Switzerland", element: "Switzerland"},
  {value: "Ivory Coast", element: "Ivory Coast"},
  {value: "Cook Islands", element: "Cook Islands"},
  {value: "Chile", element: "Chile"},
  {value: "Cameroon", element: "Cameroon"},
  {value: "China", element: "China"},
  {value: "Colombia", element: "Colombia"},
  {value: "Costa Rica", element: "Costa Rica"},
  {value: "Cuba", element: "Cuba"},
  {value: "Cape Verde", element: "Cape Verde"},
  {value: "Curacao", element: "Curacao"},
  {value: "Christmas Island", element: "Christmas Island"},
  {value: "Cyprus", element: "Cyprus"},
  {value: "Czech Republic", element: "Czech Republic"},
  {value: "Germany", element: "Germany"},
  {value: "Djibouti", element: "Djibouti"},
  {value: "Denmark", element: "Denmark"},
  {value: "Dominica", element: "Dominica"},
  {value: "Dominican Republic", element: "Dominican Republic"},
  {value: "Algeria", element: "Algeria"},
  {value: "Ecuador", element: "Ecuador"},
  {value: "Estonia", element: "Estonia"},
  {value: "Egypt", element: "Egypt"},
  {value: "Western Sahara", element: "Western Sahara"},
  {value: "Eritrea", element: "Eritrea"},
  {value: "Spain", element: "Spain"},
  {value: "Ethiopia", element: "Ethiopia"},
  {value: "Finland", element: "Finland"},
  {value: "Fiji", element: "Fiji"},
  {value: "Falkland Islands", element: "Falkland Islands"},
  {value: "Micronesia", element: "Micronesia"},
  {value: "Faroe Islands", element: "Faroe Islands"},
  {value: "France", element: "France"},
  {value: "Gabon", element: "Gabon"},
  {value: "United Kingdom", element: "United Kingdom"},
  {value: "Grenada", element: "Grenada"},
  {value: "Georgia", element: "Georgia"},
  {value: "French Guiana", element: "French Guiana"},
  {value: "Guernsey", element: "Guernsey"},
  {value: "Ghana", element: "Ghana"},
  {value: "Gibraltar", element: "Gibraltar"},
  {value: "Greenland", element: "Greenland"},
  {value: "Gambia", element: "Gambia"},
  {value: "Guinea", element: "Guinea"},
  {value: "Guadeloupe", element: "Guadeloupe"},
  {value: "Equatorial Guinea", element: "Equatorial Guinea"},
  {value: "Greece", element: "Greece"},
  {value: "South Georgia and the South Sandwich Islands", element: "South Georgia and the South Sandwich Islands"},
  {value: "Guatemala", element: "Guatemala"},
  {value: "Guam", element: "Guam"},
  {value: "Guinea-Bissau", element: "Guinea-Bissau"},
  {value: "Guyana", element: "Guyana"},
  {value: "Hong Kong", element: "Hong Kong"},
  {value: "Heard Island and McDonald Islands", element: "Heard Island and McDonald Islands"},
  {value: "Honduras", element: "Honduras"},
  {value: "Croatia", element: "Croatia"},
  {value: "Haiti", element: "Haiti"},
  {value: "Hungary", element: "Hungary"},
  {value: "Indonesia", element: "Indonesia"},
  {value: "Ireland", element: "Ireland"},
  {value: "Israel", element: "Israel"},
  {value: "Isle of Man", element: "Isle of Man"},
  {value: "India", element: "India"},
  {value: "British Indian Ocean Territory", element: "British Indian Ocean Territory"},
  {value: "Iraq", element: "Iraq"},
  {value: "Iran", element: "Iran"},
  {value: "Iceland", element: "Iceland"},
  {value: "Italy", element: "Italy"},
  {value: "Jersey", element: "Jersey"},
  {value: "Jamaica", element: "Jamaica"},
  {value: "Jordan", element: "Jordan"},
  {value: "Japan", element: "Japan"},
  {value: "Kenya", element: "Kenya"},
  {value: "Kyrgyzstan", element: "Kyrgyzstan"},
  {value: "Cambodia", element: "Cambodia"},
  {value: "Kiribati", element: "Kiribati"},
  {value: "Comoros", element: "Comoros"},
  {value: "Saint Kitts and Nevis", element: "Saint Kitts and Nevis"},
  {value: "North Korea", element: "North Korea"},
  {value: "South Korea", element: "South Korea"},
  {value: "Kuwait", element: "Kuwait"},
  {value: "Cayman Islands", element: "Cayman Islands"},
  {value: "Kazakhstan", element: "Kazakhstan"},
  {value: "Laos", element: "Laos"},
  {value: "Lebanon", element: "Lebanon"},
  {value: "Saint Lucia", element: "Saint Lucia"},
  {value: "Liechtenstein", element: "Liechtenstein"},
  {value: "Sri Lanka", element: "Sri Lanka"},
  {value: "Liberia", element: "Liberia"},
  {value: "Lesotho", element: "Lesotho"},
  {value: "Lithuania", element: "Lithuania"},
  {value: "Luxembourg", element: "Luxembourg"},
  {value: "Latvia", element: "Latvia"},
  {value: "Libya", element: "Libya"},
  {value: "Morocco", element: "Morocco"},
  {value: "Monaco", element: "Monaco"},
  {value: "Moldova", element: "Moldova"},
  {value: "Montenegro", element: "Montenegro"},
  {value: "Saint Martin", element: "Saint Martin"},
  {value: "Madagascar", element: "Madagascar"},
  {value: "Marshall Islands", element: "Marshall Islands"},
  {value: "Macedonia", element: "Macedonia"},
  {value: "Mali", element: "Mali"},
  {value: "Myanmar [Burma]", element: "Myanmar [Burma]"},
  {value: "Mongolia", element: "Mongolia"},
  {value: "Macao", element: "Macao"},
  {value: "Northern Mariana Islands", element: "Northern Mariana Islands"},
  {value: "Martinique", element: "Martinique"},
  {value: "Mauritania", element: "Mauritania"},
  {value: "Montserrat", element: "Montserrat"},
  {value: "Malta", element: "Malta"},
  {value: "Mauritius", element: "Mauritius"},
  {value: "Maldives", element: "Maldives"},
  {value: "Malawi", element: "Malawi"},
  {value: "Mexico", element: "Mexico"},
  {value: "Malaysia", element: "Malaysia"},
  {value: "Mozambique", element: "Mozambique"},
  {value: "Namibia", element: "Namibia"},
  {value: "New Caledonia", element: "New Caledonia"},
  {value: "Niger", element: "Niger"},
  {value: "Norfolk Island", element: "Norfolk Island"},
  {value: "Nigeria", element: "Nigeria"},
  {value: "Nicaragua", element: "Nicaragua"},
  {value: "Netherlands", element: "Netherlands"},
  {value: "Norway", element: "Norway"},
  {value: "Nepal", element: "Nepal"},
  {value: "Nauru", element: "Nauru"},
  {value: "Niue", element: "Niue"},
  {value: "New Zealand", element: "New Zealand"},
  {value: "Oman", element: "Oman"},
  {value: "Panama", element: "Panama"},
  {value: "Peru", element: "Peru"},
  {value: "French Polynesia", element: "French Polynesia"},
  {value: "Papua New Guinea", element: "Papua New Guinea"},
  {value: "Philippines", element: "Philippines"},
  {value: "Pakistan", element: "Pakistan"},
  {value: "Poland", element: "Poland"},
  {value: "Saint Pierre and Miquelon", element: "Saint Pierre and Miquelon"},
  {value: "Pitcairn Islands", element: "Pitcairn Islands"},
  {value: "Puerto Rico", element: "Puerto Rico"},
  {value: "Palestine", element: "Palestine"},
  {value: "Portugal", element: "Portugal"},
  {value: "Palau", element: "Palau"},
  {value: "Paraguay", element: "Paraguay"},
  {value: "Qatar", element: "Qatar"},
  {value: "Réunion", element: "Réunion"},
  {value: "Romania", element: "Romania"},
  {value: "Serbia", element: "Serbia"},
  {value: "Russia", element: "Russia"},
  {value: "Rwanda", element: "Rwanda"},
  {value: "Saudi Arabia", element: "Saudi Arabia"},
  {value: "Solomon Islands", element: "Solomon Islands"},
  {value: "Seychelles", element: "Seychelles"},
  {value: "Sudan", element: "Sudan"},
  {value: "Sweden", element: "Sweden"},
  {value: "Singapore", element: "Singapore"},
  {value: "Saint Helena", element: "Saint Helena"},
  {value: "Slovenia", element: "Slovenia"},
  {value: "Svalbard and Jan Mayen", element: "Svalbard and Jan Mayen"},
  {value: "Slovakia", element: "Slovakia"},
  {value: "Sierra Leone", element: "Sierra Leone"},
  {value: "San Marino", element: "San Marino"},
  {value: "Senegal", element: "Senegal"},
  {value: "Somalia", element: "Somalia"},
  {value: "Suriname", element: "Suriname"},
  {value: "South Sudan", element: "South Sudan"},
  {value: "São Tomé and Príncipe", element: "São Tomé and Príncipe"},
  {value: "El Salvador", element: "El Salvador"},
  {value: "Sint Maarten", element: "Sint Maarten"},
  {value: "Syria", element: "Syria"},
  {value: "Swaziland", element: "Swaziland"},
  {value: "Turks and Caicos Islands", element: "Turks and Caicos Islands"},
  {value: "Chad", element: "Chad"},
  {value: "French Southern Territories", element: "French Southern Territories"},
  {value: "Togo", element: "Togo"},
  {value: "Thailand", element: "Thailand"},
  {value: "Tajikistan", element: "Tajikistan"},
  {value: "Tokelau", element: "Tokelau"},
  {value: "East Timor", element: "East Timor"},
  {value: "Turkmenistan", element: "Turkmenistan"},
  {value: "Tunisia", element: "Tunisia"},
  {value: "Tonga", element: "Tonga"},
  {value: "Turkey", element: "Turkey"},
  {value: "Trinidad and Tobago", element: "Trinidad and Tobago"},
  {value: "Tuvalu", element: "Tuvalu"},
  {value: "Taiwan", element: "Taiwan"},
  {value: "Tanzania", element: "Tanzania"},
  {value: "Ukraine", element: "Ukraine"},
  {value: "Uganda", element: "Uganda"},
  {value: "U.S. Minor Outlying Islands", element: "U.S. Minor Outlying Islands"},
  {value: "United States", element: "United States"},
  {value: "Uruguay", element: "Uruguay"},
  {value: "Uzbekistan", element: "Uzbekistan"},
  {value: "Vatican City", element: "Vatican City"},
  {value: "Saint Vincent and the Grenadines", element: "Saint Vincent and the Grenadines"},
  {value: "Venezuela", element: "Venezuela"},
  {value: "British Virgin Islands", element: "British Virgin Islands"},
  {value: "U.S. Virgin Islands", element: "U.S. Virgin Islands"},
  {value: "Vietnam", element: "Vietnam"},
  {value: "Vanuatu", element: "Vanuatu"},
  {value: "Wallis and Futuna", element: "Wallis and Futuna"},
  {value: "Samoa", element: "Samoa"},
  {value: "Kosovo", element: "Kosovo"},
  {value: "Yemen", element: "Yemen"},
  {value: "Mayotte", element: "Mayotte"},
  {value: "South Africa", element: "South Africa"},
  {value: "Zambia", element: "Zambia"},
  {value: "Zimbabwe", element: "Zimbabwe"},
];
