import classNames from "classnames";
import {DateTime} from "luxon";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSearchParams} from "react-router-dom";
import Button from "../../../../components/Button";
import ModalWindow from "../../../../components/ModalWindow";
import {ExtendedTransactionModel} from "../../../../controllers/transactions";
import {getTransactionsList} from "../../../../controllers/transactions/getList.ts";
import onError from "../../../../helpers/onError.ts";
import TransactionRow from "../components/TransactionRow";
import styles from "./index.module.css";

import useInfiniteScroll from "react-infinite-scroll-hook";

export default function TransactionsPage() {
  
  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams()
  
  /* State */
  const [transactionsList, setTransactionsList] = useState<ExtendedTransactionModel[]>([]);
  const [isTransactionsListFinite, setIsTransactionsListFinite] = useState(false);
  const [isTransactionsListLoading, setIsTransactionsListLoading] = useState(false);
  const [stopTransactionsListLoading, setStopTransactionsListLoading] = useState(false);
  
  /* Load transactions function */
  function loadTransactionsList() {
    
    getTransactionsList({
      offset: transactionsList.length,
      limit: 50
    })
      .then((transactions) => {
        
        setTransactionsList([...transactionsList, ...transactions]);
        
        if(transactions.length <= 0) setIsTransactionsListFinite(true);
      })
      .catch((error) => {
        onError(error);
        
        setStopTransactionsListLoading(true);
      })
      .finally(() => {
        setIsTransactionsListLoading(false);
      });
  }
  
  /* Transactions list infinite scroll */
  const [transactionsListRef] = useInfiniteScroll({
    loading: isTransactionsListLoading,
    hasNextPage: !isTransactionsListFinite,
    onLoadMore: loadTransactionsList,
    disabled: stopTransactionsListLoading
  });
  
  /* Vars */
  const selectedTransactionId = $searchParams.get('selectedTransactionId');
  const selectedTransaction = transactionsList.find((transaction) => transaction._id === selectedTransactionId)
  
  /* DOM */
  return (
    <div className={classNames("container", "regular-page", styles.TransactionsPage)}>
      
      <div style={{display: "flex"}}>
        <div className="big-heading" style={{flexShrink: 0}}>
          Transactions
        </div>
        
        <div style={{width: "100%"}} />
        
        <Button additional={{isZeroed: true}} onClick={() => $navigate("/app/profile")}>
          Back to Profile
        </Button>
      </div>
      
      <div style={{height: 30}} />
      
      {transactionsList.length ? (
        transactionsList.map((transaction, i) => (
          <TransactionRow
            key={transaction._id}
            transactionData={transaction}
            onClick={() => $setSearchParams(new URLSearchParams({
              ...$searchParams,
              selectedTransactionId: transaction._id
            }))}
          />
        ))
      ) : null}
      
      <div ref={transactionsListRef} />
      
      {isTransactionsListLoading ? (
        <span>Loading...</span>
      ) : null}
      
      {/* Modal windows */}
      <ModalWindow
        isActive={!!selectedTransaction}
        title="Transaction"
        onClose={() => $setSearchParams(new URLSearchParams({
          ...$searchParams,
          selectedTransactionId: undefined
        }))}
      >
        {selectedTransaction ? (
          <React.Fragment>
            <div className="data-rows">
              
              <div className="data-row">
                <div>ID</div>
                <div>{selectedTransaction._id}</div>
              </div>
              
              <div className="data-row">
                <div>Created At</div>
                <div>{DateTime.fromISO(selectedTransaction.createdAt).toFormat('f')}</div>
              </div>
              
              <div className="data-row">
                <div>Type</div>
                <div>{
                  selectedTransaction.type !== 'withdraw' ? (
                    selectedTransaction.type
                  ) : 'withdrawal'
                }</div>
              </div>
              
              <div className="data-row">
                <div>Status</div>
                <div>{selectedTransaction.status}</div>
              </div>
              
              <div className="data-row">
                <div>Gateway</div>
                <div>{selectedTransaction.fromUser?.email ?? 'SOLID BANK'}</div>
              </div>
              
              <div className="data-row">
                <div>Receiver</div>
                <div>{selectedTransaction.toUser?.email ?? 'VPlus'}</div>
              </div>
              
              <div className="data-row">
                <div>Amount</div>
                <div>{selectedTransaction.currency} {(selectedTransaction.amount / 100).toFixed(2)}</div>
              </div>
              
              {selectedTransaction.type === 'exchange-give' || selectedTransaction.type === 'exchange-take' ? (
                <React.Fragment>
                  <div className="data-row">
                    <div>Rate</div>
                    <div>{selectedTransaction.rate.toFixed(2)}</div>
                  </div>
                </React.Fragment>
              ) : null}
              
              {selectedTransaction.type === 'withdraw' ? (
                <React.Fragment>
                  <div className="data-row">
                    <div>Fee amount</div>
                    <div>{selectedTransaction.currency} {(selectedTransaction.fee / 100).toFixed(2)}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Amount with fee</div>
                    <div>{selectedTransaction.currency} {(selectedTransaction.totalAmount / 100).toFixed(2)}</div>
                  </div>
                </React.Fragment>
              ) : null}
              
              <div className="data-row">
                <div>Reference message</div>
                <div>{selectedTransaction.message}</div>
              </div>
              
              {selectedTransaction.type === 'deposit' ? (
                <React.Fragment>
                  <div className="data-row">
                    <div>Payer name</div>
                    <div>{selectedTransaction.receiver.name}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Payer address</div>
                    <div>{selectedTransaction.receiver.address}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Payer IBAN</div>
                    <div>{selectedTransaction.receiver.iban}</div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            
            {selectedTransaction.type === 'withdraw' ? (
              <React.Fragment>
                <div style={{height: 20}} />
                <div className="small-heading">Recipient</div>
                <div style={{height: 5}} />
                
                <div className="data-rows">
                  
                  <div className="data-row">
                    <div>Name</div>
                    <div>{selectedTransaction.recipient.name}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Address</div>
                    <div>{selectedTransaction.recipient.address}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Country</div>
                    <div>{selectedTransaction.recipient.country}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Account</div>
                    <div>{selectedTransaction.recipient.account}</div>
                  </div>
                </div>
                
                <div style={{height: 20}} />
                <div className="small-heading">Beneficiary bank</div>
                <div style={{height: 5}} />
                
                <div className="data-rows">
                  
                  <div className="data-row">
                    <div>Name</div>
                    <div>{selectedTransaction.beneficiaryBank.name}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Address</div>
                    <div>{selectedTransaction.beneficiaryBank.address}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Country</div>
                    <div>{selectedTransaction.beneficiaryBank.country}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Swift</div>
                    <div>{selectedTransaction.beneficiaryBank.swift}</div>
                  </div>
                </div>
                
                <div style={{height: 20}} />
                <div className="small-heading">Intermediary bank</div>
                <div style={{height: 5}} />
                
                <div className="data-rows">
                  
                  <div className="data-row">
                    <div>Name</div>
                    <div>{selectedTransaction.intermediaryBank.name}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Address</div>
                    <div>{selectedTransaction.intermediaryBank.address}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Country</div>
                    <div>{selectedTransaction.intermediaryBank.country}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Swift</div>
                    <div>{selectedTransaction.intermediaryBank.swift}</div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            
            {selectedTransaction.type === 'deposit' ? (
              <React.Fragment>
                <div style={{height: 20}} />
                <div className="small-heading">Cooperation bank</div>
                <div style={{height: 5}} />
                
                <div className="data-rows">
                  
                  <div className="data-row">
                    <div>Name</div>
                    <div>{selectedTransaction.cooperationBank.name}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Address</div>
                    <div>{selectedTransaction.cooperationBank.address}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Country</div>
                    <div>{selectedTransaction.cooperationBank.country}</div>
                  </div>
                  
                  <div className="data-row">
                    <div>Swift</div>
                    <div>{selectedTransaction.cooperationBank.swift}</div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </ModalWindow>
    </div>
  );
}
