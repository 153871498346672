import { CardType } from './card.interface.ts';

export enum CardDataNotActiveStatus {
  NotApplied = 'NOT_APPLIED',
  Pending = 'PENDING',
  ActionRequired = 'ACTION_REQUIRED',
  VerificationFailed = 'VERIFICATION_FAILED',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
}

export enum CardDataActiveStatus {
  Active = 'ACTIVE',
}

export interface ICardDataNoActiveDto {
  status: CardDataNotActiveStatus;
  cardType: CardType;
  actionUrl: string;
}

export interface ICardDataActiveDto {
  status: CardDataActiveStatus;
  cardType: CardType;
  number: string;
  freezed: boolean;
  expiryMonth: string;
  expiryYear: string;
  cvv?: string;
  cardholderName?: string;
  pinNotSet?: boolean;
}

export type ICardDataDto = ICardDataNoActiveDto | ICardDataActiveDto;