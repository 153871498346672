import {makeAutoObservable} from "mobx";
import {DateTime} from "luxon";
import {makePersistable} from "mobx-persist-store";
import {ReactNode} from "react";
import {NotificationModel} from "../controllers/notifications";
import {BusinessUserModel, ExtendedUserModel, IndividualUserModel} from "../controllers/users";

class MainStore {

  sessionToken: string | null = null;
  smsConfirmationCooldown: DateTime | null = null;
  user: ExtendedUserModel | null = null;
  notifications: NotificationModel[] = [];

  constructor() {

    makeAutoObservable(this);

    makePersistable(this, {
      name: "mainStore",
      storage: window.localStorage,
      properties: [
        {
          key: "sessionToken",
          serialize: (value) => {
            return value ?? "null";
          },
          deserialize: (value) => {
            return value === "null" ? null : value;
          }
        },
        {
          key: "smsConfirmationCooldown",
          serialize: (value: typeof this.smsConfirmationCooldown) => {
            if(DateTime.isDateTime(value)) {
              return value.toISO();
            }

            return JSON.stringify(value);
          },
          deserialize: (value: string) => {
            if(value === "null") return null;

            return DateTime.fromISO(value);
          }
        } as any
      ]
    });
  }

  setUser(user: typeof this.user) {
    this.user = user;
  }

  setSessionToken(newSessionToken: typeof this.sessionToken) {
    this.sessionToken = newSessionToken;
  }

  setSmsConfirmationCooldown(newCooldown: typeof this.smsConfirmationCooldown) {
    this.smsConfirmationCooldown = newCooldown;
  }

  setNotifications(newNotifications: NotificationModel[]) {
    this.notifications = newNotifications;
  }

  addNotification(notification: NotificationModel) {
    this.notifications = [...this.notifications, notification];

    setTimeout(() => {
      this.removeNotification(notification.id);
    }, 5000 + 210);
  }

  removeNotification(notificationId: NotificationModel["id"]) {
    this.notifications = [...this.notifications]
    .filter((notification) =>
      notification.id !== notificationId
    );
  }

  userFullName(): string {
    if (this.user?.type === 'individual') return [this.user?.firstName, this.user?.lastName].filter(name => name).join(" ");
    return '';
  }
}

export default new MainStore();
