import { FC, ReactNode } from 'react';
import * as styles from './PageContainer.module.scss';
import classNames from 'classnames';
import { Box, BoxProps } from '@mui/material';

type PageContainerProps = BoxProps & {
  children: ReactNode,
  isRegular?: boolean,
  className?: string,
  innerContainerClassName?: string
};

export const PageContainer: FC<PageContainerProps> = (
  {
    className,
    children,
    innerContainerClassName,
    isRegular,
    ...restProps
  }) => {
  return (
    <Box
      className={classNames(styles.PageContainer, isRegular ? styles.PageContainer_regular : '', className)}
      sx={restProps.sx}
    >
      <div className={classNames(styles.PageInnerContainer, innerContainerClassName)}>
        {children}
      </div>
    </Box>
  );
};