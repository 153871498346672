import styles from "./index.module.css";

export default function ProgressBar(props: {
  progress: number
}) {

  /* DOM */
  return (
    <div className={styles.ProgressBar}>
      <div className={styles.inner} style={{width: `${props.progress}%`}} />
    </div>
  );
}
