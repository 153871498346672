import classNames from 'classnames';
import {Link} from 'react-router-dom';
import styles from './index.module.css';

export default function AMLPage() {

  /* DOM */
  return (
    <div className={styles.AMLPage}>
      <div className={styles.container}>
        <Link to="/">
          <img
            className={styles.logo}
            src="/vplus-dark-full.svg"
            alt="logo"
          />
        </Link>
        <h2 className={styles.pageTitle}>AML/KYC Policy</h2>
        <p className={styles.pageTitleSubTitle}>
          Last updated: 30 th April 2024
        </p>

        <div className={styles.kycStatusContainer}>
          <span className={styles.kycStatusTitle}>Status</span>
          <div className={styles.kycStatus}>
            <span>Approved</span>
          </div>
        </div>

        <h2>VPLUS Greater Bay Exchange Limited</h2>
        <p>
          VPLUS Greater Bay Exchange Limited is incorporated in Hong Kong with
          its registered office located at FLAT 2, 11/F, YUEN LONG TRADE CENTRE,
          99-109 CASTLE PEAK ROAD, YUEN LONG, NEW TERRITORIES. Money remittance
          services are provided legally by VPLUS GREATER BAY EXCHANGE LIMITED
          under the license for Operating Money Service (license number
          19-08-02842) issued by Customs and Excise Department.
          <br />
          Company’s current and future obligations under the: <br />
          Anti-Money Laundering and Counter-Terrorist Financing Framework in
          Hong Kong According to Anti-Money Laundering and Terrorist Financing
          Guidelines 1 and Chapter 615 "Anti-Money Laundering and Terrorist
          Financing Ordinance" (Anti-Money Laundering Ordinance) Article 7
          related statutory and regulatory requirements, and money service
          operators to implement anti-money laundering and terrorist financing
          regulations Anti-Money Laundering and Counter-Terrorist Financing
          Guidelines that should be complied with under the statutory provisions
          of the Money Laundering Ordinance. Subject to disciplinary or other
          action under anti-money laundering regulations for compliance Money
          laundering can be divided into 3 common stages, which often involve
          multiple transactions. Money service operators should pay attention to
          the signs that may be involved in criminal activities.
          <br />
          These stages include: <br />
          (a) Deposit-in-kind disposition of cash proceeds from illegal
          activities (b) layered transactions - the separation of illicit
          proceeds from their sources through complex and multi-layered
          financial transactions, thereby concealing the origin of the funds,
          obscuring audit trails and concealing the identity of the owners, and
          (c) Integrate- Create a semblance of legitimacy for criminally
          acquired wealth. When the process of layering transactions is
          successful, the integration scheme effectively funnels the laundered
          proceeds back into the general financial system, giving the impression
          that the proceeds were derived from or involved legitimate business
          activities. Section 1 of Part 1 of Schedule 1 to the Anti-Money
          Laundering Ordinance defines the meaning of the term "terrorist
          financing", which means <br />
          (a) provides or collects property, directly or indirectly, by any
          means—
          <br />
          i. with the intent to use all or part of the property for one or more
          acts of terrorism (whether or not the property is actually so used),
          or <br />
          ii. Knowing that all or part of the property will be used to commit
          one or more acts of terrorism (whether or not the property is actually
          so used) <br />
          (b) Knowing that a person is a terrorist or terrorist-associated
          person, or reckless as to whether a person is a terrorist or a
          terrorist-associated person: making any property available to that
          person, directly or indirectly, by any means or financial (or related)
          services, or for the benefit of that person, provides such property or
          services, directly or indirectly, in any way or (c) Knowing that a
          person is a terrorist or terrorist-associated person or recklessly as
          to whether a person is a terrorist or a terrorist-associated person:
          raising funds for the benefit of that person, directly or indirectly,
          by any means; property or seeking financial (or related) services.{' '}
          <br />
          Terrorists or terrorist organisations need financial support to
          achieve their goals. They often need to hide or disguise their
          connection to the source of funding. Terrorist groups must therefore
          also find ways to launder funds (regardless of whether the source of
          the funds involved is legitimate) in order to use the funds without
          being detected by the authorities.
        </p>

        <h2>General provisions</h2>
        <p>
          These Policy procedures for prevention of money laundering and
          terrorist financing, and compliance with international sanctions lay
          down requirements for screening the Clients in order to prevent
          entering into deals involving suspected money laundering and terrorist
          financing, and to ensure identification and reporting of such in case
          of existence of a risk determined by the Company. These AML/KYC Policy
          shall be applied in case of any issue related with money or financial
          transaction(s) is occurred. The rules are primarily based on the
          regulations of AML/KYC legislation applicable and international
          sanctions acts.
        </p>

        <h2>Legal Framework</h2>
        <p className={styles.noMargin}>
          This policy is designed in accordance with:
        </p>
        <ul>
          <li>
            Hong Kong's Anti-Money Laundering and Counter-Terrorist Financing
            Ordinance (Cap. 615)
          </li>
          <li>Guidelines issued by the Hong Kong Monetary Authority (HKMA)</li>
          <li>
            The People's Republic of China Anti-Money Laundering Law
            (中华人民共和国反洗钱法)
          </li>
          <li>
            Administrative Measures for the Identification of Client Identity
            and Client Due Diligence by Financial Institutions (PBOC)
          </li>
        </ul>

        <h2>Application of due diligence measures</h2>
        <p className={styles.noMargin}>
          The Company shall determine and take due diligence measures using
          results of conducted international risk assessment and provisions of
          national risk assessment. <br />
          Depending on the level of the risk of the Client and depending on the
          fact whether the business relationship is an existing one or it is
          about to be established, the Company shall apply either normal KYC/AML
          measures, simplified KYC/AML measures or enhanced KYC/AML measures.
          The Company may also apply continuous KYC/AML measures to ensure
          ongoing monitoring of business relationships.
        </p>
        <br />

        <p className={styles.noMargin}>
          KYC/AML measures may include, but are not limited with, the following
          procedures:
        </p>
        <ul className={styles.paddedList}>
          <li>
            Identifying the Client and verifying its identity using reliable,
            independent sources, documents or data, including e-identifying;
          </li>
          <li>
            Assessing and, as appropriate, obtaining information on the purpose
            of the business relationship;
          </li>
          <li>
            Conducting ongoing KYC/AML on the Client's business to ensure the
            Company’s knowledge of the Client and its source of funds is
            correct;
          </li>
          <li>
            Obtaining information whether the Client is a PEP or PEP’s family
            member or PEP’s close associate;
          </li>
          <li>
            Obtaining information from any third party, e.g. government
            authorities.
          </li>
        </ul>

        <h2>Customer Due Diligence (CDD)</h2>

        <ul>
          <li>
            Identification and Verification: Verify the identity of customers
            using reliable, independent source documents, data, or information
            before establishing a business relationship or conducting
            transactions.
          </li>
          <li>
            Beneficial Ownership: Identify the beneficial owners and take
            reasonable measures to verify their identities.
          </li>
          <li>
            Enhanced Due Diligence (EDD): Conduct EDD for customers assessed as
            high risk, including politically exposed persons (PEPs), or those
            from countries with insufficient AML/CFT measures.
          </li>
          <li>
            Ongoing Monitoring: Regularly update customer information and
            conduct scrutiny of transactions to ensure consistency with the
            customer's business and risk profile.
          </li>

          <p className={styles.listDescript}>
            The Company may establish the source of wealth of the Client, where
            appropriate. To comply with the KYC/AML obligation, the Company or
            any third party appointed by the Company shall have the right and
            obligation to:
          </p>

          <ul className={styles.paddedList}>
            <li>
              request appropriate identity documents to identify the Client;
            </li>
            <li>
              request documents and information regarding the activities of the
              Client and legal origin of funds;
            </li>
            <li>
              screen the risk profile of the Client, select the appropriate
              KYC/AML measures, assess the risk whether the Client is or may
              become involved in money laundering or terrorist financing;
            </li>
            <li>
              re-identify the Client, if there are any doubts regarding the
              correctness of the information received in the course of initial
              identification.
            </li>
          </ul>

          <p className={styles.listDescript}>
            The objective of the continuously applied KYC/AML measures is to
            ensure on-going monitoring of Clients. Conducting ongoing monitoring
            of the Business Relationship includes:
          </p>

          <ul className={styles.paddedList}>
            <li>
              Keeping up-to-date the documents, data or information, obtained
              during taking KYC/AML measures;
            </li>
            <li>
              Paying particular attention to Client’s actions, leading to
              criminal activity or money laundering or terrorist financing;
            </li>
            <li>
              Paying particular attention to Client’s actions, leading to
              criminal activity or money laundering or terrorist financing;
            </li>
          </ul>

          <p className={styles.listDescript}>
            The Company evaluates the substance and the purpose of the Client’s
            activities, in order to establish the possible links with money
            laundering or terrorist financing. The evaluation should result in
            an understanding of the purpose of the business relationship for the
            Client, the nature of the Client’s business, the risk levels of the
            Client and, if necessary, the sources of funds. The senior
            management of the company will be responsible for assessing the
            money laundering risks that the company may face and controlling
            these risks.
          </p>
        </ul>

        <h2>Enhanced Due Diligence (EDD)</h2>
        <ul>
          <p className={styles.listDescript}>
            A company's Enhanced Due Diligence (EDD) process aims to obtain as
            much information as possible to ensure that the transaction is sound
            and that the company is in compliance with legal requirements.{' '}
            <br />
            From a practical standpoint, EDD includes:
          </p>
          <li>
            Taking reasonable steps to establish the source of the Client's
            wealth - the source of wealth is different from the source of funds
            and describes the activities that have generated a person's total
            net worth, i.e. activities that have resulted in income and property
            for the Client;
          </li>
          <li>
            Considering whether it is appropriate to take steps to verify the
            source of funds and wealth either from Clients or from independent
            sources (such as the internet, public or commercially available
            databases);
          </li>
          <li>
            Obtaining additional CDD information (identification and
            relationship information);
          </li>
          <li>
            Taking additional steps to verify the CDD information received;
          </li>
          <li>
            Requiring more frequent reviews of business relationships (twice a
            year);
          </li>
          <li>
            Conducting enhanced transaction monitoring and setting lower
            thresholds for business relationship transactions and;
          </li>
          <li>
            Setting alert thresholds for automated monitoring at a lower level
            for PEPs;
          </li>
          <li>conducting adverse media searches.</li>
          <p className={styles.listDescript}>
            If in the course of verification of the Client the Company suspects
            that an act of money laundering and/or terrorist financing is being
            carried out, and further verification of the Client may cause the
            Company to suspect that information about the Client may be passed
            to the Regulator, the Company may terminate the process of
            verification of the Client and not establish business relations with
            the Client. In such cases, the information shall be forwarded to the
            Regulator in accordance with the established procedure set out in
            the "SAR Reporting" section of this procedure.
          </p>
        </ul>

        <h2>
          Identify and obtain information from <br /> ultimate beneficiaries
        </h2>
        <p>
          The potential client should be asked if he/she is the ultimate
          beneficiary. It is sometimes the case that the ultimate beneficiary
          does not match what the client suggests. The ultimate beneficiaries of
          legal entities are often more difficult to ascertain, but the goal is
          to identify the individual(s) who ultimately controls ownership of the
          company. Once the individual(s) have been identified, the CDD
          information should be completed by them properly and not by a
          nominee/representative representing themselves as the owner. In the
          case of nominee shareholding, the RM should request a legal
          justification for the nominee holding arrangement, CDD should be
          carried out on both the nominee owner and the ultimate beneficiaries.
        </p>

        <h2>Risk Management</h2>
        <p>
          Risk Identification: Identify ML/TF risks associated with customers,
          products, delivery channels, and geographic locations. <br />
          Risk Assessment and Controls: Periodically assess risks and implement
          controls tailored to the risk level, including enhanced due diligence
          for high-risk categories. <br />
          Classification of risks in practice <br />
          AML's general policy is based on a risk-based approach. All customers
          and potential customers are screened and analysed before accepting
          their transactions, but special attention is given to those customers
          and transactions that have higher ML risks. <br />A risk assessment
          model will be used to classify customers into four categories. <br />
          1. Low risk; 2. Moderate risk ; 3. High risk ; 4. very high risk ; 5.
          Failure. <br />A risk score will be assessed for all customers prior
          to any transactions by the RM who is the main point of contact,
          meeting customers face to face. <br />
          All new clients should undergo an AML/CFT assessment. Part 1 of the
          Client Due Diligence Form should be completed by the client (ideally
          in his/her own handwriting). Part 2 is a confidential assessment to be
          completed by the caseworker/customer relationship manager after
          interviewing the customer. <br />
          The RM should then calculate the client's summarised scores and send
          the CDD form to the compliance officer for review. <br />
          Risk Mitigation: Apply risk-based customer due diligence, ongoing
          monitoring, and suspicious activity reporting. <br />
        </p>

        <h2>Client identification process</h2>
        <p className={styles.noMargin}>
          According to GAMLCFT paragraph 4.14, CDD measures: <br />
          "(a) identify the customer and verify the customer's identity using
          documents, data or information provided by a reliable and independent
          source; (b) if there is a beneficial owner in relation to the
          customer, determine and take reasonable steps to verify the identity
          of the beneficial owner so that the FI is satisfied that it knows who
          the beneficial owner is, including, in the case of a legal entity or
          trust, measures to enable the FI to understand the ownership and
          control structure of the legal entity or trust; (c) obtain information
          about the purpose and pre <br />
          Prior to opening an account, the actual identity of the prospective
          customer must be established, the account manager must collect the
          information required in the AML pre-assessment form and provide any
          other customer identification documents to the company for the AML
          pre-assessment risk assessment. <br />
          The basic elements of a completed AML pre-assessment kit consist of:{' '}
          <br />
        </p>

        <ol className={classNames(styles.noMargin, styles.paddedList)}>
          <li>
            Customer identification documents such as national ID card, driving
            licence and passport.
          </li>
          <li>
            Proof of the client's address, such as utility bills, bank
            statements issued within 3 months.
          </li>
          <li>Proof of income and assets.</li>
          <li>
            Completed AML pre-approval form with answers to all questions.
          </li>
        </ol>

        <p className={styles.listDescripts}>
          During the personal interview, the client manager must examine the
          client's true and original identification documents (including ID
          cards or passports, address and income statements). The account
          manager must reasonably assess the authenticity of the identification
          documents, for example, the names on the identification documents must
          match, the client's photograph must match the real person, and the
          identification number must be the same on these documents.
        </p>

        <h2>
          Compliance with the confidentiality of <br /> customer information
        </h2>
        <p>
          It shall also ensure that all customer personal data is handled in
          accordance with the provisions of the PDPO and the Company's internal
          policy on personal data. <br />
          The Customer Relationship Manager must deliver personal identification
          documents to the Company himself/herself. Any customer data
          transmitted electronically must only go through the Company's email
          system. If identification documents are to be delivered via courier or
          third-party delivery services, the documents must be sealed in an
          envelope addressed to the proper recipient. In addition, the envelope
          must have a return address and be labelled "Confidential". Notice of
          delivery should be sent to the recipient on or before the delivery
          date. <br />
          When the CO receives information on the client's CDD, they are also
          responsible for ensuring the accuracy and authenticity of the
          documents, if they find any errors or incompleteness in the CDD they
          should reject the CDD in the RM. <br />
        </p>

        <h2>Politically Exposed Persons (PEP)</h2>
        <p>
          A Politically Exposed Person (PEP) is an individual who is or has been
          entrusted with a prominent function and as such could potentially
          abuse such a position or function for the purposes of laundering or
          other predicate offences, such as corruption or bribery3. Due to the
          high risks associated with PEPs, The Financial Action TaskForce (FATF)
          recommends that additional AML and due diligence controls are put into
          place when entering into a business relationship with a PEP. <br />
          The Company utilises existing commercial resources and other databases
          for the identification of PEPs and always ensures that initial due
          diligence KYC checks include reviewing individual names against these
          resources and databases to identify PEPs immediately. <br />
          The Company invokes additional due diligence measures for all
          identified PEPs and where such a proposal to establish a business
          relationship exists, the Company always conducts EDD and ensures that
          the senior management approval for establishing the business
          relationship is obtained and recorded. <br />
        </p>

        <h2>Sanction check</h2>
        <p className={styles.noMargin}>
          Consolidating sanctions lists and identifying restricted countries for
          both Hong Kong and China involves several key steps, given that both
          jurisdictions adhere to international sanctions and may have
          additional local restrictions. <br />
          International Sanctions
        </p>
        <p className={styles.noMargin}>
          Both China and Hong Kong adhere to international sanctions imposed by
          bodies like the United Nations (UN). Here’s what to consider
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            United Nations Sanctions: Both jurisdictions follow UN sanctions,
            which typically include travel bans, asset freezes, and trade
            restrictions against specific countries, individuals, or entities.
            Regularly check the UN Security Council Sanctions Committees'
            website for updates.
          </li>
          <li>
            Additional International Bodies: Consider other international
            sanctions lists like those from the USA (OFAC), EU, etc., especially
            if your business has international dealings or uses international
            financial systems.
          </li>
        </ul>
        <p className={styles.noMargin}>
          China may impose its own sanctions which are important for businesses
          operating within its jurisdiction.
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            Ministry of Commerce of China (MOFCOM): Check for any
            country-specific sanctions or trade restrictions that are updated by
            MOFCOM.
          </li>
          <li>
            People's Bank of China (PBOC): For financial sanctions, refer to
            directives from the PBOC that may impact financial transactions.
          </li>
        </ul>
        <p className={styles.noMargin}>
          Hong Kong generally aligns with international sanctions and may
          implement additional measures under the instruction of the <br /> Hong
          Kong Monetary Authority (HKMA):
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            HKMA: They provide guidelines on the implementation of financial
            sanctions and lists that are relevant to banking and financial
            institutions in Hong Kong.
          </li>
          <li>
            Trade and Industry Department (TID): For trade-related sanctions and
            checks.
          </li>
        </ul>
        <br />
        <p>
          Lists of Restricted Countries <br />
          Export and Trade Controls: Both China and Hong Kong have regulations
          regarding trade with certain countries due to security concerns or
          international agreements. These are often listed on the TID website
          for Hong Kong and the MOFCOM site for China. <br />
          Financial Sector Restrictions: Look for guidance from the HKMA and
          PBOC regarding any financial prohibitions involving specific
          countries. <br />
          Regular Updates: Sanctions and restricted lists are subject to change
          based on geopolitical developments and new international agreements.
          Establish a regular review process to check for updates from the
          respective sources. <br />
          Integration with Internal Systems: Ensure that the consolidated list
          is integrated with your internal compliance and transaction monitoring
          systems. This helps in real-time screening and compliance checks.{' '}
          <br />
        </p>

        <h2>Customer activity monitoring</h2>
        <p className={styles.noMargin}>
          The Company is required to monitor business relationships and
          scrutinise unusual, complex or high-risk transactions or activities to
          detect or prevent money laundering or terrorist financing.
        </p>
        <p className={styles.noMargin}>
          As required by law, the Company monitors transactions and establishes
          patterns of customer spending behaviour to identify:
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>Complex and/or unusually large transactions;</li>
          <li>
            Unusual transaction patterns that have no apparent economic or
            apparent legitimate purpose;
          </li>
          <li>
            Any other activity which, in the opinion of the Company, by its
            nature may be related to money laundering or terrorist financing.
          </li>
        </ul>
        <p className={styles.noMargin}>
          An unusual transaction or activity may take a form that does not fit
          the expected pattern of activity within a particular business
          relationship or normal business activity for the type of product or
          service offered. This may indicate money laundering, terrorist
          financing activity or fraud where the transaction or activity has no
          apparent economic or apparent legitimate purpose.
        </p>
        <p className={styles.noMargin}>
          The Company utilises a monitoring system that works to achieve the
          following objectives:
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            Processing Customer information by providing automated control over
            the identification of the Customer, beneficial owner, authorised
            representative and other Customers and mandatory information fields
            necessary for the management of economic and personal activities;
          </li>
          <li>
            Classifying the Client's risks, maintaining and utilising a scoring
            system to ensure that the Client's risk factors are assessed, the
            risk type is determined and an appropriate numerical score is
            assigned;
          </li>
          <li>
            Customer research and transaction tracking, ensuring electronic
            documentation of Due Diligence, storage and accessibility of
            decisions made based on the results of the research, business
            intelligence, and at the authorisation level of users of the
            monitoring system (e.g. read-only/edit mode);
          </li>
          <li>
            Monitoring "dormant" accounts by automatically identifying and
            further verifying a transaction made from or to a dormant account;
          </li>
          <li>
            Utilisation of sanctions lists by ensuring that intelligent
            sanctions lists are maintained, updated and utilised;
          </li>
          <li>
            Identification of customers who are politically exposed persons,
            their family members or persons closely related to politically
            exposed persons (hereinafter referred to as "PEP"), ensuring the
            preparation of automatic warning messages if information about the
            customer, its true beneficiary, representatives allows identifying a
            possible PEP;
          </li>
          <li>
            AML/CFT risk exposure assessment, ensuring comparison of aggregated
            indicators of AML/CFT risk exposure with the results of previous
            assessment periods and determining the dynamics of their change.
          </li>
        </ul>
        <p className={styles.noMargin}>
          Possible characteristics to be monitored may include changes in:
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>the nature and type of transaction;</li>
          <li>the frequency and nature of a series or set of transactions;</li>
          <li>
            the amount of any transactions, with a focus on particularly large
            transactions;
          </li>
          <li>
            the geographical origin/destination of the payment or receipt;
          </li>
          <li>
            stakeholders to ensure that no payments are made to or from persons
            on the sanctions list;
          </li>
          <li>the Customer's ordinary activities or turnover.</li>
        </ul>
        <p>
          Monitoring of Customer transactions and activities shall be carried
          out on a risk-based basis, with high-risk Customers subject to
          additional and more frequent verification and surveillance.
          Transactions and activities shall be monitored throughout the Client
          relationship to ensure that the transactions and activities conducted
          are consistent with the Client's KYC, business, source of funds and
          source of wealth, if required to be determined. <br />
          Monitoring of complex, unusual and large transactions or unusual
          patterns of transactions shall be examined and recorded in writing.{' '}
          <br />
          Monitoring of transactions involving virtual assets by checking the
          history of virtual assets using a specialised IT tool. <br />
          Where there is a material change in the basis of the relationship, the
          Company should carry out additional procedures to monitor the Client's
          activities to ensure that the revised risk and basis of the
          relationship is fully understood. Any red flags should be responded to
          in order to improve understanding. <br />
          The Company should ensure that any updated information obtained
          through meetings, discussions or other methods of communication with
          the Client is recorded and retained with the Client's records. This
          information should be available to the Company's compliance officer.{' '}
          <br />
          Ongoing monitoring of the Client's activities will allow the Company
          to continue to profile the Client and will entail ongoing collection
          of CDD information. <br />
        </p>

        <h2>Know Your Transaction (KYT)</h2>
        <p className={styles.noMargin}>
          Transaction Monitoring Process or KYT, or Know Your Transaction, is a
          process for monitoring and tracking financial transactions to detect
          and prevent fraudulent or criminal activity such as money laundering
          or terrorist financing.
          <br />
          The company does KYT in both crypto transactions and financial
          transactions. More detailed information with all the alerts and % is
          set out in the document "Crypto Transaction Monitoring" and "Fiat
          Transaction Monitoring"
          <br />
          KYT is a requirement of anti-money laundering/terrorist financing
          legislation and at the same time an important tool for financial
          institutions to ensure compliance with regulations, prevent financial
          crime and protect their customers and reputation.
          <br />
          The company conducts KYT in both crypto and fiat transactions, using a
          variety of trusted providers as well as utilising internal
          regulations.
          <br />
          Event-based reviews allow the Company to continuously monitor the
          profile of an existing customer. The frequency of an event is
          determined by indicators from our transaction monitoring, indicators
          from regulators or other entities indicating either new money
          laundering methods, e.g. indicating industries, new platforms e.g.:
          darknets that may have been launched.
          <br />
        </p>
        <p className={styles.noMargin}>
          Thus, for example, scenarios that may trigger an event-based review
          may include:
        </p>

        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            Changes in customer behaviour and regular flagging in SumSub or
            Chainalysis as triggering their high risk flags;
          </li>
          <li>
            Negative news, legal action against various platforms or services
            that are considered money laundering channels;
          </li>
          <li>
            Unwanted marketing flagging of individuals by a third-party
            monitoring system;
          </li>
          <li>
            Significant changes to previously provided KYC customer information,
            including the customer's current monitoring platform;
          </li>
          <li>
            An account that has been dormant or has started frequenting new
            addresses.
          </li>
        </ul>

        <p>
          The process of passing KYT will consist of the following steps: <br />
          Step 1 - transaction - the system receives the address from where you
          will log in or to which you will withdraw. <br />
          Step 2 - Transaction verification and response - The expected account
          turnover allows you to preliminarily categorise the customer as a
          risk. <br />
          Step 3 - Record the address in the database for continuous monitoring
          - All addresses are recorded, both incoming and outgoing, in a
          register for continuous monitoring. <br />
          And in case of changes in their risk is soon. <br />
          The responsible person is informed immediately. <br />
        </p>

        <h2>Suspicious Activity Reporting</h2>
        <p className={styles.noMargin}>
          Pursuant to GAMLCFT 7.9, the MLRO must be designated to (a) review
          internal disclosures and exception reports and, in light of all
          available relevant information, determine whether a report should be
          filed with the JFIU; (b) maintain all records related to such internal
          reviews; and (c) provide guidance on how to avoid tipping. <br />
          "The laws state that if a person knows or suspects that any property
          is the proceeds of drug trafficking, or a punishable offence or
          terrorist property, or has been used in connection with drug
          trafficking, a punishable offence or a terrorist act, or is intended
          to be used in drug trafficking, a punishable offence or a terrorist
          act, he must as soon as possible report his knowledge or suspicion to
          an authorised officer (t <br />
          Upon identification of a suspicious transaction by any employee of the
          company, he/she should immediately and directly report the same to the
          MLRO and the CO of the company and subsequent transmission of such
          reports should be included among them. <br />
          The MLRO together with the CO must then determine whether there are
          reasonable grounds for suspicion and the MLRO must immediately make an
          STR. <br />
          Before making an STR, it is advisable that the MLRO and CO first
          follow the SAFE system: <br />
          Screen: Checking the account for suspicious indicators: recognising
          the indicator or indicators of suspicious activity Question: What are
          suspicious indicators and how were they identified? <br />
          Ask: Ask the client relevant questions <br />
          Question: Has the RM or other staff attempted to ask customers
          appropriate questions to rule out false positives? <br />
          Find: Find out customer details: Analyse already known information
          when deciding whether to expect suspicious activity. The MLRO and CO
          should conduct a full review of the transaction details and CDD
          information of the customer and include them in the STR. <br />
          Assess: Assess all the above information: is the transaction
          suspicious? <br />
          The MLRO and CO should then assess the above information and determine
          whether an STR is required, in particular if any of the red flags
          identified in paragraph 5.3 are detected. <br />
          In accordance with GAMLCFT 7.5, as soon as knowledge or suspicion
          arises: (a) the FI should file an STR even if no transaction has been
          conducted by or through the FI; and (b) an STR should be filed as soon
          as possible after the suspicion is first identified. <br />
          STR reports should be sent by email and registered post and the MLRO
          should have a telephone conversation with the JFIU. <br />
          The MLRO should ensure that the electronic communication channel is
          secure before using electronic distribution and, when sending a hard
          copy to the JFIU, that the method of delivery is reliable and secure:{' '}
          <br />
        </p>

        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>via the electronic reporting system, STREAMS</li>
          <li>by email: jfiu@police.gov.hk</li>
          <li>by fax: (852) 2529 4013</li>
          <li>
            by post to: Joint Financial Intelligence Unit, GPO Box 6555 Hong
            Kong
          </li>
          <li>
            by telephone (852) 2866 3366 (for urgent communications during
            normal business hours) STR must contain the following information:
            (852) 2866 3366 (for urgent communications during normal business
            hours).
          </li>
        </ul>

        <p className={styles.noMargin}>
          The STR must contain the following information
        </p>

        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            personal details (name, ID or passport number, date of birth,
            address, telephone number, bank account number) of the person(s) or
            company involved in the suspicious transaction;
          </li>
          <li>details of the suspicious financial activity;</li>
          <li>
            the reason why the transaction is suspicious - what indicators of
            suspicious activity are present?
          </li>
          <li>
            The explanation based on the STR assessment and related information
            should be kept separate from access by persons other than the COO
            and MLRO. STR reports should be locked in a folder labelled
            "Confidential". If the information is stored electronically, the
            wording "Confidential" should be labelled at the top of the document
            and in the header of emails.
          </li>
        </ul>

        <h2>Record keeping</h2>
        <p className={styles.noMargin}>
          The Company complies with provisions of the Proceeds of Crime (Money
          Laundering) and Terrorist Financing Regulations. By doing so, we:
        </p>
        <ul className={classNames(styles.paddedList, styles.noMargin)}>
          <li>
            Maintain a full audit trail for KYC, CDD and EDD performed on our
            potential (in cases when the person or a corporate entity has not
            been onboarded) and actual Clients including information about UBOs
            and information accompanying transfers of funds;
          </li>
          <li>
            For five years from the date of termination of transactions or
            business relationships with the Customer, keep a register of the
            Customers with whom transactions or business relationships were
            terminated due to the refusal to submit additional information in
            specified time limits;
          </li>
          <li>
            For five years from the date of termination of transactions or
            business relationships with the Customer,keep copies of identity
            documents of the Customer, the identity data of the beneficial
            owner, the identity data of the beneficiary, selfie photographs,
            direct video streaming/direct video broadcasting recordings and/or
            photographs, other data received at the time of onboarding;
          </li>
          <li>
            For five years from the date of termination of transactions or
            business relationships with the Customer,in electronic and paper
            form keep business correspondence with the Customer;
          </li>
          <li>
            For five years from the date of execution of the monetary operation
            or conclusion of the transaction,keep transaction data and data
            relating to the execution of those transactions;
          </li>
          <li>
            For five years from the date of termination of transactions or
            business relationships with the Customer,keep records of financial
            investigations of suspicious Client activity or transactions.
          </li>
        </ul>
        <p>
          Every record that is required to be kept under Regulations shall be
          retained in such a way that it can be provided to an unauthorised
          person within 30 days after a request is made to examine it.
        </p>

        <h2>Training Policy</h2>
        <p>
          The company should conduct regular AML/CFT trainings for all employees
          through the CO. External trainers from governmental or
          non-governmental organisations may also be invited to conduct seminars
          for company employees. The CO is responsible for ensuring that all
          training is conducted by qualified personnel with sufficient
          experience and knowledge of AML issues relevant to the company's
          business. The company should also cultivate a culture of AML/CFT
          suspicious transaction awareness, as outlined in Chapter 5. <br />
          Whether or not the CO conducts training sessions, he or she should
          attend all sessions to monitor the quality of the training and keep
          records of the training for at least 3 years. <br />
          The CO must be present during arrival and departure times and ensure
          that attendance records are signed and output only by the person(s)
          present. The trainee's full name on the ID card must be recorded and
          training records will be retained for a minimum of seven years. <br />
          Any absence from compulsory training must be notified to the absent
          person's line manager. If an employee is absent from training, they
          must not engage in any regulated activity until the lessons and tests
          have been passed. <br />
        </p>

        <h2 className={styles.countriesTitle}>
          Annex 1 <br />
          List of restricted countries
        </h2>
        <div className={styles.countriesContainer}>
          <ul>
            <li>Abkhazia</li>
            <li>Afghanistan</li>
            <li>Albania</li>
            <li>American Samoa</li>
            <li>Bahamas</li>
            <li>Barbados</li>
            <li>Belarus</li>
            <li>Botswana</li>
            <li>Cambodia</li>
            <li>Central African Republic</li>
            <li>Crimea</li>
            <li>Democratic Republic of the Congo</li>
            <li>Cuba</li>
            <li>Donetsk National Republic (DNR)</li>
            <li>Ethiopia</li>
            <li>Ghana</li>
            <li>Haiti</li>
            <li>Iran</li>
            <li>Jamaica</li>
            <li>Kashmir</li>
            <li>Lebanon</li>
            <li>Libya</li>
            <li>Luhansk National Republic (LNR)</li>
            <li>Mauritius</li>
            <li>Mali</li>
            <li>Mongolia</li>
          </ul>

          <ul>
            <li>Myanmar</li>
            <li>Nagorno Karabakh</li>
            <li>Nicaragua</li>
            <li>North Korea (Democratic People's Republic of Korea)</li>
            <li>Nigeria</li>
            <li>Pakistan</li>
            <li>Panama</li>
            <li>Palestine</li>
            <li>Puerto Rico</li>
            <li>Russia</li>
            <li>Samoa</li>
            <li>Senegal</li>
            <li>Somalia</li>
            <li>South Ossetia</li>
            <li>South Sudan</li>
            <li>Syria</li>
            <li>Trinidad and Tobago</li>
            <li>Ukraine Crimea and Sevastopol (Crimea)</li>
            <li>Uganda</li>
            <li>USA</li>
            <li>Vanuatu</li>
            <li>Venezuela</li>
            <li>Yemen</li>
            <li>Zimbabwe</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
