import classNames from "classnames";
import React, {FormEvent, useState} from "react";
import BgIcon from "../../../../../../../components/BgIcon";
import Button from "../../../../../../../components/Button";
import FileUploadBlock from "../../../../../../../components/FileUploadBlock";
import Loader from "../../../../../../../components/Loader";
import RowSelect from "../../../../../../../components/RowSelect";
import Select from "../../../../../../../components/Select";
import TextInput from "../../../../../../../components/TextInput";
import {IndividualUserModel, UserType} from "../../../../../../../controllers/users";
import validateFounder from "../../../../../../../controllers/users/helpers/validateFounder.ts";
import {
  BusinessFounderModel,
  FounderModel,
  IndividualFounderModel
} from "../../../../../../../controllers/users/models/Founder.ts";
import {ExtendedFounderModel} from "../../../../../../../controllers/users/models/Founder.ts";
import {ExtendedIndividualFounderModel} from "../../../../../../../controllers/users/models/Founder.ts";
import countriesOptions from "../../../../../../../helpers/countriesOptions.ts";
import onError from "../../../../../../../helpers/onError.ts";
import styles from "./index.module.css";

export const initialFounder = {
  type: "individual" as UserType,

  fullName: "",
  dateOfBirth: "",

  personalData: {
    documentType: "",
    citizenShip: "",
    passport: {
      number: "",
      issuedAt: "",
      issuedBy: "",
      expiringDate: "",
    },
    residenceAddress: "",
    residenceCountry: "",
    documents: {
      documentProofFileId: null,
      addressProofFileId: null
    }
  } as ExtendedIndividualFounderModel["personalData"],

  businessName: "",

  legalData: {
    country: '',
    address: "",
    registrationNumber: "",
    registrationDate: ""
  } as BusinessFounderModel["legalData"],

  about: '',
  webSite: ''
};

/* Component */
export default function Founder(props: {
  founder: ExtendedFounderModel,
  isSubmitAvailable: boolean,
  isActive: boolean,
  isPending?: boolean,
  onDelete?(): void,
  onUpdate(founder: ExtendedFounderModel): void
}) {

  /* State */
  const [founder, setFounder] = useState<typeof props.founder>(props.founder);
  const [uploadedFiles, setUploadedFiles] = useState({
    personalData: {
      documents: {
        documentProof: founder.type === 'individual' ? founder.personalData.documents.documentProof : null,
        addressProof: founder.type === 'individual' ? founder.personalData.documents.addressProof : null
      }
    }
  });

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if(props.isPending) return

    let $founder: FounderModel | null = null

    Promise.resolve()
    .then(() => {

      /* Compose founder object */
      if(founder.type === 'individual') {
        return $founder = {
          type: 'individual',

          fullName: founder.fullName,
          dateOfBirth: founder.dateOfBirth,

          personalData: founder.personalData,
        } as IndividualFounderModel
      }
      else {
        return $founder = {
          type: 'business',
          businessName: founder.businessName,
          legalData: founder.legalData,
          about: founder.about,
          webSite: founder.webSite
        } as BusinessFounderModel
      }
    })
    .then((founder: FounderModel) => {

      /* Validate founder */
      return validateFounder(founder)
    })
    .then((validation) => {

      if(typeof validation === 'string') {
        throw new Error(validation)
      }
    })
    .then(() => {

      props.onUpdate({
        ...$founder,

        ...($founder?.type === 'individual' ? {

          personalData: {
            ...$founder.personalData,

            documents: {
              ...$founder.personalData.documents,
              documentProofFileId: uploadedFiles.personalData.documents.documentProof?._id,
              addressProofFileId: uploadedFiles.personalData.documents.addressProof?._id,
            }
          }
        } : null)
      } as ExtendedFounderModel)
    })
    .catch(onError)
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} className={classNames(styles.Founder, {isHidden: !props.isActive})}>
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Founder Type
        </div>

        <div className="page-form-segment-container">

          <div style={{height: 10}} />

          <div className="big-heading" style={{fontSize: "14pt"}}>
            Select founder type
          </div>

          <div style={{height: 10}} />

          <RowSelect
            values={[founder.type as string]}
            options={[
              {value: "individual", contents: "Individual"},
              {value: "business", contents: "Business"}
            ]}
            isSingleSelect={true}
            onChange={([value]: [UserType]) => setFounder({...founder, type: value as any})}
          />
        </div>
      </div>

      <div style={{height: 50, flexShrink: 0}} />

      {founder.type === "individual" ? (

        /* Individual founder */
        <React.Fragment>
          <div className="page-form-segment">

            <div className="page-form-segment-heading">
              Personal Information
            </div>

            <div className="page-form-segment-container">

              <div className="flex">
                <TextInput
                  placeholder="Full Name"
                  value={founder.fullName}
                  onChange={(value) => setFounder({
                    ...founder,
                    fullName: value
                  })}
                  isReadOnly={props.isPending}
                />

                <TextInput
                  placeholder="Date of Birth (YYYY-MM-DD)"
                  value={founder.dateOfBirth}
                  onChange={(value) => setFounder({
                    ...founder,
                    dateOfBirth: value
                  })}
                  isReadOnly={props.isPending}
                  maskOpt={{
                    mask: '0000-00-00'
                  }}
                />
              </div>

              <div style={{height: 30}} />

              <div className="flex">

                <Select
                  options={[
                    {
                      value: "passport", element: "Passport"
                    }, {
                      value: "idCard", element: "ID Card"
                    }
                  ]}
                  onSelect={(type: IndividualUserModel["personalData"]["documentType"]) => setFounder({
                    ...founder,
                    personalData: {
                      ...founder.personalData,
                      documentType: type
                    }
                  })}
                  value={founder.personalData.documentType}
                  placeholder={"Document Type"}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Citizenship"
                  value={founder.personalData.citizenShip}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {...founder.personalData, citizenShip: value}
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />
              </div>

            </div>
          </div>

          <div style={{height: 50, flexShrink: 0}} />

          <div className="page-form-segment">

            <div className="page-form-segment-heading">
              {founder.personalData.documentType === 'passport' ? ('Passport') : ('ID Card')}
            </div>

            <div className="page-form-segment-container">

              <div className="flex">
                <TextInput
                  placeholder="Number"
                  value={founder.personalData.passport.number}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {
                      ...founder.personalData,
                      passport: {...founder.personalData?.passport, number: value}
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Issuing Authority"
                  value={founder.personalData.passport.issuedBy}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {
                      ...founder.personalData,
                      passport: {...founder.personalData?.passport, issuedBy: value}
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />
              </div>

              <div style={{height: 30}} />

              <div className="flex">
                <TextInput
                  placeholder="Date of Issue (YYYY-MM-DD)"
                  value={founder.personalData.passport.issuedAt}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {
                      ...founder.personalData,
                      passport: {...founder.personalData?.passport, issuedAt: value}
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                  maskOpt={{
                    mask: '0000-00-00'
                  }}
                />

                <TextInput
                  placeholder="Date of Expiry (YYYY-MM-DD)"
                  value={founder.personalData.passport.expiringDate}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {
                      ...founder.personalData,
                      passport: {...founder.personalData?.passport, expiringDate: value}
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                  maskOpt={{
                    mask: '0000-00-00'
                  }}
                />
              </div>

              <div style={{height: 30}} />

              <div className="flex">

                <Select
                  options={countriesOptions}
                  onSelect={(value) => {
                    setFounder({
                      ...founder,
                      personalData: {
                        ...founder.personalData,
                        residenceCountry: value
                      }
                    });
                  }}
                  placeholder="Country of Residence"
                  value={founder.personalData.residenceCountry}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Residence address"
                  value={founder.personalData.residenceAddress}
                  onChange={(value) => setFounder({
                    ...founder,
                    personalData: {...founder.personalData, residenceAddress: value}
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />
              </div>
            </div>
          </div>

          <div style={{height: 50, flexShrink: 0}} />

          <div className="page-form-segment">

            <div className="page-form-segment-heading">
              Documents
            </div>

            <div className="page-form-segment-container">

              <div className="flex">
                <FileUploadBlock
                  file={uploadedFiles.personalData.documents.documentProof ?? undefined}
                  title={"ID / Passport\nCopy"}
                  icon="custom-scanner-1"
                  acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onUpload={(file) => setUploadedFiles({
                    ...uploadedFiles, personalData: {
                      ...uploadedFiles.personalData,
                      documents: {
                        ...uploadedFiles.personalData.documents,
                        documentProof: file
                      }
                    }
                  })}
                />

                <FileUploadBlock
                  file={uploadedFiles.personalData.documents.addressProof ?? undefined}
                  title={"Address\nproof"}
                  icon="custom-scanner-1"
                  acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onUpload={(file) => setUploadedFiles({
                    ...uploadedFiles, personalData: {
                      ...uploadedFiles.personalData,
                      documents: {
                        ...uploadedFiles.personalData.documents,
                        addressProof: file
                      }
                    }
                  })}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (

        /* Business founder */
        <React.Fragment>
          <div className="page-form-segment">

            <div className="page-form-segment-heading">
              Business Information
            </div>

            <div className="page-form-segment-container">

              <TextInput
                placeholder="Business Name"
                value={founder.businessName}
                onChange={(value) => setFounder({...founder, businessName: value})}
                isReadOnly={props.isPending || !props.isSubmitAvailable}
              />

              <div style={{height: 30}} />

              <div className="flex">
                <TextInput
                  placeholder="Registration number"
                  value={founder.legalData.registrationNumber}
                  onChange={(value) => setFounder({
                    ...founder, legalData: {
                      ...founder.legalData,
                      registrationNumber: value
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Registration date (YYYY-MM-DD)"
                  value={founder.legalData.registrationDate}
                  onChange={(value) => setFounder({
                    ...founder, legalData: {
                      ...founder.legalData,
                      registrationDate: value
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                  maskOpt={{
                    mask: '0000-00-00'
                  }}
                />
              </div>

              <div style={{height: 30}} />

              <div className="flex">

                <Select
                  options={countriesOptions}
                  onSelect={(value) => {
                    setFounder({
                      ...founder,
                      legalData: {
                        ...founder.legalData,
                        country: value
                      }
                    })
                  }}
                  placeholder="Country of Registration"
                  value={founder.legalData.country}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Registration address"
                  value={founder.legalData.address}
                  onChange={(value) => setFounder({
                    ...founder, legalData: {
                      ...founder.legalData,
                      address: value
                    }
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />
              </div>

              <div style={{height: 30}} />

              <div className="flex">
                <TextInput
                  placeholder="About the business"
                  value={founder.about}
                  onChange={(value) => setFounder({
                    ...founder,
                    about: value
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />

                <TextInput
                  placeholder="Web site"
                  value={founder.webSite}
                  onChange={(value) => setFounder({
                    ...founder,
                    webSite: value
                  })}
                  isReadOnly={props.isPending || !props.isSubmitAvailable}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <div style={{height: 30, flexShrink: 0}} />

      <div className="flex">

        {props.onDelete ? (
          <Button
            additional={{isZeroed: true}}
            disabled={props.isPending || !props.isSubmitAvailable}
            onClick={() => props.onDelete?.()}
          >

            {!props.isPending ? (
              <BgIcon
                icon="trash-can-27"
                isLightIcon={true}
                background={props.isSubmitAvailable ? "var(--red)" : "#ddd"}
              />
            ) : (
              <Loader spinsPerSecond={3} />
            )}

            <span>Delete founder</span>
          </Button>
        ) : null}

        <Button
          additional={{isZeroed: true}}
          disabled={props.isPending || !props.isSubmitAvailable}
          style={{marginLeft: "auto"}}
        >
          <span>Submit</span>

          {!props.isPending ? (
            <BgIcon icon="arrow-right-lined" background={props.isSubmitAvailable ? "var(--soft-green)" : "#ddd"} />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
