import AccountModel from "../accounts";
import FileModel from "../files";
import DirectorModel from "./models/Director.ts";
import {ExtendedDirectorModel} from "./models/Director.ts";
import {FounderModel} from "./models/Founder.ts";
import {ExtendedFounderModel} from "./models/Founder.ts";
import UboModel from "./models/Ubo.ts";
import {ExtendedUboModel} from "./models/Ubo.ts";

export type UserType = "individual" | "business";
export type IndividualUserKYCHeading = "general" | "personal" | "financial"

export type BusinessUserKYCHeading =
  | "general"
  | "business"
  | "founders"
  | "directors"
  | "ubos"
  | "financial";

export interface BusinessUserModel extends BaseUserModel {
  type: "business",
  
  businessName: string,
  
  acceptedKycs: BusinessUserKYCHeading[],
  rejectedKycs: BusinessUserKYCHeading[],
  filledKycs: BusinessUserKYCHeading[],
  
  legalData: {
    country: string,
    address: string,
    registrationNumber: string,
    registrationDate: string,
    certificateOfRegistrationFileId: FileModel["_id"] | null,
    addressProofFileId: FileModel['_id'] | null
  },
  
  webSite: string,
  
  about: string,
  
  license: {
    number: string,
    issuedBy: string,
    proofFileId: FileModel["_id"] | null,
  } | null,
  
  founders: FounderModel[],
  directors: DirectorModel[],
  ubos: UboModel[],
  maaFileId: FileModel["_id"] | null,
  registerExtractFileId: FileModel["_id"] | null
}

export interface BaseUserModel {
  _id: string,
  phoneNumber: string,
  email: string,
  passwordHash: string,
  twoFaKey: string | null,
  createdAt: Date,
  isSuspended: boolean,
  isEmailVerified: boolean,

  accountsIds: AccountModel["_id"][],

  financialData: {
    purposeOfUse: string,
    monthlyIncome: number | null,
    monthlyExpense: number | null,
    sourceOfIncome: string,
    soiArray: string[]
  }
}

export interface IndividualUserModel extends BaseUserModel {
  type: "individual",
  firstName: string,
  lastName: string,
  fullName: string,
  dateOfBirth: string,

  acceptedKycs: IndividualUserKYCHeading[],
  rejectedKycs: IndividualUserKYCHeading[],
  filledKycs: IndividualUserKYCHeading[],

  personalData: {
    documentType: "passport" | 'idCard' | '',
    citizenShip: string,
    passport: {
      number: string,
      issuedAt: string,
      issuedBy: string,
      expiringDate: string,
    },
    residenceAddress: string,
    residenceCountry: string,
    documents: {
      documentProofFileId: FileModel['_id'] | null,
      addressProofFileId: FileModel['_id'] | null
    }
  }
}

export interface ExtendedIndividualUserModel extends IndividualUserModel {
  personalData: IndividualUserModel["personalData"] & {
    documents: IndividualUserModel["personalData"]["documents"] & {
      documentProof: FileModel | null,
      addressProof: FileModel | null
    },
  };
}


export interface ExtendedBusinessUserModel extends BusinessUserModel {
  legalData: BusinessUserModel['legalData'] & {
    certificateOfRegistration: FileModel | null,
    addressProof: FileModel | null
  },
  license: {
    number: string,
    issuedBy: string,
    proofFileId: FileModel["_id"] | null,
    proof: FileModel | null
  } | null,
  founders: ExtendedFounderModel[],
  directors: ExtendedDirectorModel[],
  ubos: ExtendedUboModel[],
  maa: FileModel | null,
  registerExtract: FileModel | null
}

export type UserModel = IndividualUserModel | BusinessUserModel;
export type ExtendedUserModel = ExtendedIndividualUserModel | ExtendedBusinessUserModel;

export const availableKycs = {
  individual: ["personal", "financial", "general"] as IndividualUserKYCHeading[],
  business: [
    "general",
    "business",
    "founders",
    "directors",
    "ubos",
    "financial"
  ] as BusinessUserKYCHeading[]
};

export const soiArrayIndividual = [
  'Drawings/Income from disclosed self-employed business',
  'Capital stocks sale',
  'Savings',
  'Salary',
  'Investment Income',
  'Royalties/Rewards',
  'Interest receivable and dividends',
  'Inheritance/Gifts',
  'Private property sale',
  'Income from rent'
]

export const soiArrayBusiness = [
  'Employment income',
  'Cryptocurrency trading',
  'Capital replenishment',
  'Loans',
  'Savings',
  'Beneficial owner own funds'
]
