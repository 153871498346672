import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './MyCardSelector.module.css';
import { FC } from 'react';
import { ICardDto } from '../../../../../../services/card-api';
import { Card } from './../../../../Cards/components/Card/Card.tsx';
import { formatCardNumber, maskCardNumber } from './../../../../../../helpers/card-number.helper.ts';

interface ICardSelectorProps {
  cards: ICardDto[];
  activeCard: ICardDto | null;
  onCardClick: (card: ICardDto) => void;
}

export const MyCardsSelector: FC<ICardSelectorProps> = observer(
  ({ cards, activeCard, onCardClick }) => {
    return (
      <div className={styles.CardsSelector}>
        {cards.map((card: ICardDto) => (
          <div className={cn(styles.Option, {[styles.Option_active]: activeCard?.id === card.id })} key={card.id}>
            <Card
              cardType={card.cardType}
              isShowBalance={false}
              isShowCardNumber={false}
              isShowCvvAndDate={false}
              isShowKindCard={false}
              styled={'MyCardsSelector'}
              onClick={() => onCardClick(card)}
            ></Card>
            <div className={styles.OptionCardNumber}>{formatCardNumber(maskCardNumber(card.number))}</div>
          </div>
        ))}
      </div>
    );
  }
);
