import classNames from 'classnames';
import type { ComponentProps, ForwardedRef, MouseEventHandler } from 'react';
import { To, useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import styles from './index.module.css';
import { forwardRef } from 'react';

const Button2 = forwardRef<any, {
  text: string;
  icon?: string;
  type?: "button" | "submit" | "reset";
  isDuctile?: boolean;
  disabled?: boolean;
  isUnderlined?: boolean;
  hasBackground?: boolean;
  flipIcon?: boolean;
  isNegative?: boolean;
  isPadded?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  goTo?: To;
  buttonProps?: ComponentProps<'button'>;
}>((props, ref) => {
  // navigation
  const navigate = useNavigate();

  // handlers

  const clickHandler: MouseEventHandler<HTMLButtonElement> =
    function clickHandler(ev) {
      props.onClick?.(ev);

      props.goTo && navigate(props.goTo);
    };

  /* DOM */
  return (
    <button
      {...props.buttonProps}
      ref={ref}
      type={props.type}
      disabled={props.disabled}
      className={classNames(props.className, styles.Button, {
        isDuctile: props.isDuctile,
        isUnderlined: props.isUnderlined,
        hasBackground: props.hasBackground,
        isPadded: props.isPadded,
        isNegative: props.isNegative
      })}
      onClick={clickHandler}>
      {props.icon && props.flipIcon && (
        <div className={classNames(styles.icon, 'flip')}>
          <Icon icon={props.icon} />
        </div>
      )}

      <span>{props.text}</span>

      {props.icon && !props.flipIcon && (
        <div className={styles.icon}>
          <Icon icon={props.icon} />
        </div>
      )}
    </button>
  );
});

export default Button2;