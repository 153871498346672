import { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.css';

interface IGetMyCardBlockProps {
  onClickGetMyCard?: () => void;
  isDisabledBtn?: boolean;
}

const GetMyCardBlock: FC<IGetMyCardBlockProps> = ({
  onClickGetMyCard,
  isDisabledBtn,
}) => {
  return (
    <section className={styles.infoBlock}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textColumn}>
            <div className={styles.textWrapper}>
              <p className={styles.description}>
                Begin with a short KYC form to receive your card— it's fast and
                convenient. Your card is just a step away!
              </p>
              <button className={styles.ctaButton} onClick={onClickGetMyCard} disabled={isDisabledBtn}>
                Get my card
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetMyCardBlock;
