import classNames from "classnames";
import React from "react";
import {MyAccountsObj} from "../../../../../controllers/accounts/getList.ts";
import styles from "./index.module.css";
import ClickToCopy from "../../../../../components/ClickToCopy/index.tsx";

export default function AccountBlock(props: {
  isExpanded?: boolean,
  accountObj: MyAccountsObj,
  isSelected?: boolean,

  onClick?(): void
}) {

  /* DOM */
  return (
    <div
      className={classNames("white-block", styles.AccountBlock, {
        isSelectable: props.isSelected !== undefined,
        isSelected: props.isSelected
      })}
      onClick={() => props.onClick?.()}
    >

      <div className="flex flex-aic flex-jcsb">
        <div className={styles.currency} style={{color: props.isSelected ? "var(--green)" : "#aaa"}}>
          {props.accountObj.currency}
        </div>

        <ClickToCopy valueToCopy={props.accountObj.number} displayValue={`№${props.accountObj.number}`} />
      </div>

      <div className={styles.balances}>
        <div>
          <div style={{height: 30}} />
          <div style={{fontWeight: "bold", opacity: .6}}>
            Total
          </div>

          <div style={{height: 5}} />
          <div style={{fontSize: "21pt", fontWeight: "bold"}}>
            {(props.accountObj.balances.total / 100).toFixed(2)}
          </div>
        </div>

        <div>
          <div style={{height: 30}} />
          <div style={{fontWeight: "bold", opacity: .6}}>
            Pending
          </div>

          <div style={{height: 5}} />
          <div style={{fontSize: "21pt", fontWeight: "bold"}}>
            {(props.accountObj.balances.pending / 100).toFixed(2)}
          </div>
        </div>

        <div>
          <div style={{height: 30}} />
          <div style={{fontWeight: "bold", opacity: .6}}>
            Available
          </div>

          <div style={{height: 5}} />
          <div style={{fontSize: "21pt", fontWeight: "bold"}}>
            {(props.accountObj.balances.available / 100).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
