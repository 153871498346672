export const actionButtons: IActionButton[] = [
  {
    icon: 'arrow-bottom-with-green-container',
    text: 'Top up',
    type: 'topUp',
    disabled: false,
  },
  {
    icon: 'lock-in-green-container-icon',
    text: 'Lock',
    type: 'lock',
    disabled: false,
  },
  {
    icon: 'unlock-with-green-container',
    text: 'Unlock',
    type: 'unlock',
    disabled: false,
  },
  {
    icon: 'shield-tick-with-green-container',
    text: 'Set PIN',
    type: 'setPin',
    disabled: false,
  },
  {
    icon: 'eye-with-green-container',
    text: 'Card details',
    type: 'details',
    disabled: false,
  }
] as const;

export interface IActionButton {
  icon: string;
  text: string;
  type: ActionType;
  disabled: boolean;
}

export type ActionType = 'topUp' | 'lock' | 'unlock' | 'setPin' | 'details';