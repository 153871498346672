import { makeAutoObservable } from "mobx";

export class BurgerMenuStore {
  public isBurgerMenuOpen = false;

  public constructor() {
    makeAutoObservable(this);
  }

  public openBurgerMenu() {
    this.isBurgerMenuOpen = true;
  }

  public closeBurgerMenu() {
    this.isBurgerMenuOpen = false;
  }

  public toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }
}

export const burgerMenuStore = new BurgerMenuStore();