import classNames from "classnames";
import React from "react";
import Icon from "../Icon";
import styles from "./index.module.css";

export default function ActionCard(props: {
  icon: React.ReactNode,
  rightIcon?: React.ReactNode,
  text: React.ReactNode,
  onClick?(): void,
  className?: string
}) {

  /* DOM */
  return (
    <div
      className={classNames("white-block", styles.ActionCard, props.className)}
      onClick={() => props.onClick?.()}
    >
      <div className="flex flex-aic">

        <div className={styles.icon}>
          {props.icon}
        </div>

        <div style={{width: "100%"}} />

        <div className={styles.rightIcon}>
          {props.rightIcon ?? (
            <Icon icon="arrow-63" style={{opacity: .2}} />
          )}
        </div>
      </div>

      <div className={styles.text}>
        {props.text}
      </div>
    </div>
  );
}
