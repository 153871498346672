import classNames from "classnames";
import {observer} from "mobx-react-lite";
import React, {ReactNode} from "react";
import {Navigate, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import BgIcon from "../../../../components/BgIcon";
import Button from "../../../../components/Button";
import ProgressBar from "../../../../components/ProgressBar";
import {BusinessUserKYCHeading, IndividualUserKYCHeading} from "../../../../controllers/users";
import getUserVerificationLevel from "../../../../helpers/getUserVerificationLevel.ts";
import onError from "../../../../helpers/onError.ts";
import BBusinessStep from "../steps/BBusinessStep";
import BDirectorsStep from "../steps/BDirectorsStep";
import BFinancialStep from "../steps/BFinancialStep";
import BFoundersStep from "../steps/BFoundersStep";
import BGeneralStep from "../steps/BGeneralStep";
import BUbosStep from "../steps/BUbosStep";
import IFinancialStep from "../steps/IFinancialStep";
import IGeneralStep from "../steps/IGeneralStep";
import IPersonalStep from "../steps/IPersonalStep";
import KycFinal from "../steps/KycFinal";
import styles from "./index.module.css";

import mainStore from "../../../../store/main.ts";

/* Vars */
const kycStepsSlugs: {
  [key: string]: string;
} = {
  general: "General",
  personal: "Personal Info",
  financial: "Financial Info",

  business: "Business Info",
  founders: "Business Founders",
  directors: "Directors Info",
  ubos: "UBOs"
};

const individualKycSteps = ["general", "personal", "financial"];
const businessKycSteps = ["general", "business", "founders", "directors", "ubos", "financial"];

/* Component */
function KycPage() {

  if(!mainStore.user) return null;

  /* Hooks */
  const $navigate = useNavigate();
  const $location = useLocation();
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const userVerificationLevel = getUserVerificationLevel(mainStore.user);
  const step = $searchParams.get("step") || "general";
  const steps = mainStore.user!.type === "individual" ? (
    individualKycSteps
  ) : (
    businessKycSteps
  );
  const stepNo = steps.indexOf(step) + 1;

  /* KYC Navigate functions */
  function toNextStep() {
    const nextStep = steps[stepNo] as string | undefined;

    if(!nextStep) return;

    $setSearchParams(new URLSearchParams({
      ...$searchParams,
      step: nextStep
    }));
  }

  function toPrevStep() {
    const prevStep = steps[stepNo - 2] as string | undefined;

    if(!prevStep) return;

    $setSearchParams(new URLSearchParams({
      ...$searchParams,
      step: prevStep
    }));
  }

  /* DOM */
  return (
    <div className={classNames("container", "regular-page", styles.KycPage)}>

      <div style={{display: "flex"}}>
        <div className="big-heading" style={{flexShrink: 0}}>
          KYC / {kycStepsSlugs[step] || "Final"}
        </div>

        <div style={{width: "100%"}} />

        <Button additional={{isZeroed: true}} onClick={() => $navigate("/app/profile")}>
          Back to Profile
        </Button>
      </div>

      <div style={{height: 30}} />

      <div style={{display: "flex", gap: 30}}>
        <div className={classNames(styles.pageLeft)}>

          <div className={classNames("white-block", styles.kycProgressBlock)}>

            <div className="flex">

              <Button
                additional={{isZeroed: true}}
                onClick={() => toPrevStep()}
                disabled={!steps[stepNo - 2]}
              >
                <div style={{transform: "rotateZ(180deg)"}}>
                  <BgIcon icon="arrow-right-lined" background={steps[stepNo - 2] ? "var(--soft-green)" : "#D9D9D9"} />
                </div>
                <span>Back</span>
              </Button>

              <div style={{width: "100%"}} />

              <Button
                additional={{isZeroed: true}}
                onClick={() => toNextStep()}
                disabled={!steps[stepNo] || step === "final"}
              >
                <span>Forward</span>
                <BgIcon
                  icon="arrow-right-lined"
                  background={steps[stepNo] && step !== "final" ? "var(--soft-green)" : "#D9D9D9"}
                />
              </Button>
            </div>

            {!(
              userVerificationLevel === "verified"
              ||
              mainStore.user.type === "individual" && mainStore.user.filledKycs.length >= 3
              ||
              mainStore.user.type === "business" && mainStore.user.filledKycs.length >= 6
            ) ? (
              <React.Fragment>
                <div style={{height: 30}} />
                <div style={{fontWeight: 600, fontSize: "11pt"}}>
                  Progress of filling KYC out
                </div>
                <div style={{height: 10}} />
                <ProgressBar progress={stepNo / steps.length * 100} />
              </React.Fragment>
            ) : null}
          </div>
        </div>

        <div className={classNames("white-block", styles.pageRight)}>

          {mainStore.user.type === "individual" ? (

            step === "general" ? (<IGeneralStep />) : (
              step === "personal" ? (<IPersonalStep />) :
                step === "financial" ? (<IFinancialStep />) :
                  step === "final" ? (<KycFinal />) : null
            )
          ) : mainStore.user.type === "business" ? (
            step === "general" ? (<BGeneralStep />) :
              step === "business" ? (<BBusinessStep />) :
                step === "founders" ? (<BFoundersStep />) :
                  step === "directors" ? (<BDirectorsStep />) :
                    step === "ubos" ? (<BUbosStep />) :
                      step === "financial" ? (<BFinancialStep />) :
                        step === "final" ? (<KycFinal />) : null
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default observer(KycPage);
