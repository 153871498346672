import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './index.module.css';

interface TableProps {
  className?: string;
  children?: ReactNode;
}

export const Table: FC<TableProps> = (props) => {
  return (
    <table className={classNames(props.className, styles.table, 'basic_block')}>
      {props.children}
    </table>
  );
};
