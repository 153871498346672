import apiClient from "../../helpers/apiClient.ts";
import AccountModel, {AccountCurrency} from "./index.ts";

export type MyAccountsObj = {
  "_id": AccountModel['_id'],
  "currency": AccountCurrency,
  "amount": AccountModel['amount'],
  "number": AccountModel['number'],
  "balances": {
    "total": number,
    "available": number,
    "pending": number,
    "irreducible": number
  }
}

export default async function getMyAccounts(

): Promise<MyAccountsObj[]> {

  const {data: accounts} = await apiClient.get<MyAccountsObj[]>('/accounts/getList')

  return accounts
}
