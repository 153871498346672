import { GroupHeadingProps } from 'react-select';
import styles from './GroupHeading.module.css';
import React from 'react';

export const GroupHeading = (props: GroupHeadingProps) => {
  const { className, cx } = props;
  
  return (
    <div
      className={cx({ 'group-heading': true }, styles.GroupHeading, className)}
    >
      <span>{props.data.label}</span>
      <span className={styles.GroupHeadingLine}></span>
    </div>
  );
};