import {
  CardType,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeVisaPhysical,
  CardTypeVisaVirtual
} from '../services/card-api/interfaces';

export enum CardTier {
  Platinum = 'Platinum',
  Gold = 'Gold',
  Silver = 'Silver',
}

export const isSilverCard = (cardType: CardType): boolean =>
  cardType === CardTypeVisaVirtual.VirtualVisaSilver ||
  cardType === CardTypeMastercardVirtual.VirtualMastercardSilver;

export const isGoldCard = (cardType: CardType): boolean =>
  cardType === CardTypeVisaVirtual.VirtualVisaGold ||
  cardType === CardTypeMastercardVirtual.VirtualMastercardGold;

export const isPlatinumCard = (cardType: CardType): boolean =>
  cardType === CardTypeVisaVirtual.VirtualVisaPlatinum ||
  cardType === CardTypeMastercardVirtual.VirtualMastercardPlatinum ||
  cardType === CardTypeMastercardPhysical.PhysicalMastercard ||
  cardType === CardTypeVisaPhysical.PhysicalVisa;

export const getCardTier = (cardType: CardType): CardTier | null => {
  switch (true) {
    case isSilverCard(cardType):
      return CardTier.Silver;
    
    case isGoldCard(cardType):
      return CardTier.Gold;
    
    case isPlatinumCard(cardType):
      return CardTier.Platinum;
    
    default:
      return null;
  }
}