import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './index.module.css';

interface TBodyProps {
  classname?: string;
  children?: ReactNode;
}

export const TBody: FC<TBodyProps> = (props) => {
  return (
    <tbody className={classNames(props.classname, styles.tBody)}>
      {props.children}
    </tbody>
  );
};
