export const getFileSize = (file: File, unit?: ISizeUnit) => {
  switch (unit) {
    case 'kb':
      return file.size / 1024;
    case 'mb':
      return file.size / (1024 * 1024);
    case 'gb':
      return file.size / (1024 * 1024 * 1024);
    default:
      return file.size;
  }
}

type ISizeUnit = 'bytes' | 'kb' | 'mb' | 'gb';