import apiClient from "../../helpers/apiClient.ts";
import validateGetListParams, {Params} from "../../helpers/validateGetListParams.ts";
import {ExtendedUserModel, UserModel} from "../users";
import {ExtendedTransactionModel} from "./index.ts";

import mainStore from '../../store/main.ts'

export async function getTransactionsList(
  params: Params
): Promise<ExtendedTransactionModel[]> {

  /* Get getList params */
  const getListParams = validateGetListParams<{}>(params, {});
  if(typeof getListParams === "string") {
    throw new Error(getListParams);
  }

  const {
    offset,
    limit,
    searchQuery
  } = getListParams;

  /* Get users list */
  const {data: transactions} = (
    await apiClient.get<ExtendedTransactionModel[]>("/transactions/getList", {
      params: {
        offset,
        limit,
        searchQuery
      }
    })
  );

  /* Return users list */
  return transactions;
}
