import classNames from "classnames";
import {DateTime} from "luxon";
import { FormEvent, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import createUser from "../../../../controllers/users/create.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";
import parentStyles from "../../Login.module.scss";
import styles from "./SignUpFinish.module.scss";

import mainStore from "../../../../store/main.ts";
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';

/* Initial form state */
const initialFormState = {
  email: "",
  password1: "",
  password2: "",
  firstName: "",
  lastName: "",
};

/* Component */
export default function SignUpFinishAction() {

  /* Hooks */
  const $navigate = useNavigate();
  const [$searchParams, $setSearchParams] = useSearchParams();
  const $breakpoint = useBreakpoint();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [isBiggerControls, setIsBiggerControls] = useState<boolean>(false);

  useEffect(() => {
    setIsBiggerControls(isBreakpointGreaterThan($breakpoint, Breakpoints.XL));
  }, [$breakpoint]);

  /* Vars */
  const phoneNumber = $searchParams.get("phoneNumber") as string;
  const smsToken = $searchParams.get("smsToken") as string;
  if (!phoneNumber || !smsToken) {
    return (<Navigate to="./?action=signUpPhone" />);
  }

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
      .then(() => {

        /* Validate client data */
        for (const validation of [
          validate("name", formState.firstName, 'First Name'),
          validate("name", formState.lastName, 'Last Name'),
          validate("email", formState.email),
          validate("phoneNumber", phoneNumber),
          validate("password", formState.password1),
        ]) {
          if (typeof validation === "string") {
            throw new Error(validation);
          }
        }
      })
      .then(() => {
        if (formState.password1 !== formState.password2) {
          throw new Error("Passwords don't match");
        }
      })
      .then(() => {
        return createUser({
          phoneNumber,
          ...formState,
          password: formState.password2,
        }, smsToken);
      })
      .then(() => {

        /* On Done */
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `E-Mail sent`,
          contents: `We've sent you E-Mail with activation link`
        });

        mainStore.setSmsConfirmationCooldown(DateTime.now().plus({minutes: 3}));

        $navigate(`./?action=emailSent&email=${formState.email}`);
      })
      .catch(onError)
      .finally(() => {
        setIsFormPending(false);
      });
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(styles.SignUpFinish, 'tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')}>
        <div className="xl:tw-text-3.5xl">Create your account</div>
        <div className="tw-flex tw-flex-gap-5 tw-leading-5 tw-justify-between tw-font-semibold tw-mt-4">
          <p className="tw-text-sm xl:tw-text-base">
            Phone number successfuly confirmed.
            <br/>
            Enter your email address and password
          </p>
          <p className="tw-text-end tw-hidden xl:tw-inline-block">
            By signing up on Vplus, you
            <br/>
            agree to our <Link to="/terms" className="tw-underline">Terms of Use</Link>
          </p>
        </div>

        <form
          className={styles.SignUpFinishForm}
          onSubmit={onFormSubmit}
        >
          <div className={classNames(styles.Controls, 'tw-flex tw-flex-col')}>
            <div className="tw-flex tw-gap-3">
              <TextInput
                className="tw-flex-1"
                placeholder="First name"
                value={formState.firstName}
                onChange={(value) => setFormState({ ...formState, firstName: value })}
                isBigger={isBiggerControls}
                isReadOnly={isFormPending}
                minLength={2}
                maxLength={32}
                required
              />
              <TextInput
                className="tw-flex-1"
                placeholder="Last name"
                value={formState.lastName}
                onChange={(value) => setFormState({ ...formState, lastName: value })}
                isBigger={isBiggerControls}
                isReadOnly={isFormPending}
                minLength={2}
                maxLength={32}
                required
              />
            </div>
            <TextInput
              placeholder="Email"
              value={formState.email}
              onChange={(value) => setFormState({ ...formState, email: value })}
              isBigger={isBiggerControls}
              isReadOnly={isFormPending}
            />
            <TextInput
              type="password"
              placeholder="Password"
              value={formState.password1}
              onChange={(value) => setFormState({ ...formState, password1: value })}
              isBigger={isBiggerControls}
              showPasswordButton
              isReadOnly={isFormPending}
            />
            <TextInput
              type="password"
              placeholder="Confirm password"
              value={formState.password2}
              onChange={(value) => setFormState({ ...formState, password2: value })}
              isBigger={isBiggerControls}
              showPasswordButton
              isReadOnly={isFormPending}
            />
          </div>
          <p className="tw-block tw-text-sm tw-mt-9.5 xl:tw-hidden">
            By signing up on Vplus, you agree to our <Link to="/terms" className="tw-underline">Terms of Use</Link>
          </p>
          <Button
            additional={{ isZeroed: true }}
            disabled={isFormPending}
            className="tw-mt-7 xl:tw-mt-12 tw-ml-auto"
          >
            <span>Next step</span>

            {!isFormPending ? (
              <img className="tw-size-8.75" src="/icons/arrow-right-background.svg" alt=""/>
            ) : (
              <Loader spinsPerSecond={3}/>
            )}
          </Button>
        </form>
      </div>

      <div className={
        classNames(
          styles.HasAccount,
          'tw-flex tw-items-center tw-mt-7 tw-bg-softGreen tw-rounded-xl xl:tw-rounded-2xl xl:tw-mt-5'
        )}>
        <img src="/icons/face-1.svg" alt=""/>
        <div className="tw-text-xl xl:tw-text-3.5xl">
          <div>Already has an account?</div>
          <Link to="./" className="tw-underline">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
}
