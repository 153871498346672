import validate from "../../../helpers/validate.ts";
import UboModel from "../models/Ubo.ts";

export default async function validateUbo(
  ubo: UboModel
): Promise<true | string> {

  /* Validate data */
  for(const validation of [
    validate("personName", ubo.fullName, "UBO full name"),
    validate("dateOfBirth", new Date(ubo.dateOfBirth), "UBO date of birth"),

    validate("comment256", ubo.personalData.citizenShip, "UBO citizenship"),
    validate("comment256", ubo.personalData.passport.number, "UBO passport number"),
    validate("pastDate", new Date(ubo.personalData.passport.issuedAt), "UBO passport issue date"),
    validate("comment256", ubo.personalData.passport.issuedBy, "UBO passport IA"),
    validate("date", new Date(ubo.personalData.passport.expiringDate), "UBO passport expiry date"),
    validate("comment256", ubo.personalData.residenceAddress, "UBO residence address"),
    validate("comment256", ubo.personalData.residenceCountry, "UBO residence country"),
  ]) {
    if(typeof validation === "string") {
      return validation;
    }
  }

  /* Check passport dates */
  const passportIssuedAt = new Date(ubo.personalData.passport.issuedAt);
  const passportExpiryDate = new Date(ubo.personalData.passport.expiringDate);

  if(passportExpiryDate < passportIssuedAt || passportExpiryDate < new Date()) {
    return 'Passport is expired'
  }

  /* Return true if all correct */
  return true;
}
