import {AxiosError} from "axios";
import logout from "../controllers/users/logout.ts";
import mainStore from "../store/main.ts";
import {getRandomNumber} from "./misc.ts";
import { showDangerToast } from './toasts.helper.ts';

export default function onError(error: Error | AxiosError | unknown) {
  let errorMessage = `Unknown error (${error})`;
  let errorCode: number | null = null;
  if(error instanceof Error) {
    errorMessage = error.message ?? String(error);
  }

  if(error instanceof AxiosError) {
    if(error.response) {
      errorCode = error.response.status;

      if(errorCode === 401) {
        errorMessage = "Can't authorize this request. Sign in again";
        logout();
      }
      else {
        errorMessage = error.response.data?.error ?? error.response.data?.message ?? error.response.data;
      }
    }
    else {
      errorMessage = error.message;
    }
  }

  console.error(error);
  if(error instanceof AxiosError) {
    console.error(error.response?.status, error.response?.data);
  }
  
  showDangerToast(errorMessage);
  /*mainStore.addNotification({
    id: getRandomNumber(0, 9999999).toString(16),
    title: `Error${errorCode ? ` #${errorCode}` : ""}`,
    contents: errorMessage
  });*/
}
