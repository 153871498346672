export const occupationOptions = [
  { label: 'Actor', value: 'Actor' },
  { label: 'Aircraft Mechanic', value: 'Aircraft Mechanic' },
  { label: 'Book Author', value: 'Book Author' },
  { label: 'Businessmen', value: 'Businessmen' },
  { label: 'Cashier', value: 'Cashier' },
  { label: 'Cleaner', value: 'Cleaner' },
  { label: 'Dancer', value: 'Dancer' },
  { label: 'Dentist', value: 'Dentist' },
  { label: 'Fireman', value: 'Fireman' },
  { label: 'Housewife', value: 'Housewife' },
  { label: 'Journalist', value: 'Journalist' },
  { label: 'Medical', value: 'Medical' },
  { label: 'Assistant', value: 'Assistant' },
  { label: 'Musician', value: 'Musician' },
  { label: 'Teacher', value: 'Teacher' },
  { label: 'Painter', value: 'Painter' },
  { label: 'Policeman', value: 'Policeman' }
];