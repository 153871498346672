import { makeAutoObservable } from 'mobx';
import { cardApiService } from '../../../../../../services/card-api';

class CardPinDialogContentStore {
  private readonly _cardPinLength = 6;
  public cardPinValue = '';
  public isLoading = false;
  public successChanged = false;
  
  public constructor(private readonly _cardId: string) {
    makeAutoObservable(this);
  }
  
  public get isEnableBtnSave() {
    return this.cardPinValue.length === this._cardPinLength;
  }
  
  public async updateCardPin(value: string) {
    this._setLoading(true);
    this._setSuccess(false);
    try {
      await cardApiService.setCardPin(this._cardId, value);
      this._setSuccess(true);
    } finally {
      this._setLoading(false);
    }
  }
  
  public setCardPinValue(value: string) {
    this.cardPinValue = value;
  }
  
  private _setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  
  private _setSuccess(isSuccess: boolean) {
    this.successChanged = isSuccess;
  }
}

export function createPinDialogContentStore(cardId: string) {
  return new CardPinDialogContentStore(cardId);
}