import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import RowSelect from "../../../../../components/RowSelect";
import getMe from "../../../../../controllers/users/getMe.ts";
import {FounderModel} from "../../../../../controllers/users/models/Founder.ts";
import {ExtendedIndividualFounderModel} from "../../../../../controllers/users/models/Founder.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import Founder, {initialFounder} from "./components/Founder";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function BFoundersStep() {
  if(!mainStore.user || (mainStore.user.type !== "business")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    founders: mainStore.user.founders.length ? (
      mainStore.user.founders
    ) : (
      [initialFounder as ExtendedIndividualFounderModel]
    )
  };

  /* Vars */
  const isSubmitAvailable = !(
    mainStore.user.filledKycs.includes("founders")
    ||
    mainStore.user.acceptedKycs.includes("founders")
  );

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [selectedFounderIndex, setSelectedFounderIndex] = useState<number>(0);

  /* On form submit function */
  function onFormSubmit() {

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Send API request */
      return apiClient.post("/users/edit", {
        founders: formState.founders
      });
    })
    .then(() => {

      /* Send submit request */
      return apiClient.post("/users/submitKyc", {
        kycStep: "founders"
      });
    })
    .then(() => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `New data sent to our admins for verification`
      });

      /* Change step */
      $setSearchParams(new URLSearchParams({
        ...$searchParams,
        step: "directors"
      }));

      /* Update user data */
      return getMe();
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* Vars */
  const selectedFounder = formState.founders[selectedFounderIndex] as FounderModel | undefined

  /* DOM */
  return (
    <React.Fragment>

      <div className="flex">
        <RowSelect
          values={[String(selectedFounderIndex)]}
          options={formState.founders.map((founderm, i) => (
            {value: String(i), contents: `Founder ${i + 1}`}
          ))}
          onChange={([index]) => setSelectedFounderIndex(+index)}
          isSingleSelect={true}
        />

        {formState.founders.length < 5 && isSubmitAvailable ? (
          <Button
            additional={{isAutoWidth: true, isSoftGreen: true}}
            onClick={() => setFormState({
              ...formState,
              founders: [...formState.founders, initialFounder]
            })}
          >
            <Icon icon="plus-lined" />
          </Button>
        ) : null}
      </div>

      <div style={{height: 30}} />
      {formState.founders.map((founder, i) => (
        <Founder
          key={i}
          isActive={selectedFounderIndex === i}
          founder={founder}
          isSubmitAvailable={isSubmitAvailable}
          onDelete={i > 0 ? (() => {
            const $founders = [...formState.founders]

            $founders.splice(i, 1)

            setFormState({...formState, founders: $founders})

            if(i === selectedFounderIndex) {
              setSelectedFounderIndex(0)
            }
          }) : undefined}
          isPending={isFormPending}
          onUpdate={(founder) => {
            formState.founders[i] = founder;
            onFormSubmit()
          }}
        />
      ))}
    </React.Fragment>
  );
}
