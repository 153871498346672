import { makeAutoObservable } from 'mobx';
import { ICardDto, cardApiService } from '../../../../../services/card-api';

export class CardsStore {
  public cards: ICardDto[] = [];
  public loading: boolean = false;

  public constructor() {
    makeAutoObservable(this);
  }

  public async loadCards(): Promise<ICardDto[]> {
    this.setLoading(true);
    try {
      const cards = await cardApiService.getCards();
      this.setCards(cards);
      return cards;
    } catch {
      this.setCards([]);
      return [];
    } finally {
      this.setLoading(false);
    }
  }

  public setCards(cards: ICardDto[]): void {
    this.cards = cards;
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }
  
  public updateCard(cardId: string, payload: Partial<ICardDto>): void {
    this.cards = this.cards.map((card) => {
      if (card.id === cardId) return {...card, ...payload };
      return { ...card };
    });
  }
}

export function createCardsStore() {
  return new CardsStore();
}
