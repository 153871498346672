import { TransactionStatus, TransactionType } from '../services/card-api/interfaces';

export const operationTypeToTextMap: Record<TransactionType, string> = {
  [TransactionType.Deposit]: 'Deposit',
  [TransactionType.Withdraw]: 'Withdrawal',
  // Visa Virtual - Start
  [TransactionType.Unknown]: 'Unknown',
  [TransactionType.NewAccount]: 'New Account',
  [TransactionType.CardSale]: 'Card Issuance',
  [TransactionType.Purchase]: 'Purchase',
  [TransactionType.Refund]: 'Refund',
  [TransactionType.AccountFunding]: 'Deposit',
  [TransactionType.BalanceInquiry]: 'Balance Inquiry',
  [TransactionType.P2P]: 'P2P Transfer',
  [TransactionType.WalletTransfer]: 'Wallet Transfer',
  [TransactionType.Exchange]: 'Exchange',
  [TransactionType.ExchangeMargin]: 'Exchange Margin',
  [TransactionType.SecondPresentment]: 'Second Presentment',
  [TransactionType.Chargeback]: 'Chargeback',
  [TransactionType.Adjustment]: 'Adjustment',
  [TransactionType.ExchangeAdjustment]: 'Exchange Adjustment',
  [TransactionType.Maintenance]: 'Maintenance Charge',
  [TransactionType.Chargeoff]: 'Charge Off',
  [TransactionType.Withholding]: 'Withholding',
  [TransactionType.CloseAccount]: 'Account Closed',
  [TransactionType.Reward]: 'Reward',
  [TransactionType.Shipping]: 'Shipping',
  [TransactionType.ExpeditedShipping]: 'Priority Shipping',
  [TransactionType.Addressing]: 'Addressing',
  [TransactionType.Settlement]: 'Settlement Completed',
  [TransactionType.Consolidated]: 'Consolidation Completed',
  [TransactionType.Interest]: 'Interest Payment',
  [TransactionType.Cashback]: 'Cashback Payment',
  [TransactionType.Fee]: 'Fee Charge',
  [TransactionType.MonthlyFee]: 'Monthly Fee Charge',
  [TransactionType.AnnualFee]: 'Annual Fee Charge',
  [TransactionType.FastFund]: 'Fast Funds',
  [TransactionType.LoadReversal]: 'Load Reversal',
  [TransactionType.Expiry]: 'Expired Funds',
  [TransactionType.MoneyReceive]: 'Received Funds',
  [TransactionType.LatePaymentFee]: 'Late Payment Fee Charge'
  // Visa Virtual - End
};

export const getOperationTypeText = (type: TransactionType): string => operationTypeToTextMap[type] ?? type;

export const transactionTextByStatusMap = {
  [TransactionStatus.Success]: 'Completed',
  [TransactionStatus.Failed]: 'Failed',
  [TransactionStatus.Pending]: 'Pending',
} as const;

export const transactionExecutionTextByStatusMap = {
  [TransactionStatus.Failed]: 'Rejected',
  [TransactionStatus.Pending]: 'Pending',
  [TransactionStatus.Success]: 'Executed',
} as const;


// Group methods on inflow/outflow
const inflowOperation = [
  TransactionType.Deposit,
  TransactionType.Refund,
  TransactionType.AccountFunding,
  TransactionType.Chargeback,
  TransactionType.Reward,
  TransactionType.Settlement,
  TransactionType.Interest,
  TransactionType.Cashback,
  TransactionType.P2P,
  TransactionType.WalletTransfer,
];

const outflowOperation = [
  TransactionType.Withdraw,
  TransactionType.Purchase,
  TransactionType.BalanceInquiry,
  TransactionType.Maintenance,
  TransactionType.Withholding,
  TransactionType.Shipping,
  TransactionType.ExpeditedShipping,
  TransactionType.Fee,
  TransactionType.MonthlyFee,
  TransactionType.AnnualFee,
  TransactionType.Expiry,
  TransactionType.LatePaymentFee,
];

export const isInflowOperation = (operationType: TransactionType) => {
  return inflowOperation.some((item) => item === operationType);
}

export const isOutFlowOperation = (operationType: TransactionType) => {
  return outflowOperation.some((item) => item === operationType);
}

export const getOperationSign = (operationType: TransactionType): '+' | '-' | '' => {
  if (isInflowOperation(operationType)) return '+';
  if (isOutFlowOperation(operationType)) return '-';
  return '';
}