import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import apiClient from "../../../../helpers/apiClient.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./PasswordReset.module.scss";
import classNames from 'classnames';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';

/* Initial form state */
const initialFormState = {
  email: ""
};

/* Component */
export default function PasswordResetAction() {

  /* Hooks */
  const $navigate = useNavigate();
  const $breakpoint = useBreakpoint();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("email", formState.email)
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post("/users/sendPasswordResetRequest", {
        email: formState.email
      });
    })
    .then(() => {

      $navigate(`./?action=emailSent&email=${formState.email}`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(styles.PasswordReset, 'tw-bg-secondary-75 tw-rounded-2xl xl:tw-rounded-2xl')}>
        <div className="tw-text-xl xl:tw-text-3.5xl">Forgot password</div>
        <div className={classNames(styles.TextMessage, 'tw-text-sm xl:tw-text-base tw-mt-3 xl:tw-mt-4')}>
          Enter the email address that you’ve used to register
          the account and we will send you a link to reset your password
        </div>

        <form className="tw-mt-6.5 xl:tw-mt-5" onSubmit={onFormSubmit}>
          <TextInput
            placeholder="Email"
            value={formState.email}
            onChange={(value) => setFormState({...formState, email: value})}
            isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
            isReadOnly={isFormPending}
          />

          <Button additional={{isZeroed: true}} disabled={isFormPending} className="tw-ml-auto tw-mt-7.5 xl:tw-mt-11">
            <span>Reset</span>

            {!isFormPending ? (
              <img src="/icons/arrow-right-background.svg" alt="" />
            ) : (
              <Loader spinsPerSecond={3} />
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}
