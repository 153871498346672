import {useNavigate, useSearchParams} from "react-router-dom";
import ActionCard from "../../../../components/ActionCard";
import BgIcon from "../../../../components/BgIcon";
import LatestTransactionsBlock from "../../components/LatestTransactionsBlock";
import styles from "./index.module.css";

export default function MainPaymentsPage() {

  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const selectedAccountId = $searchParams.get('selectedAccountId')
  if(!selectedAccountId) {
    return null
  }

  /* DOM */
  return (
    <div className={styles.MainPaymentsPage}>
      <LatestTransactionsBlock />

      <div style={{height: 30}} />

      <div className={styles.actionCards}>
        <ActionCard
          icon={<BgIcon icon="arrow-58" background="var(--soft-green)" />}
          text={"Transfer\nyour funds"}
          onClick={() => $navigate(`/app/payments/transfer?selectedAccountId=${selectedAccountId}`)}
        />

        <ActionCard
          icon={<BgIcon icon="arrow-up-circle-filled" background="var(--soft-green)" />}
          text={"Withdrawal\nyour funds"}
          onClick={() => $navigate(`/app/payments/withdraw?selectedAccountId=${selectedAccountId}`)}
        />

        <ActionCard
          icon={<BgIcon icon="currency-34" background="var(--soft-green)" />}
          text={"Currency\nexchange"}
          onClick={() => $navigate(`/app/payments/exchange?selectedAccountId=${selectedAccountId}`)}
        />
      </div>
    </div>
  );
}
