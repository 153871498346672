import { fetch } from './../../utils/fetch.ts';
import { ICardBinDto } from './interfaces/card-bin.interface.ts';
import { CardType, IDepositDto } from '../card-api/interfaces';
import apiClient from '../../helpers/apiClient.ts';
import { AxiosResponse } from 'axios';
import onError from '../../helpers/onError.ts';
import { ICountryListDto } from './interfaces/country.interface.ts';
import { IAreaListDto } from './interfaces/area.interface.ts';
import { ICardKYCPayload, ISendCardKYCDto } from './interfaces/card-kyc.interface.ts';

export class CardKycApiService {
  private readonly _baseUrl: string = '/cards/kyc';

  public async getCardBins(): Promise<ICardBinDto[]> {
    const response = await fetch(`${this._baseUrl}/card_bins`, {
      method: 'GET'
    });

    if (!response?.ok) {
      throw new Error(response.statusText);
    }

    return (await response.json())?.cardBins ?? [];
  }
  
  public async getDeposit(cardId: string): Promise<IDepositDto> {
    try {
      const { data } = await apiClient.post(`/cards/${cardId}/deposit`) as AxiosResponse<IDepositDto>;
      return data;
    } catch (err) {
      onError(err);
      throw err;
    }
  }
  
  public async getCountries(): Promise<ICountryListDto> {
    try {
      const { data } = await apiClient.get(`${this._baseUrl}/countries`) as AxiosResponse<ICountryListDto>;
      return data;
    } catch (err) {
      onError(err);
      throw err;
    }
  }
  
  public async getAreas(cardType: CardType): Promise<IAreaListDto> {
    try {
      const { data } = await apiClient
        .get(`${this._baseUrl}/areas_headers`, { params: { cardType }}) as AxiosResponse<IAreaListDto>;
      return data;
    } catch (err) {
      onError(err);
      throw err;
    }
  }
  
  public async sendCardKYC(payload: ICardKYCPayload): Promise<ISendCardKYCDto> {
    try {
      const { data } = await apiClient.post(`${this._baseUrl}`, payload) as AxiosResponse<ISendCardKYCDto>;
      return data;
    } catch (err) {
      console.log(err);
      onError(err);
      throw err;
    }
  }
}

export const cardKycApiService: CardKycApiService = new CardKycApiService();
