import { CardDataActiveStatus, CardDataNotActiveStatus, CardStatus } from '../interfaces';

type Key = CardDataActiveStatus | CardDataNotActiveStatus;
export const detailCardStatusToCardStatusMap: Record<Key, CardStatus> = {
  [CardDataActiveStatus.Active]: CardStatus.ACTIVE,
  [CardDataNotActiveStatus.Pending]: CardStatus.KYCPending,
  [CardDataNotActiveStatus.NotApplied]: CardStatus.KYCFailed,
  [CardDataNotActiveStatus.VerificationFailed]: CardStatus.KYCFailed,
  [CardDataNotActiveStatus.ActionRequired]: CardStatus.KYCPending,
  [CardDataNotActiveStatus.WaitingForPayment]: CardStatus.WAITING_FOR_PAYMENT,
};