import classNames from 'classnames';
import React, { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import styles from './KYCStepTwo.module.css';
import { incomeOptions } from './../../../../consts/income-options.ts';
import { occupationOptions } from './../../../../consts/occupation-options.ts';
import { positionOptions } from './../../../../consts/position-options.ts';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import Button2 from '../../../../../../../../components/Button2';
import Select2 from '../../../../../../../../components/Select2';

// @ts-ignore
const KYCStepTwo = forwardRef<any, any>(({ onNextStepClick, formState }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [data, setFieldValue] = formState;

  const [annualIncome, setAnnualIncome] = useState(
    data.annualIncome || '5000-25000'
  );

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState[0];

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setFieldValue({ ...formState[0] });
  };

  const handleIncomeSelect = (value: string) => {
    setFieldValue(value, 'annualIncome');
    setAnnualIncome(value);
  };
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    
    if (!data.annualIncome) {
      showDangerToast('Please select annual income');
      return;
    }
    if (!data.position) {
      showDangerToast('Please select position');
      return;
    }
    
    if (!data.occupation) {
      showDangerToast('Please select occupation');
      return;
    }
    // setFieldValue('annualIncome', annualIncome);
    onNextStepClick();
  };

  return (
    <div className={styles.kycStepTwo}>
      <form className={styles.kycContainer} onSubmit={handleSubmit}>
        <div className={styles.leftColumn}>
          <h2>Financial Information:</h2>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.kycGrid}>
            <div className={styles.inputWrapper}>
              <label>Annual Income (HKD)</label>
              <div className={styles.incomeButtons}>
                {incomeOptions.map((option) => (
                  <button
                    key={option.value}
                    type="button"
                    className={classNames(styles.incomeButton, {
                      [styles.selectedButton]: annualIncome === option.value
                    })}
                    onClick={() => handleIncomeSelect(option.value)}>
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Select2
                label="Occupation"
                options={occupationOptions}
                selected={occupationOptions.find(
                  (opt) => opt.value === data.occupation
                )}
                onSelect={(option) =>
                  setNestedFieldValue('occupation', option.value)
                }
                required
                disabled
              />
            </div>
            <div className={styles.inputWrapper}>
              <Select2
                label="Position"
                options={positionOptions}
                selected={positionOptions.find(
                  (opt) => opt.value === data.position
                )}
                onSelect={(option) =>
                  setNestedFieldValue('position', option.value)
                }
                required
                disabled
              />
            </div>
          </div>
        </div>
        
        <div className={styles.controls}>
          <Button2
            ref={nextButtonRef}
            className={classNames(styles.kycBtn, 'BorderNone')}
            text="Next Step"
            icon="custom-arrow-1"
            buttonProps={{ type: 'submit' }}
            isDuctile
          />
        </div>
      </form>
    </div>
  );
});

export default KYCStepTwo;
