import React, {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import validate from "../../../../helpers/validate.ts";
import styles from './index.module.css';

import mainStore from "../../../../store/main.ts";
import Icon from '../../../../components/Icon';
import { showDangerToast } from '../../../../helpers/toasts.helper.ts';
import {
  changePasswordApiService,
  IChangePasswordPayload
} from '../../../../services/change-password/change-password-api.service.ts';
import classNames from 'classnames';

const initialFormFirst = {
  password: "",
};

const initialFormSecond = {
  password: "",
  repeatPassword: "",
};

export default function AppChangePasswordPage() {
  const $navigate = useNavigate();
  const [formFirstState, setFirstFormState] = useState<typeof initialFormFirst>(initialFormFirst);
  const [formSecondState, setSecondFormState] = useState<typeof initialFormSecond>(initialFormSecond);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [changePasswordStep, setChangePasswordStep] = useState<'first' | 'second'>('first');
  const [hiddenPasswords, setHiddenPasswords] = useState(true);
  
  const handleCheckPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(isFormPending) return;
    
    for(const validation of [validate("password", formFirstState.password)]) {
      if(typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }
    setIsFormPending(true);
    changePasswordApiService.validatePassword(formFirstState.password)
      .then(() => {
        setHiddenPasswords(true);
        setChangePasswordStep('second');
      })
      .finally(() => setIsFormPending(false))
  }
  
  const handleChangePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(isFormPending) return;
    
    for (const validation of [
      validate("password", formSecondState.password, 'New password'),
      validate("password", formSecondState.repeatPassword, 'Confirm new password'),
    ]) {
      if(typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }
    
    if (formSecondState.password !== formSecondState.repeatPassword) {
      showDangerToast("Passwords don't match");
      return;
    }
    
    setIsFormPending(true);
    const payload: IChangePasswordPayload = {
      oldPassword: formFirstState.password,
      newPassword: formSecondState.password,
    };
    changePasswordApiService.changePassword(payload)
      .then(() => {
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Success`,
          contents: `Password changed`
        });
        $navigate(`/app/profile`);
      })
     .finally(() => setIsFormPending(false));
  }

  /* DOM */
  return (
    <div className="tw-flex tw-flex-col tw-h-full">
      <div className={classNames(styles.PageForm, 'tw-h-full tw-gap-12')}>
        <div className="page-form-segment-heading">
          Change password
        </div>

        <div className="tw-w-full tw-h-full">
          {changePasswordStep === 'first' &&
            <form
              className="tw-flex tw-flex-col tw-h-full"
              onSubmit={handleCheckPassword}
            >
              <div className="tw-flex-1">
                <TextInput
                  type={hiddenPasswords ? 'password' : 'text'}
                  placeholder="Current password"
                  value={formFirstState.password}
                  onChange={(value) => setFirstFormState({ password: value })}
                  isBigger
                  isReadOnly={isFormPending}
                  floatingPlaceholder={false}
                />
                <Button
                  type="button"
                  additional={{ isZeroed: true }}
                  className="tw-w-max tw-h-max tw-p-0 tw-underline tw-mt-5 tw-text-sm"
                  onClick={() => setHiddenPasswords(prevState => !prevState)}
                >
                  {hiddenPasswords ? 'Show password' : 'Hide password' }
                </Button>
              </div>
              
              <div className="tw-flex tw-justify-end">
                <Button additional={{isZeroed: true}} disabled={isFormPending} className="tw-text-secondary">
                  <span>Submit</span>
                  {!isFormPending ? (
                    <span className="btn-icon tw-bg-softGreen">
                      <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                    </span>
                  ) : (
                    <Loader spinsPerSecond={3} />
                  )}
                </Button>
              </div>
            </form>
          }
          
          {changePasswordStep === 'second' &&
            <form
              className="tw-flex tw-flex-col tw-h-full"
              onSubmit={handleChangePassword}
            >
              <p className="tw-font-semibold">
                You have entered the correct password. You can set a new password
              </p>
              <div className="tw-flex-1 tw-mt-8.5">
                <TextInput
                  type={hiddenPasswords ? 'password' : 'text'}
                  placeholder="New password"
                  value={formSecondState.password}
                  onChange={(value) => setSecondFormState({ ...formSecondState, password: value })}
                  isBigger
                  isReadOnly={isFormPending}
                  floatingPlaceholder={false}
                />
                <TextInput
                  containerClassName="tw-mt-10"
                  placeholder="Confirm new password"
                  type={hiddenPasswords ? 'password' : 'text'}
                  value={formSecondState.repeatPassword}
                  onChange={(value) => setSecondFormState({ ...formSecondState, repeatPassword: value })}
                  isBigger
                  isReadOnly={isFormPending}
                  floatingPlaceholder={false}
                />
                <Button
                  type="button"
                  additional={{ isZeroed: true }}
                  className="tw-w-max tw-h-max tw-p-0 tw-underline tw-mt-5 tw-text-sm"
                  onClick={() => setHiddenPasswords(prevState => !prevState)}
                >
                  {hiddenPasswords ? 'Show passwords' : 'Hide passwords' }
                </Button>
              </div>
              <div className="tw-flex tw-justify-end">
                <Button additional={{ isZeroed: true }} disabled={isFormPending} className="tw-text-secondary">
                  <span>Submit</span>
                  {!isFormPending ? (
                    <span className="btn-icon tw-bg-softGreen">
                      <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                    </span>
                    ) : (
                      <Loader spinsPerSecond={3}/>
                    )}
                  </Button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  );
}
