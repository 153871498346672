import { FC } from 'react';
import Icon from '../../../../../components/Icon';

interface CheckmarkStatusProps {
  value: boolean;
}

export const CheckmarkStatus: FC<CheckmarkStatusProps> = ({ value }) => {
  return (
    <span className="tw-flex tw-items-center tw-gap-2.5">
      <Icon icon={value ? 'custom-checked' : 'custom-rejected'} className="tw-size-5" />
      {value ? 'Yes' : 'No'}
    </span>
  );
};