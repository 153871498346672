import classNames from 'classnames';
import styles from './CardTransactionsPage.module.css';
import { Table } from './../../../../components/Table';
import { TBody } from './../../../../components/Table/TBody';
import { TRow } from './../../../../components/Table/TRow';
import { THead } from './../../../../components/Table/THead';
import { TCell } from './../../../../components/Table/TCell';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker, { DatePickerStateModel } from './../../../../components/DatePicker';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createCardTransactionsStore } from './../MyCards/store/CardTransactions.store.ts';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ICardTransactionDto } from '../../../../services/card-api/interfaces';
import {
  getOperationTypeText,
  transactionExecutionTextByStatusMap
} from '../../../../helpers/transaction.helper.ts';
import { convertCentsToCurrency } from '../../../../helpers/convert-cents-to-currency.helper.ts';
import formatBalance from '../../../../helpers/formatBalance.ts';
import Loader from '../../../../components/Loader';

function DataRow(props: { data: ICardTransactionDto }) {
  const { time, type, amount, status, currency, localAmount, localCurrency } = props.data;

  return (
    <>
      <TRow>
        <TCell className="tw-font-semibold">{format(new Date(time), 'yyyy/MM/dd hh:mm')}</TCell>
        <TCell className="tw-font-semibold">{getOperationTypeText(type)}</TCell>
        <TCell className="tw-font-semibold">
          {formatBalance(convertCentsToCurrency(localAmount))} {localCurrency}
          {currency !== localCurrency && (
            <div className={styles.TransactionCurrency}>
              {formatBalance(convertCentsToCurrency(amount))} {currency}
            </div>
          )}
        </TCell>
        <TCell className={classNames(styles.transactionStatus, 'Transaction_' + status, 'tw-font-semibold')}>
          {transactionExecutionTextByStatusMap[status]}
        </TCell>
      </TRow>
    </>
  );
}

export const CardTransactionsPage: FC = observer(() => {
  const { cardId = '' } = useParams<{ cardId: string }>();
  const store = useLocalObservable(() => {
    const store = createCardTransactionsStore();
    store.setFilters({
      fromDate: startOfDay(subDays(new Date(), 30)).toISOString(), // Початок 30-денного періоду
      toDate: endOfDay(new Date()).toISOString() // Поточний день
    });
    return store;
  });

  useEffect(() => {
    // Load transactions based on filters
    store.loadCardTransactions(cardId, store.filters);
  }, [cardId, store.filters]);

  useEffect(() => {
    // Check if the period should be extended to 90 days if there is no data for 30 days
    if (!store.loading && store.transactions.length === 0 && store.filters.fromDate === startOfDay(subDays(new Date(), 30)).toISOString()) {
      store.setFilters({
        fromDate: startOfDay(subDays(new Date(), 90)).toISOString(), // Start of 90-day period
        toDate: endOfDay(new Date()).toISOString() // Current day
      });
    }
  }, [store.loading, store.transactions.length]);

  const handleChangeDateFilter = useCallback((range: DatePickerStateModel) => {
    const fromDate = startOfDay(range.startDate).toISOString();
    const toDate = endOfDay(range.endDate as Date).toISOString();
    if (store.filters.fromDate !== fromDate || store.filters.toDate !== toDate) {
      store.setFilters({ fromDate, toDate });
    }
  }, []);

  return (
    <div className="regular-page">
      <div className={styles.Transactions}>
        <h3>Card Transaction History</h3>
        <div className={styles.transactionsContainer}>
          <div className={styles.DateRangeField}>
            <label className={styles.DateRangeLabel}>Date range</label>
            <DatePicker
              hasIcon
              startDate={new Date(store.filters.fromDate as string)}
              endDate={new Date(store.filters.toDate as string)}
              containerClassName={styles.DateRange}
              onClose={handleChangeDateFilter}
            ></DatePicker>
          </div>
          <Table className={styles.transactionsTable}>
            <TBody>
              <TRow>
                <THead>Initiation date</THead>
                <THead>Operation</THead>
                <THead>Transaction amount</THead>
                <THead classname={styles.transactionStatus}>Status</THead>
              </TRow>
              {!store.loading && !!store.transactions.length && store.transactions
                .map((data, index) => <DataRow key={data.id ?? index} data={data}/>)}
              {store.loading &&
                  <TRow>
                      <TCell colSpan={4}>
                          <div className="tw-flex-centering">
                              <Loader/>
                          </div>
                      </TCell>
                  </TRow>
              }
              {!store.loading && !store.transactions.length &&
                  <TRow>
                      <TCell colSpan={4}>
                          <div className="tw-flex-centering tw-font-semibold">No data</div>
                      </TCell>
                  </TRow>
              }
            </TBody>
          </Table>
        </div>
      </div>
    </div>
  );
});
