import apiClient from "../../helpers/apiClient.ts";
import validate from "../../helpers/validate.ts";
import {BaseUserModel, UserType} from "./index.ts";

export default async function createUser(
  opt: {
    email: BaseUserModel["email"],
    phoneNumber: BaseUserModel["phoneNumber"],
    password: string,
    firstName: string,
    lastName: string,
  },
  smsToken: string
): Promise<void> {

  /* Validate client data */
  for(const validation of [
    validate("phoneNumber", opt.phoneNumber),
    validate("email", opt.email),
    validate("password", opt.password),
    validate("name", opt.firstName, 'First name'),
    validate("name", opt.lastName, 'Last name'),
  ]) {
    if(typeof validation === "string") {
      throw new Error(validation);
    }
  }

  /* Send API request */
  await apiClient.post("/users/create", {
    ...opt,
    smsToken
  });
}
