export enum CardStatus {
  KYCPending = 'KYC_PENDING',
  KYCFailed = 'KYC_FAILED',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  ACTIVE = 'ACTIVE'
}

export enum CardTypeVisaVirtual {
  VirtualVisaSilver = 'VIRTUAL VISA SILVER',
  VirtualVisaGold = 'VIRTUAL VISA GOLD',
  VirtualVisaPlatinum = 'VIRTUAL VISA PLATINUM'
}

export enum CardTypeVisaPhysical {
  PhysicalVisa = 'PHYSICAL VISA'
}

export enum CardTypeApplePay {
  VirtualApplePay = 'VIRTUAL APPLEPAY'
}

export enum CardTypeMastercardVirtual {
  VirtualMastercardSuperTransfer = 'VIRTUAL MASTERCARD SUPER TRANSFER',
  VirtualMastercardGlobalTransfer = 'VIRTUAL MASTERCARD GLOBAL TRANSFER',
  VirtualMastercardSilver = 'VIRTUAL MASTERCARD SILVER',
  VirtualMastercardGold = 'VIRTUAL MASTERCARD GOLD',
  VirtualMastercardPlatinum = 'VIRTUAL MASTERCARD PLATINUM'
}
export enum CardTypeMastercardPhysical {
  PhysicalMastercard = 'PHYSICAL MASTERCARD'
}

export enum CardTypeUnionpay {
  VirtualUnionpay = 'VIRTUAL UNIONPAY'
}

export type CardType =
  | CardTypeVisaVirtual
  | CardTypeVisaPhysical
  | CardTypeMastercardVirtual
  | CardTypeMastercardPhysical
  | CardTypeUnionpay
  | CardTypeApplePay;

export interface ICardDto {
  id: string;
  status: CardStatus;
  cardType: CardType;
  freezed?: boolean;
  number?: string;
  pinNotSet?: boolean;
}
