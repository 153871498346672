import {
  CardType, CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  CardTypeVisaVirtual
} from '../services/card-api/interfaces/card.interface.ts';

export enum CardBrand {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  Unionpay = 'Unionpay'
}

const logoByCardBrand = {
  [CardBrand.Visa]: '/images/visa-icon.svg',
  [CardBrand.MasterCard]: '/images/mastercard-icon.svg',
  [CardBrand.Unionpay]: '/images/unionpay-icon.svg',
}

export const logoCardClassMap = {
  [CardTypeMastercardPhysical.PhysicalMastercard]: 'PhysicalMastercard',
  [CardTypeVisaPhysical.PhysicalVisa]: 'PhysicalVisa',
  [CardTypeUnionpay.VirtualUnionpay]: 'VirtualUnionpay',
  [CardTypeApplePay.VirtualApplePay]: 'VisaVirtualApplePay',
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: 'VirtualMastercard',
}

const visaCardBrand: Partial<CardType>[] = [
  ...Object.values(CardTypeVisaVirtual),
  ...Object.values(CardTypeVisaPhysical)
];

const mastercardCardBrand: Partial<CardType>[] = [
  ...Object.values(CardTypeMastercardVirtual),
  ...Object.values(CardTypeMastercardPhysical)
];

export const isVisaCard = (cardType?: CardType): boolean => {
  return !!cardType && visaCardBrand.includes(cardType);
};

export const isMastercard = (cardType?: CardType): boolean => {
  return !!cardType && mastercardCardBrand.includes(cardType);
};

export const isUnionpayCard = (cardType?: CardType): boolean => {
  return CardTypeUnionpay.VirtualUnionpay === cardType;
};

export const getCardBrand = (cardType?: CardType) => {
  if (isMastercard(cardType)) return CardBrand.MasterCard;
  if (isVisaCard(cardType)) return CardBrand.Visa;
  if (isUnionpayCard(cardType)) return CardBrand.Unionpay;
  return null;
};

export const getCardBrandLogo = (cardType?: CardType) => {
  const cardBrand = getCardBrand(cardType);
  return (cardBrand && logoByCardBrand[cardBrand]) ?? null;
};
