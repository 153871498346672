import classNames from 'classnames';
import { CSSProperties } from 'react';
import Icon from '../Icon';
import styles from './index.module.css';
import { showDangerToast, showSuccessToast } from './../../helpers/toasts.helper.ts';

interface Props {
  value: string | number;
  className?: string;
  isDuctile?: boolean;
  variant?: 'collapsed-id';
  label?: string;
  style?: CSSProperties;
}

function CopyValue(props: Props) {
  // copy value handler
  async function copyValueHandler() {
    try {
      await navigator.clipboard.writeText(props.value.toString());
      showSuccessToast('Was successfully copied!');
    } catch {
      showDangerToast('Failed to copy!');
    }
  }

  const _collapseId = (id: string | number) => {
    const text = id.toString();

    if (text === '' || text.length <= 6) return;

    return text.slice(0, 3) + '...' + text.slice(text.length - 3, text.length);
  };

  return (
    <div
      style={props.style}
      className={classNames(props.className, styles.copyValue, {
        isDuctile: props.isDuctile
      })}>
      <div className={styles.copyValueIcon} onClick={copyValueHandler}>
        <Icon icon="custom-copy-1" />
      </div>
      {props.label?.length && <div className={styles.copyValueContent}>{props.label}</div>}
    </div>
  );
}

export default CopyValue;
