import classNames from "classnames";
import {ReactNode} from "react";
import Button from "../Button";
import Icon from "../Icon";
import styles from "./index.module.css";

export default function ModalWindow(props: {
  title: ReactNode,
  children: ReactNode | ReactNode[],
  isActive: boolean,
  onClose(): void
}) {

  /* DOM */
  return (
    <div className={classNames(styles.ModalWindow, {isHidden: !props.isActive})}>

      <div className={styles.windowContainer}>

        {/* Header */}
        <div className={styles.windowHeader}>

          <div className={styles.windowTitle}>
            {props.title}
          </div>

          <Button
            additional={{isZeroed: true}}
            style={{marginLeft: "auto", height: "auto"}}
            onClick={() => props.onClose()}
          >
            <Icon icon="x-mark-lined" />
          </Button>
        </div>

        {/* Contents */}
        <div className={styles.windowContents}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
