import { FormEvent, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import apiClient from "../../../../helpers/apiClient.ts";
import { getRandomNumber } from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./SignUpSmsCodeAction.module.scss";

import mainStore from "../../../../store/main.ts";
import classNames from 'classnames';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';

/* Initial form state */
const initialFormState = {
  smsCode: ""
};

/* Component */
export default function SignUpSmsCodeAction() {

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();
  const $navigate = useNavigate();
  const $breakpoint = useBreakpoint();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [rerender, setRerender] = useState(0);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  /* Vars */
  const phoneNumber = $searchParams.get("phoneNumber") as string;
  if(!phoneNumber) {
    return (<Navigate to="./?action=signUpPhone" />);
  }
  const smsCooldownSeconds = mainStore.smsConfirmationCooldown?.diffNow("seconds").seconds ?? 0;

  /* Rerender component every second */
  useEffect(() => {
    const smsCooldownSeconds = mainStore.smsConfirmationCooldown?.diffNow("seconds").seconds ?? 0;

    const interval = setInterval(() => {
      setRerender(Date.now());
    }, 1000);

    if(smsCooldownSeconds <= 0) clearInterval(interval);
  }, [mainStore.smsConfirmationCooldown]);

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("phoneNumber", phoneNumber),
        (/^\d{6}$/).test(formState.smsCode) || "Invalid SMS verification code"
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post<{token: string}>("/smsConfirmations/verify", {
        phoneNumber: phoneNumber,
        code: formState.smsCode
      });
    })
    .then(({data: {token}}) => {

      /* On Done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Thank you for joining VPlus!`,
        contents: `Now you can create your account`
      });

      $navigate(`./?action=signUpFinish&phoneNumber=${encodeURIComponent(phoneNumber)}&smsToken=${token}`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(styles.SignUpSmsCode, 'tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')}>
        <div className="xl:tw-text-3.5xl">Create your account</div>
        <div className="tw-text-sm xl:tw-text-base tw-font-semibold tw-mt-3 xl:tw-mt-4.5">
          You can request the code after {smsCooldownSeconds > 0 ? smsCooldownSeconds.toFixed(0) : 0} seconds
        </div>
        
        <form onSubmit={onFormSubmit} className={styles.form}>
          <TextInput
            placeholder={`Enter your 6-digit code from SMS (${phoneNumber})`}
            value={formState.smsCode}
            onChange={(value) => setFormState({ ...formState, smsCode: value })}
            isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
            isReadOnly={isFormPending}
          />
          
          <div className="tw-flex tw-justify-between tw-gap-4 tw-mt-9 xl:tw-mt-10">
            <Button
              className="tw-underline tw-h-auto tw-text-sm xl:tw-text-base"
              additional={{ isZeroed: true }}
              disabled={(smsCooldownSeconds > 0) || isFormPending}
              onClick={() => $navigate("./?action=signUpPhone")}
            >
              Change number
            </Button>
            <Button additional={{ isZeroed: true }} disabled={isFormPending}>
              <span>Next step</span>
              
              {!isFormPending ? (
                <img src="/icons/arrow-right-background.svg" className="tw-size-8.75" alt=""/>
              ) : (
                <Loader spinsPerSecond={3}/>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
