import classNames from "classnames";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import config from "../../config.ts";
import FileModel from "../../controllers/files";
import apiClient from "../../helpers/apiClient.ts";
import onError from "../../helpers/onError.ts";
import Icon from "../Icon";
import styles from "./index.module.css";

export default function FileUploadBlock(props: {
  title: ReactNode,
  file?: FileModel,
  icon: string,
  onUpload(file: FileModel): void,

  acceptFiles?: string,
}) {

  /* Refs */
  const fileUploadRef = useRef<HTMLInputElement>(null);

  /* State */
  const [file, setFile] = useState<FileModel | null>(null);

  /* Update file */
  useEffect(() => {
    setFile(props.file ?? null)
  }, [props.file]);

  /* Upload file function */
  function uploadFile(file: File | undefined) {
    if(!file) return

    const formData = new FormData();
    formData.append('file', file, file.name)

    /* Send API request */
    apiClient.post<FileModel>('/files/upload', formData)
    .then(({data: file}) => {
      props.onUpload(file)
      console.log(file)
    })
    .catch(onError)
  }

  /* DOM */
  return (
    <div
      className={classNames("white-block", styles.FileUploadBlock, {isActive: !!file})}
      onClick={() => {

        if(!props.file) {

          fileUploadRef.current?.click()

          return
        }
        else {
          window.open(`${config.uploadedFilesUrl}/${props.file.displayName}`);

          return
        }
      }}
    >

      <div className="flex flex-aic">

        <div className={styles.twoIcons}>
          <Icon icon="custom-green-folder-1" />
          <Icon icon={props.icon} />
        </div>

        {props.file ? (
          <div className={styles.actionText}>
            Download
          </div>
        ) : (
          <div className={styles.actionText}>
            Upload
          </div>
        )}
      </div>

      <div className={classNames(styles.title, {isSmall: typeof props.title === 'string' && props.title.length > 30})}>
        {props.title}
      </div>

      {/* Input type file */}
      <input
        ref={fileUploadRef}
        type="file"
        accept={props.acceptFiles}
        onChange={({target: {files}}) => uploadFile(files?.[0])}
      />
    </div>
  );
}
