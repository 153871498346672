import classNames from "classnames";
import {Navigate, useSearchParams} from "react-router-dom";
import styles from "./EmailSentAction.module.scss";

export default function EmailSentAction() {

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const email = $searchParams.get("email") as string;
  if(!email) {
    return (<Navigate to="./?action=signUpPhone" />);
  }

  return (
    <div className="tw-flex tw-flex-col xl:tw-flex-row tw-gap-5 xl:tw-gap-5.5">
      <div className={
        classNames(
          styles.EmailSentBlock,
          'tw-flex tw-items-center tw-min-w-0 tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')
      }>
        <div className="tw-text-xl xl:tw-text-3.5xl tw-truncate">
          Check your email
          <br />
          <span>{email}</span>
          <br />
          for confirmation
        </div>
      </div>

      <div className={classNames(styles.EmailSentSeeBg, 'tw-bg-softGreen tw-rounded-xl xl:tw-rounded-2xl')} />
    </div>
  );
}
