import styles from "./index.module.css";

export default function Loader(props: {
  size?: number,
  width?: number,
  code?: string,
  spinsPerSecond?: number,
  color?: string
}) {

  /* Vars */
  const size = props.size ?? 24;
  const width = props.width ?? 3;
  const spinsPerSecond = props.spinsPerSecond ?? 1;
  const color = props.color ?? "#000";

  /* DOM */
  return (
    <div
      className={styles.Loader}
      style={{
        width: size,
        height: size,
        borderWidth: width,
        animationDuration: `${1 / spinsPerSecond}s`,
        borderColor: color
      }}
    />
  );
}
