import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormState } from './../../../../hooks/useFormState';
import { KYCMCPhysical } from './../../Cards/CardKYC/components/KYCMCPhysical/KYCMCPhysical.tsx';
import { KYCUnionPay } from './../../Cards/CardKYC/components/KYCUnionPay/KYCUnionPay.tsx';
import { KYCVisaPhysical } from './../../Cards/CardKYC/components/KYCVisaPhysical/KYCVisaPhysical.tsx';
import { fetch } from './../../../../utils/fetch.ts';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeUnionpay,
  CardTypeVisaPhysical
} from '../../../../services/card-api/interfaces';
import styles from './KYCForm.module.css';
import Button2 from '../../../../components/Button2';
import classNames from 'classnames';
import { cardKycApiService } from '../../../../services/card-kyc-api';
import { isPhysicalCard } from '../../../../helpers/card-kind.helper.ts';
import { limitDocumentSizeMb } from './consts/consts.ts';

const KYCForm: FC = () => {
  const childRef = useRef<any>();
  const { cardType } = useParams();
  const [isSendingForm, setIsSendingForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const [countStep, setCountStep] = useState(1);
  const [formState, setFormState] = useFormState({
    firstNameEn: '',
    firstName: '',
    lastNameEn: '',
    lastName: '',
    countryCode: '',
    phone: '',
    email: '',
    cardType: '',
    address: {
      country: '',
      city: '',
      postalCode: '',
      line1: '',
      line2: '',
      line3: ''
    },
    annualIncome: '',
    occupation: 'Businessmen',
    position: 'senior management',
    identificationDocument: {
      number: '',
      issuanceDate: '',
      expiryDate: '',
      birthday: '',
      country: '',
      type: 'PASSPORT',
      image: '',
      birthCountry: '',
      residenceCountry: ''
    },
    signImage: '',
    addressImage: '',
    cardBin: ''
  });

  useEffect(() => {
    setFormState(cardType as CardType, 'cardType');
    switch (cardType as CardType) {
      case CardTypeMastercardPhysical.PhysicalMastercard:
        setCountStep(3);
        break;
      case CardTypeUnionpay.VirtualUnionpay:
        setCountStep(3);
        break;
      case CardTypeVisaPhysical.PhysicalVisa:
        setCountStep(2);
        break;
      case CardTypeApplePay.VirtualApplePay:
        setCountStep(2);
        break;
    }
  }, [cardType]);
  
  const [step, setStep] = useState(1);
  
  const handleClickForward = () => {
    if (childRef.current) childRef.current?.nextStepClick();
  };

  const handleNextStepClick = () => {
    if (step === countStep) {
      if (!isSendingForm) submitForm();
    } else {
      setStep((prevStep) => Math.min(prevStep + 1, countStep));
    }
  };

  const handlePrevStepClick = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  }, []);

  const submitForm = async () => {
    setIsSendingForm(true);
    try {
      const { cardId } = await cardKycApiService.sendCardKYC(formState as any);
      const sharedPath = `/app/cards/${cardId}`;
      navigate( isPhysicalCard(cardType as CardType)
        ? `${sharedPath}/payment-for-physical-card`
        : `${sharedPath}/payment-for-virtual-card`
      );
    } finally {
      setIsSendingForm(false);
    }
  };

  return (
    <div className={classNames(styles.KYCIndividualPage, 'regular-page')}>
      <div className={styles.blockHeader}>
        <h3>Questionnaire for opening a debit card</h3>
      </div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBarBlock}>
          <div className={styles.progressBarHead}>
            <Button2
              className={classNames(styles.stepBtn, 'BorderNone disabled:tw-opacity-100')}
              disabled={step === 1}
              flipIcon
              icon="custom-arrow-1"
              isDuctile
              text="Back"
              onClick={handlePrevStepClick}
            />
            <Button2
              className={classNames(styles.stepBtn, 'BorderNone disabled:tw-opacity-100')}
              isDuctile
              disabled={step >= countStep}
              text="Forward"
              icon="custom-arrow-1"
              onClick={handleClickForward}
            />
          </div>
          <div className={styles.progressBar}>
            <span>Process of filling out the questionnaire</span>
            <div className={styles.progressBarProgress}>
              <div style={{ width: `${(100 / countStep) * step}%` }}></div>
            </div>
          </div>
        </div>
        {
          step === 1 &&
            <div className="tw-flex tw-gap-4.5 tw-bg-secondary-75 tw-rounded-1.5xl tw-p-5 tw-mt-4">
              <img
                src="/images/information-icon-with-red-container.svg"
                alt="Alert icon"
                className="tw-w-7.5 tw-h-6.5"
              />
              <span className={classNames(styles['AlertMessage-Text'], 'tw-text-sm tw-mt-1.5')}>
                Please be aware that uploaded files should not exceed {limitDocumentSizeMb} MB.
              </span>
          </div>
        }
      </div>

      {cardType === CardTypeMastercardPhysical.PhysicalMastercard && (
        <KYCMCPhysical
          ref={childRef}
          step={step}
          formState={formState}
          setFormState={setFormState}
          onNextStepClick={handleNextStepClick}></KYCMCPhysical>
      )}
      {cardType === CardTypeVisaPhysical.PhysicalVisa && (
        <KYCVisaPhysical
          ref={childRef}
          step={step}
          formState={formState}
          setFormState={setFormState}
          onNextStepClick={handleNextStepClick}></KYCVisaPhysical>
      )}
      {cardType === CardTypeUnionpay.VirtualUnionpay && (
        <KYCUnionPay
          ref={childRef}
          step={step}
          formState={formState}
          setFormState={setFormState}
          onNextStepClick={handleNextStepClick}></KYCUnionPay>
      )}
    </div>
  );
};

export default KYCForm;
