import {FormEvent, useState} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import apiClient from "../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./ChangePasswordAction.module.scss";

import mainStore from "../../../../store/main.ts";
import classNames from 'classnames';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';

/* Initial form state */
const initialFormState = {
  password1: "",
  password2: ""
};

/* Component */
export default function ChangePasswordAction() {

  const $navigate = useNavigate();
  const $breakpoint = useBreakpoint();
  const [$searchParams, $setSearchParams] = useSearchParams();

  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  const emailToken = $searchParams.get("emailToken") as string;
  if(!emailToken) {
    return (<Navigate to="./" />);
  }

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("password", formState.password1),
        validate("password", formState.password2)
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Compare passwords */
      if(formState.password1 !== formState.password2) {
        throw new Error("Passwords don't match");
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post("/users/resetPassword", {
        emailToken,
        password: formState.password2
      });
    })
    .then(() => {

      /* On Done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `Log in to your account with new password`
      });

      $navigate(`./`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <div className={styles.ChangePasswordAction}>

      <div className={classNames(styles.ChangePassword, 'tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')}>
        <div className="xl:tw-text-3.5xl">Change password</div>
        
        <form onSubmit={onFormSubmit} className="tw-mt-6.5 xl:tw-mt-5">
          <TextInput
            type="password"
            placeholder="New password"
            value={formState.password1}
            onChange={(value) => setFormState({...formState, password1: value})}
            isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
            showPasswordButton
            isReadOnly={isFormPending}
          />
          <div className="tw-pt-6.5 xl:tw-pt-4">
            <TextInput
              type="password"
              placeholder="Confirm new password"
              value={formState.password2}
              onChange={(value) => setFormState({...formState, password2: value})}
              isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
              showPasswordButton
              isReadOnly={isFormPending}
            />
          </div>

          <Button additional={{isZeroed: true}} disabled={isFormPending} className="tw-mt-8 xl:tw-mt-11 tw-ml-auto">
            <span>Change</span>

            {!isFormPending ? (
              <img src="/icons/arrow-right-background.svg" alt="" />
            ) : (
              <Loader spinsPerSecond={3} />
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}
