import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './index.module.css';

interface TRowProps {
  classname?: string;
  children?: ReactNode;
}

export const TRow: FC<TRowProps> = (props) => {
  return (
    <tr className={classNames(props.classname, styles.tRow)}>
      {props.children}
    </tr>
  );
};
