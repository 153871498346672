import styles from "./index.module.css";

export default function LatestTransactionsBlock() {

  /* DOM */
  return (
    <div className={styles.LatestTransactionsBlock}>
      <div className="white-block">
        <div className="big-heading">
          Latest transactions
          <br />
          for several days
        </div>

        <div style={{height: 70}} />
        <div className={styles.bigGrayHeading}>
          You are not making
          <br />
          any transactions yet
        </div>
      </div>
    </div>
  );
}
