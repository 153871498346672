import { Slide, toast, ToastOptions } from 'react-toastify';

const defaultToastOptions:  (ToastOptions<unknown> | undefined) = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  theme: "colored",
  transition: Slide,
}

export const showDangerToast = (message: string, options?: ToastOptions<unknown> | undefined ) => {
  toast.error(message, {...defaultToastOptions, ...options})
}

export const showSuccessToast = (message: string, options?: ToastOptions<unknown> | undefined ) => {
  toast.success(message, {...defaultToastOptions, ...options});
}