import React, { FormEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Icon from './../../../../../../../../components/Icon';
import TextInput from './../../../../../../../../components/TextInput';
import styles from './KYCStepOne.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { phoneCodeOptions } from '../../../../../../../../consts/phone-code-options.ts';
import { documentTypeOptions } from './../../../../consts/document-type-options.ts';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import { sexOptions } from './../../../../consts/sex-options.ts';
import Button2 from '../../../../../../../../components/Button2';
import Select2, { SelectOptionModel } from '../../../../../../../../components/Select2';
import validate from '../../../../../../../../helpers/validate.ts';
import { cardKYCCountriesStore } from '../../../../../../../../store/card-kyc-countries.store.ts';

type DocumentFieldKey = 'identificationDocument.image'
  | 'identificationDocument.faceImage'
  | 'identificationDocument.backImage'
  | 'signImage'
  | 'addressImage';

const documentTitleMap: Record<DocumentFieldKey, string> = {
  'identificationDocument.image': 'Passport',
  'identificationDocument.faceImage': 'ID Front side',
  'identificationDocument.backImage': 'ID Back side',
  signImage: 'Sign',
  addressImage: 'Address Image',
};


// @ts-ignore
const KYCStepOne = observer(forwardRef<any, any>(({ onNextStepClick, formState, setFormState }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [documentsForLoadMap, setDocumentsForLoadMap] = useState<Record<DocumentFieldKey, boolean>>({
    'identificationDocument.image': true,
    'identificationDocument.faceImage': false,
    'identificationDocument.backImage': false,
    signImage: true,
    addressImage: true,
  });
  const [countryOptions, setCountryOptions] = useState<SelectOptionModel<string>[]>([]);
  
  const checkFillFieldDocument = useCallback((fieldName: DocumentFieldKey) => {
    switch (fieldName) {
      case 'identificationDocument.backImage':
        return !!formState.identificationDocument.backImage;
      case 'identificationDocument.image':
        return !!formState.identificationDocument.image;
      case 'identificationDocument.faceImage':
        return !!formState.identificationDocument.faceImage;
      case 'signImage':
        return !!formState.signImage;
      case 'addressImage':
        return !!formState.addressImage;
      default:
        return false;
    }
  }, [formState]);
  
  useEffect(() => {
    setCountryOptions(cardKYCCountriesStore.countries.map(({ id, name: label }) => ({ value: id, label })));
  }, [cardKYCCountriesStore.countries]);
  
  useEffect(() => {
    cardKYCCountriesStore.getCountries();
  }, []);
  
  useEffect(() => {
    const newDocumentsForLoadMap = { ...documentsForLoadMap };
    if (formState.identificationDocument.type === 'PASSPORT') {
      newDocumentsForLoadMap['identificationDocument.backImage'] = false;
      newDocumentsForLoadMap['identificationDocument.faceImage'] = false;
      newDocumentsForLoadMap['identificationDocument.image'] = true;
      setNestedFieldValue('identificationDocument.backImage', null);
      setNestedFieldValue('identificationDocument.faceImage', null);
    } else {
      newDocumentsForLoadMap['identificationDocument.backImage'] = true;
      newDocumentsForLoadMap['identificationDocument.faceImage'] = true;
      newDocumentsForLoadMap['identificationDocument.image'] = false;
      setNestedFieldValue('identificationDocument.image', null);
    }
    setDocumentsForLoadMap(newDocumentsForLoadMap);
  }, [formState.identificationDocument.type]);
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    
    if (
      formState.firstName !== formState.firstNameEn ||
      formState.lastName !== formState.lastNameEn
    ) {
      showDangerToast(
        'First Name and Last Name do not match the confirmation fields.'
      );
      return;
    }
  
    const checkIsValidEmail: true | string = validate('email', formState.email);
    
    if (typeof checkIsValidEmail === 'string') {
      showDangerToast(checkIsValidEmail);
      return;
    }
    
    if (!formState.countryCode) {
      showDangerToast('Please select country code');
      return;
    }
  
    const checkValidBirthday: true | string = validate(
      'dateOfBirth',
      new Date(formState.identificationDocument.birthday),
      'Date Of Birth',
    );
    if (typeof checkValidBirthday === 'string') {
      showDangerToast(checkValidBirthday);
      return;
    }
    
    if (!formState.identificationDocument.birthCountry) {
      showDangerToast('Please select birthday country');
      return;
    }
    
    if (!formState.identificationDocument.residenceCountry) {
      showDangerToast('Please select residence country');
      return;
    }
    
    if (!formState.identificationDocument.sex) {
      showDangerToast('Please select sex');
      return;
    }
  
    if (new Date(formState.identificationDocument.issuanceDate) > new Date(formState.identificationDocument.expiryDate)) {
      showDangerToast('Issuance Date date must be earlier than Expire date');
      return;
    }
    
    const checkIssuanceDate: true | string = validate(
      'pastDate',
      new Date(formState.identificationDocument.issuanceDate),
      'Issuance Date',
    );
    if (typeof checkIssuanceDate === 'string') {
      showDangerToast(checkIssuanceDate);
      return;
    }
    
    const checkExpireDate: true | string = validate(
      'futureDate',
      new Date(formState.identificationDocument.expiryDate),
      'Expire Date',
    );
    if (typeof checkExpireDate === 'string') {
      showDangerToast(checkExpireDate);
      return;
    }
    
    if (!formState.identificationDocument.country) {
      showDangerToast('Please select country');
      return;
    }
    
    const requiredDocuments: Partial<DocumentFieldKey>[] = ['signImage', 'addressImage'];
    if (formState.identificationDocument.type === 'PASSPORT') {
      requiredDocuments.push('identificationDocument.image');
    } else {
      requiredDocuments.push('identificationDocument.faceImage', 'identificationDocument.backImage')
    }
    
    if (requiredDocuments.some(d => !checkFillFieldDocument(d))) {
      showDangerToast('Please upload all documents.');
      return;
    }

    onNextStepClick();
  };

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState;
    if (keys.length === 1) {
      setFormState(value, key);
    } else {
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
        currentLevel = currentLevel[keys[i]];
      }
      currentLevel[keys[keys.length - 1]] = value;
      setFormState({ ...formState });
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64 = e.target?.result;
        if (base64) {
          setNestedFieldValue(fieldName, base64.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleFileDelete = (fieldName: string) => {
    setNestedFieldValue(`${fieldName}`, null);
    const input = document.getElementById(`${fieldName}Upload`) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  return (
    <div className={styles.kycStepOne}>
      <form className={styles.kycContainer} onSubmit={handleSubmit}>
        <div className={styles.leftColumn}>
          <h2>Personal Information:</h2>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.kycGrid}>
            <TextInput
              type="text"
              name="firstName"
              label="First Name"
              minLength={2}
              value={formState.firstName}
              onChange={setFormState}
              required
            />
            <TextInput
              type="text"
              name="lastName"
              label="Last Name"
              minLength={2}
              value={formState.lastName}
              onChange={setFormState}
              required
            />
          </div>
          <div className={styles.description}>You are required to duplicate your information in these fields.</div>
          <div className={styles.kycGrid}>
            <TextInput
              type="text"
              name="firstNameEn"
              label="First Name"
              minLength={2}
              value={formState.firstNameEn}
              onChange={setFormState}
              required
            />
            <TextInput
              type="text"
              name="lastNameEn"
              label="Last Name"
              minLength={2}
              value={formState.lastNameEn}
              onChange={setFormState}
              required
            />
            <TextInput
              type="email"
              name="email"
              label="Email"
              minLength={5}
              value={formState.email}
              onChange={setFormState}
              required
            />
            <div className={styles.phoneRow}>
              <Select2
                label="Country code"
                options={phoneCodeOptions}
                selected={phoneCodeOptions.find(
                  (opt) => opt.value === formState.countryCode
                )}
                onSelect={(option) =>
                  setNestedFieldValue('countryCode', option.value)
                }
                required
              />
              <TextInput
                type="text"
                name="phone"
                label="Phone Number"
                value={formState.phone}
                onChange={setFormState}
                required
              />
            </div>
            <TextInput
              type="date"
              name="identificationDocument.birthday"
              label="Date of Birth"
              value={formState.identificationDocument.birthday}
              onChange={setFormState}
              required
            />
            <Select2
              label="Birthday Country"
              options={countryOptions}
              selected={countryOptions.find(
                (opt) => opt.value === formState.identificationDocument.country
              )}
              onSelect={(option) =>
                setNestedFieldValue(
                  'identificationDocument.birthCountry',
                  option.value
                )
              }
              required
            />
            <Select2
              label="Residence Country"
              options={countryOptions}
              selected={countryOptions.find(
                (opt) => opt.value === formState.identificationDocument.country
              )}
              onSelect={(option) =>
                setNestedFieldValue(
                  'identificationDocument.residenceCountry',
                  option.value
                )
              }
              required
            />
            <Select2
              label="Sex"
              options={sexOptions}
              selected={sexOptions.find(
                (opt) => opt.value === formState.identificationDocument.sex
              )}
              onSelect={(option) =>
                setNestedFieldValue(
                  'identificationDocument.sex',
                  option.value
                )
              }
              required
            />
            <TextInput
              type="text"
              name="identificationDocument.number"
              label="Document Series and Number"
              minLength={3}
              value={formState.identificationDocument.number}
              onChange={setFormState}
              required
            />
            <TextInput
              type="date"
              name="identificationDocument.issuanceDate"
              label="Issuance Date"
              value={formState.identificationDocument.issuanceDate}
              onChange={setFormState}
              required
            />
            <TextInput
              type="date"
              name="identificationDocument.expiryDate"
              label="Expiry Date"
              value={formState.identificationDocument.expiryDate}
              onChange={setFormState}
              required
            />
            <Select2
              label="Country"
              options={countryOptions}
              selected={countryOptions.find(
                (opt) => opt.value === formState.identificationDocument.country
              )}
              onSelect={(option) =>
                setNestedFieldValue(
                  'identificationDocument.country',
                  option.value
                )
              }
              required
            />
            <Select2
              label="Type of Document"
              options={documentTypeOptions}
              selected={documentTypeOptions.find(
                (opt) => opt.value === formState.identificationDocument.type
              )}
              onSelect={(option) =>
                setNestedFieldValue('identificationDocument.type', option.value)
              }
              required
            />
          </div>
        </div>

        <div
          className={classNames(
            styles.leftColumn,
            styles.documentsTitleContainer
          )}>
          <h2>Documents:</h2>
        </div>
        <div className={classNames(styles.rightColumn, styles.documentsContainer)}>
          {Object.entries(documentsForLoadMap).filter(([_, value]) => value).map(([fieldName, _]) => (
            <button
              type="button"
              key={fieldName}
              className={classNames(styles.uploadBlock, 'tw-text-start', {
                [styles.uploadBlockLoadedFile]: checkFillFieldDocument(fieldName as DocumentFieldKey)}
              )}
              onClick={() => document.getElementById(`${fieldName}`)?.click()}
            >
              <Icon icon="custom-documents-upload" className={styles.icon} />
              <span className={styles.uploadText}>
                { checkFillFieldDocument(fieldName as DocumentFieldKey) ? 'Loaded' : 'Download' }
              </span>
              <p>{documentTitleMap[fieldName as DocumentFieldKey]}</p>
              <input
                name={`${fieldName}`}
                type="file"
                id={`${fieldName}`}
                accept="image/*"
                onChange={(e) => handleFileChange(e, fieldName)}
                style={{ display: 'none' }}
              />
            </button>
          ))}
        </div>

        <div className={styles.controls}>
          <Button2
            ref={nextButtonRef}
            className={classNames(styles.kycBtn, 'BorderNone')}
            text="Next Step"
            icon="custom-arrow-1"
            buttonProps={{ type: 'submit' }}
            isDuctile
          />
        </div>
      </form>
    </div>
  );
}));

export default KYCStepOne;
