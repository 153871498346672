import {FormEvent, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import BgIcon from "../../../../components/BgIcon";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import getMyAccounts, {MyAccountsObj} from "../../../../controllers/accounts/getList.ts";
import apiClient from "../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import {parseFloatFromString} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";

import styles from './index.module.css'

import mainStore from "../../../../store/main.ts";
import classNames from "classnames";
import Icon from "../../../../components/Icon/index.tsx";
import Select from "../../../../components/Select/index.tsx";
import AccountModel from "../../../../controllers/accounts/index.ts";
import {ExchangeGiveTransactionModel} from "../../../../controllers/transactions";

/* Component */
export default function ExchangeFundsPage() {
  if(!mainStore.user) return null;

  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const selectedAccountId = $searchParams.get('selectedAccountId')

  /* State */
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [toAccountId, setToAccountId] = useState<AccountModel['_id'] | null>(null);
  const [accounts, setAccounts] = useState<MyAccountsObj[]>([]);
  const [rates, setRates] = useState<{[key: string]: number} | null>(null)
  const [fromAmount, setFromAmount] = useState<string>('')
  const [toAmount, setToAmount] = useState<string>('')

  /* Load accounts & rates on mount */
  useEffect(() => {
    loadMyAccounts()
    .then(loadCurrencyRates)
  }, [selectedAccountId]);

  /* Vars */
  let fromAccount = accounts.find((account) => account._id === selectedAccountId);
  const toAccount = accounts.find((account) => account._id === toAccountId);

  if(!toAccount || !fromAccount || !rates) {

    return (
      <div className="loader-container">
        <Loader spinsPerSecond={3} />
      </div>
    );
  }

  /* Load currency rates function */
  function loadCurrencyRates() {

    if(!fromAccount) {

      if(!accounts.length) {
        loadMyAccounts()
        .then((accounts) => {

          if(Array.isArray(accounts)) {
            fromAccount = accounts.find((account) => account._id === selectedAccountId);
            loadCurrencyRates()
          }
        })
      }

      return
    }

    setRates(null)
    setFromAmount('')
    setToAmount('')

    return Promise.resolve()
    .then(() => {

      return apiClient.get<{[key: string]: number}>('/currencyRates/get', {
        params: {
          fromCurrency: fromAccount?.currency
        }
      })
    })
    .then((response) => {

      if(response?.data) setRates(response.data)
    })
    .catch(onError)
  }

  /* Load my accounts function */
  function loadMyAccounts() {
    return getMyAccounts()
    .then((accounts) => {

      setAccounts(accounts)

      setToAccountId(accounts.filter((account) => account._id !== selectedAccountId)[0]._id)

      return accounts
    })
    .catch(onError)
  }

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("clientFloat", +fromAmount, "From amount"),
        validate("clientFloat", +toAmount, "To amount"),
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post<ExchangeGiveTransactionModel>("/transactions/createExchange", {
        amount: +fromAmount * 100,
        fromAccountId: selectedAccountId,
        toAccountId: toAccountId
      });
    })
    .then(({data: transaction}) => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `You successfully sent exchange request`
      });

      /* Navigate to profile page */
      $navigate(`/app/transactions?selectedTransactionId=${transaction._id}`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* Vars */
  const fee = rates[`${fromAccount.currency}${toAccount.currency}`]

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} className="white-block" style={{padding: 30}}>
      <div className="big-heading">
        Exchange funds
      </div>

      <div style={{height: 20}} />

      <div>

        <Select
          options={accounts.filter((account) => account._id !== selectedAccountId).map((account) => ({
            value: account._id, element: account.currency
          }))}
          value={toAccount._id}
          onSelect={setToAccountId}
          placeholder="Select currency to buy"
        />

        <div style={{height: 30}} />

        <div className={styles.formWrapper}>

          <div className={classNames('white-block', styles.formSection)}>

            <div style={{width: 200}}>
              <TextInput
                isBigger={true}
                value={fromAmount}
                onChange={(value) => {

                  const amount = parseFloatFromString(value)

                  setFromAmount(amount.string)
                  setToAmount((amount.float * fee).toFixed(2))
                }}
                placeholder="Enter amount"
                isReadOnly={isFormPending}
              />
            </div>
            <div style={{height: 20}} />
            <div className={styles.formSectionCurrency}>
              {fromAccount.currency}
            </div>
          </div>

          <div className={styles.formWrapperArrow}>
            <Icon icon="arrow-right-alt-filled" />
          </div>

          <div className={classNames('white-block', styles.formSection)}>

            <div style={{width: 200}}>
              <TextInput
                isBigger={true}
                value={toAmount}
                onChange={(value) => {

                  const amount = parseFloatFromString(value)

                  setFromAmount((amount.float / fee).toFixed(2))
                  setToAmount(amount.string)
                }}
                placeholder="Enter amount"
                isReadOnly={isFormPending}
              />
            </div>
            <div style={{height: 20}} />
            <div className={styles.formSectionCurrency}>
              {toAccount.currency}
            </div>
          </div>
        </div>

        <div style={{height: 30}} />

        <div
          style={{
            textAlign: 'center',
            fontSize: '18pt',
            fontStyle: 'italic',
            fontWeight: 500
          }}
        >
          Enter amount in one of two blocks above
        </div>
      </div>

      <div style={{height: 30}} />

      <div className="flex flex-aic flex-jcsb">

        <div style={{fontWeight: 'bold'}}>
          1 {fromAccount.currency} = {fee} {toAccount.currency}
        </div>

        <Button additional={{isZeroed: true}} disabled={isFormPending}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon
              icon="check-mark-lined"
              background="var(--soft-green)"
            />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
