import { observer, useLocalObservable } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { actionButtons, ActionType, IActionButton } from './components/ActionButtons/action-buttons.ts';
import { CardStatus, ICardDto } from '../../../../services/card-api/interfaces/card.interface.ts';
import ActionButton from './components/ActionButtons/ActionButton.tsx';
import styles from './index.module.css';
import { Card } from './../../Cards/components/Card/Card.tsx';
import { CardsStore, createCardDetailStore, createCardsStore } from './store';
import { CardTransactions } from './../../Cards/components/CardTransactions/CardTransactions.tsx';
import { Modal } from './../../../../components/Modal';
import { CardPinDialogContent } from './../../Cards/components/CardPinDialogContent/CardPinDialogContent.tsx';
import { MyCardsSelector } from './../../Cards/MyCards/components/MyCardsSelector/MyCardsSelector.tsx';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import classNames from 'classnames';
import { isVirtualCard } from '../../../../helpers/card-kind.helper.ts';
import { ActivateCardOverlap } from '../components/ActivateCardOverlap/ActivateCardOverlap.tsx';
import { ICardDataActiveDto } from '../../../../services/card-api';
import { useQueryParams } from '../../../../hooks/useQueryParams.ts';

interface MyCardsPageProps {
  cardsStore?: CardsStore;
}

const MyCardsPage: FC<MyCardsPageProps> = observer(({ cardsStore: inputCardStore }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const cardsStore = inputCardStore ?? useLocalObservable(() => createCardsStore());
  const activeCardStore = useLocalObservable(() => createCardDetailStore());
  const [filteredActionButtons, setActionButtons] = useState([] as IActionButton[]);
  const [filteredCards, setFilteredCards] = useState<ICardDto[]>([]);
  const [isOpenCardDetailsDialog, setOpenCardDetailsDialog] = useState(false);
  const [isOpenChangePinDialog, setOpenChangePinDialog] = useState(false);
  
  useEffect(() => {
    cardsStore.loadCards();
  }, []);
  
  useEffect(() => {
    const filteredCards = cardsStore.cards.filter((card) => card.status === CardStatus.ACTIVE);
    if (!activeCardStore.card?.id && filteredCards.length) {
      const candidateCardId = searchParams.get('cardId') ?? '';
      const activeCard: ICardDto = [...filteredCards].find(({ id }) => id === candidateCardId) ?? filteredCards[0];
      activeCardStore.setCard(activeCard);
      updateSearchParams(activeCard.id)
    }
    setFilteredCards(filteredCards);
   
  }, [cardsStore.cards]);
  
  useEffect(() => {
    const activeCardId = activeCardStore.card?.id;
    if (activeCardId) activeCardStore.loadCardData(activeCardId);
  }, [activeCardStore.card?.id]);
  
  useEffect(() => {
    if (activeCardStore.card?.id) {
      cardsStore.updateCard(activeCardStore.card.id, { freezed: activeCardStore.card?.freezed })
    }
    setActionButtons(filterActionButtons(actionButtons, activeCardStore.cardData));
  }, [activeCardStore.cardData, activeCardStore.isLoadingLock, activeCardStore.loading]);
  
  const updateSearchParams = (cardId: string) => {
    const params = new URLSearchParams({...searchParams});
    params.set('cardId', cardId);
    setSearchParams(params, { replace: true });
  };
  
  const handleCardSelectorClick = (card: ICardDto) => {
    activeCardStore.setCard(card);
    updateSearchParams(card.id);
  }
  
  const handleChangedPin = () => {
    if (activeCardStore.card) {
      cardsStore.updateCard(activeCardStore.card.id, { pinNotSet: false });
      activeCardStore.updateCardData({ pinNotSet: false });
    }
  };
  
  const handleActionClick = useCallback((type: ActionType) => {
    if (activeCardStore.card) {
      switch (type) {
        case 'lock':
          activeCardStore.lockCard(activeCardStore.card.id);
          break;
        case 'unlock':
          activeCardStore.unlockCard(activeCardStore.card.id);
          break;
        case 'details':
          setOpenCardDetailsDialog(true);
          break;
        case "setPin":
          setOpenChangePinDialog(true);
          break;
        case "topUp":
          navigate(`${activeCardStore.card.id}/top-up`)
          break;
      }
    }
  }, []);
  
  const filterActionButtons = (buttons: IActionButton[], card: ICardDataActiveDto | null) => {
    if (!card) return [];
    const { cardType, freezed } = card;
    return buttons.filter((item) => {
      if (item.type === 'setPin' && isVirtualCard(cardType)) return false;
      if (item.type !== 'lock' && item.type !== 'unlock') return true;
      if (item.type === 'lock' && !freezed) return true;
      return item.type === 'unlock' && freezed;
    }).map((item: IActionButton) => {
      if (activeCardStore.isLoadingLock) return {...item, disabled: true };
      if (activeCardStore.cardData?.freezed && item.type !== 'unlock') return {...item, disabled: true };
      if (activeCardStore.loading || !activeCardStore.cardData) return {...item, disabled: true };
      if ((item.type === 'lock' || item.type === 'unlock') && activeCardStore.isLoadingLock) {
        return {...item, disabled: activeCardStore.isLoadingLock };
      }
      return {...item};
    });
  };
  
  return (
    <div className="regular-page">
      <section className={styles.content}>
        <div className={styles.cardColumn}>
          <div className={classNames(styles.cardWrapper, 'tw-flex-centering')}>
            {cardsStore.loading && <Loader />}
            {!cardsStore.loading && activeCardStore.card && (
              <Card
                isLocked={activeCardStore.cardData?.freezed}
                cardType={activeCardStore.card.cardType}
                cardData={activeCardStore?.cardData}
                cardBalance={activeCardStore?.balance}
                containerClassNames="tw-relative"
              >
                {
                  activeCardStore.cardData?.pinNotSet &&
                    <ActivateCardOverlap onClickSetPin={() => handleActionClick('setPin')} />
                }
                {activeCardStore.loading &&
                  <div className="tw-position-centering">
                    <Loader />
                  </div>
                }
              </Card>
            )}
            {!cardsStore.loading && !filteredCards.length && (
              <p>No card data available</p>
            )}
          </div>
        </div>
        <div className={styles.transactionColumn}>
          <CardTransactions cardId={activeCardStore.card?.id}></CardTransactions>
        </div>
      </section>
      <div className={styles.CardsControls}>
        {!cardsStore.loading && filteredCards.length > 1 && (
          <section className={styles.MyCardsMini}>
            <h5 className={styles.MyCardsMiniTitle}>My cards</h5>
            <div className={styles.MyCardsSelectorWrapper}>
              <MyCardsSelector
                cards={filteredCards}
                activeCard={activeCardStore.card}
                onCardClick={(card: ICardDto) => handleCardSelectorClick(card)}
              ></MyCardsSelector>
            </div>
          </section>
          )
        }
        <section className={styles.actionButtons}>
          <div className={styles.actionButtonsWrapper}>
            {filteredActionButtons.map((button) => (
              <ActionButton
                key={button.type}
                icon={button.icon}
                text={button.text}
                disabled={button.disabled}
                pinNotSet={activeCardStore.cardData?.pinNotSet}
                onClick={() => handleActionClick(button.type)}
              />
            ))}
          </div>
        </section>
      </div>
      
      {isOpenCardDetailsDialog && activeCardStore.card?.cardType &&
          <Modal
              isOpen={isOpenCardDetailsDialog}
              className={styles.CardDetailDialog}
              onRequestClose={() => setOpenCardDetailsDialog(false)}
          >
              <Card
                cardType={activeCardStore.card.cardType}
                cardData={activeCardStore?.cardData}
                cardBalance={activeCardStore?.balance}
                isShowCvv={!!activeCardStore.cardData?.cvv}
                isMaskedData={false}
            ></Card>
          </Modal>
      }
      
      {isOpenChangePinDialog && activeCardStore.card?.id &&
          <Modal
              isOpen={isOpenChangePinDialog}
              className={styles.CardSetPinDialog}
              onRequestClose={() => setOpenChangePinDialog(false)}
          >
              <CardPinDialogContent
                cardId={activeCardStore.card.id}
                onChangedPin={handleChangedPin}
              ></CardPinDialogContent>
          </Modal>
      }
    </div>
  );
});

export default MyCardsPage;
