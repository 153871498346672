import {
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeVisaPhysical
} from '../../../../services/card-api';

export interface ICardFeature {
  cardImageScr: string;
  cardType: IAvailableCardTypes,
  cardNetwork: string,
  currency: string;
  fundingMethod: string;
  paymentForGoodsAndServices: boolean;
  cashWithdrawals: boolean;
  cardIssuanceCost: string;
  cardTopUpOptions: string;
  transactionLimit: string;
  dailyLimit: string;
  monthlyLimit: string;
  brandLogo: { src: string; alt: string };
}

export type IAvailableCardTypes = CardTypeMastercardPhysical.PhysicalMastercard
  | CardTypeVisaPhysical.PhysicalVisa
  | CardTypeMastercardVirtual.VirtualMastercardSuperTransfer
  | CardTypeApplePay.VirtualApplePay;

export const cardsFeatureMap: Record<IAvailableCardTypes, ICardFeature> = {
  [CardTypeMastercardPhysical.PhysicalMastercard]: {
    cardImageScr: 'ms-physical-card.svg',
    cardType: CardTypeMastercardPhysical.PhysicalMastercard,
    cardNetwork: 'Mastercard',
    currency: 'Hong Kong Dollar (HKD)',
    fundingMethod: 'USD Tether (USDT)',
    paymentForGoodsAndServices: true,
    cashWithdrawals: true,
    cardIssuanceCost: '150 USD',
    cardTopUpOptions: '2.2%',
    transactionLimit: 'No Limited',
    dailyLimit: 'No Limited',
    monthlyLimit: '1,000,000 USD',
    brandLogo: { src: 'mastercard-icon.svg', alt: 'Mastercard brand logo' },
  },
  [CardTypeVisaPhysical.PhysicalVisa]: {
    cardImageScr: 'visa-physical-card.svg',
    cardType: CardTypeVisaPhysical.PhysicalVisa,
    cardNetwork: 'VISA',
    currency: 'Hong Kong Dollar (HKD)',
    fundingMethod: 'USD Tether (USDT)',
    paymentForGoodsAndServices: true,
    cashWithdrawals: true,
    cardIssuanceCost: '150 USD',
    cardTopUpOptions: '2.2%',
    transactionLimit: 'No Limited',
    dailyLimit: 'No Limited',
    monthlyLimit: '1,000,000 USD',
    brandLogo: { src: 'visa-dark-icon.svg', alt: 'Visa brand logo' },
  },
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: {
    cardImageScr: 'mastercard-virtual-card.svg',
    cardType: CardTypeMastercardVirtual.VirtualMastercardSuperTransfer,
    cardNetwork: 'Mastercard',
    currency: 'US Dollar (USD)',
    fundingMethod: 'USD Tether (USDT)',
    paymentForGoodsAndServices: true,
    cashWithdrawals: false,
    cardIssuanceCost: '15 USD',
    cardTopUpOptions: '2.2%',
    transactionLimit: '2,500 USD',
    dailyLimit: '10,000 USD',
    monthlyLimit: '100,000 USD',
    brandLogo: { src: 'mastercard-icon.svg', alt: 'Mastercard brand logo' },
  },
  [CardTypeApplePay.VirtualApplePay]: {
    cardImageScr: 'mastercard-virtual-apay-card.svg',
    cardType: CardTypeApplePay.VirtualApplePay,
    cardNetwork: 'Mastercard Apple Pay',
    currency: 'US Dollar (USD)',
    fundingMethod: 'USD Tether (USDT)',
    paymentForGoodsAndServices: true,
    cashWithdrawals: false,
    cardIssuanceCost: '15 USD',
    cardTopUpOptions: '2.2%',
    transactionLimit: '2,500 USD',
    dailyLimit: '10,000 USD',
    monthlyLimit: '100,000 USD',
    brandLogo: { src: 'mastercard-virtual-apay-card-icon.svg', alt: 'Mastercard Apple pay brand logo' },
  },
};

export const getCardFeature = (cardType: IAvailableCardTypes) => {
  return { ...cardsFeatureMap[cardType] };
}
