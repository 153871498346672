import { Link, NavLink } from 'react-router-dom';
import * as styles from './Header.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import mainStore from '../../../store/main.ts';
import Button from '../../../components/Button';
import { useBreakpoint } from '../../../hooks/useBreakpoint.ts';
import { PageContainer } from '../../../components/PageContainer/PageContainer.tsx';
import { burgerMenuStore } from '../../../components/BurgerMenu/BurgerMenu.store.ts';
import { observer } from 'mobx-react-lite';
import { Breakpoints } from '../../../helpers/breakpoints.ts';

export const Header = observer(() => {
  const [scrolled, setScrolled] = useState(false);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (breakpoint === Breakpoints.XL) {
      burgerMenuStore.closeBurgerMenu();
    }
  }, [breakpoint]);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <header className={classNames(styles.Header, {[styles.HeaderScrolled]: scrolled })}>
      <PageContainer innerContainerClassName="tw-flex tw-items-center tw-justify-between tw-gap-5">
        <div>
          <img src="/vplus-dark-full.svg" className="tw-h-6.25 tw-w-auto" alt="vplus-logo"/>
        </div>
        <div className="tw-hidden xl:tw-flex tw-items-center tw-gap-8">
          {mainStore.user
            ?
            <NavLink to="/app/profile" className="tw-flex tw-items-center tw-gap-2.5">
              <Icon icon="profile-circle" className="tw-w-6"/>
              <span>User profile</span>
            </NavLink>
            : <>
              <Link to="/login" className="tw-mb-0">Sign in</Link>
              <Link
                className="tw-flex-centering tw-bg-softGreen tw-rounded-lg tw-h-8 tw-w-30 tw-mb-0"
                to="/login/?action=signUpPhone"
              >
                Sign up
              </Link>
            </>
          }
        </div>
        <div className="xl:tw-hidden">
          <Button
            className="tw-h-auto tw-w-auto tw-p-0 tw-bg-transparent tw-border-none"
            onClick={() => burgerMenuStore.toggleBurgerMenu()}
          >
            <Icon icon="menu" className="tw-size-4.5 tw-fill-black-700"></Icon>
          </Button>
        </div>
      </PageContainer>
    </header>
  );
});