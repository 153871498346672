import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styles from './TopUpCard.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IDepositDto, cardApiService } from '../../../../../services/card-api';
import { createCardDetailStore } from '../store';
import classNames from 'classnames';
import Button from '../../../../../components/Button';
import { Card } from '../../components/Card/Card.tsx';
import CopyValue from '../../../../../components/CopyValue';
import { toCanvas } from 'qrcode';
import Loader from '../../../../../components/Loader';
import Icon from '../../../../../components/Icon';
import { exchangeRateService } from '../../../../../services/exchange-api/exchange-api.service.ts';
import { isPhysicalCard } from '../../../../../helpers/card-kind.helper.ts';
import formatBalance from '../../../../../helpers/formatBalance.ts';

const minTopUpLimitVirtualCard = 50;
const maxTopUpLimitVirtualCard = 10000;
const minTopUpLimitPhysicalCard = 50;
const maxTopUpLimitPhysicalCard = 100000;

export const TopUpCard = observer(() => {
  const { cardId } = useParams() as { cardId: string };
  const navigate = useNavigate();
  const cardStore = useLocalObservable(() => createCardDetailStore());
  const [selectedCoin, setSelectedCoin] = useState<string | null>(null);
  const [selectedChain, setSelectedChain] = useState<string | null>(null);
  const [deposit, setDepositState] = useState<{ isLoading: boolean; data: IDepositDto | null }>({
    isLoading: false,
    data: null,
  });
  const [exchange, setExchangeState] = useState<{ isLoading: boolean; rate: number | null }>({
    isLoading: false,
    rate: null,
  });

  const loadExchangeRate = async () => {
    setExchangeState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const res = await exchangeRateService.exchangeRate();
      setExchangeState({ isLoading: false, rate: res?.rate ?? 0 });
    } catch (error) {
      setExchangeState({ isLoading: false, rate: null });
    }
  };

  const loadWalletAddress = async (cardId: string) => {
    try {
      setDepositState((prevState) => ({ ...prevState, isLoading: true }));
      const depositData = await cardApiService.getDeposit(cardId);
      setDepositState({ isLoading: false, data: depositData });
    } catch (error) {
      setDepositState({ isLoading: false, data: null });
    }
  };

  useEffect(() => {
    cardStore.loadCardData(cardId);
  }, [cardId]);

  useEffect(() => {
    if (cardStore.cardData?.freezed) navigate('/app/cards');
  }, [cardStore.cardData]);

  useEffect(() => {
    const canvas = document.getElementById('WalletQrCode');
    if (deposit.data?.usdtTrcAddress) {
      toCanvas(canvas, deposit.data?.usdtTrcAddress ?? '', { width: 122, margin: 3 });
    }
  }, [deposit.data]);

  useEffect(() => {
    if (selectedCoin && selectedChain) {
      loadWalletAddress(cardId);
      if (isPhysicalCard(cardStore.cardData?.cardType)) loadExchangeRate();
    }
  }, [selectedCoin, selectedChain]);

  return (
    <div className={classNames(styles.TopUpPage, 'regular-page')}>
      <section>
        <h5 className="tw-text-body tw-font-semibold tw-text-3lg">Your card</h5>
        <div className="tw-mt-4">
          <div className={classNames(styles.CardWrapper, 'tw-flex-centering')}>
            {cardStore.loading && <Loader />}
            {!cardStore.loading && cardStore.cardData && (
              <Card
                isLocked={cardStore.cardData.freezed}
                cardType={cardStore.cardData.cardType}
                cardData={cardStore?.cardData}
                cardBalance={cardStore.balance}
                styled='TopUpCard'
              ></Card>
            )}
          </div>
          { selectedCoin && selectedChain &&
              <div className="tw-flex tw-flex-col tw-gap-4.5 tw-mt-4.5">
                {isPhysicalCard(cardStore.cardData?.cardType) &&
                    <div
                        className={classNames(styles.BlockInfo, 'tw-flex tw-gap-4.5 tw-bg-secondary-75 tw-font-semibold')}>
                        <div>
                            <img
                                src="/images/exchange-in-green-container-icon.svg"
                                alt="Exchange icon"
                                className="tw-w-7.5 tw-h-6.5"
                            />
                        </div>
                        <div className="">
                            <span className="tw-text-sm tw-text-secondary">Exchange rate</span>
                            <div className="tw-text-body tw-text-2xl">
                              {exchange.isLoading
                                ? <div className="tw-flex-centering tw-h-9"><Loader/></div>
                                : `1 USDT = ${exchange.rate} HKD`
                              }
                            </div>
                        </div>
                    </div>
                }
                  <div
                      className={classNames(styles.BlockInfo, 'tw-flex tw-gap-4.5 tw-bg-secondary-75 tw-font-semibold')}>
                      <div>
                          <img
                              src="/images/percent-in-green-container-icon.svg"
                              alt="Percent icon"
                              className="tw-w-7.5 tw-h-6.5"
                          />
                      </div>
                      <div>
                          <span className="tw-text-sm tw-text-secondary">Top up fee</span>
                          <div className="tw-text-body tw-text-2xl tw-font-semibold">
                              2.2%
                          </div>
                      </div>
                  </div>

                  <div
                    className={classNames(styles.TopUpLimitsBlockInfo, 'tw-flex tw-gap-4.5 tw-bg-secondary-75 tw-text-sm')}>
                    <div>
                      <img
                        src="/images/information-icon-with-red-container.svg"
                        alt="Alert icon"
                        className="tw-w-7.5 tw-h-6.5"
                      />
                    </div>
                    <div>
                      <span className="tw-text-secondary">Top up limits</span>
                        <div className="tw-mt-2">
                          <p>
                            The following top-up limits apply at VPlus to maintain <br/>
                            a secure and efficient transaction process. Please take <br/>
                            a moment to review the details: <br/>
                          </p>
                        </div>
                        <div className="tw-mt-4">
                            <div className="tw-flex">
                                <span>Minimum Top Up Amount:</span>
                                <span className="tw-ml-0.5">
                                  {
                                    (isPhysicalCard(cardStore.cardData?.cardType)
                                      ? minTopUpLimitPhysicalCard
                                      : minTopUpLimitVirtualCard
                                    ).toLocaleString('en-us')
                                  } USDT
                                </span>
                            </div>
                            <div>
                                <span>Maximum Top Up Amount:</span>
                                <span className="tw-ml-0.5">
                                  {
                                    (isPhysicalCard(cardStore.cardData?.cardType)
                                      ? maxTopUpLimitPhysicalCard
                                      : maxTopUpLimitVirtualCard
                                    ).toLocaleString('en-us')
                                  } USDT per day
                                </span>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          }
        </div>
      </section>
      <section>
        <h5 className="tw-text-body tw-font-semibold tw-text-3lg">Deposit</h5>
        <div className={classNames(styles.DepositSection, 'tw-mt-6 tw-bg-secondary-75')}>
          <div className="tw-font-semibold tw-text-body">Choose coin</div>
          {
            !selectedChain &&
              <div className="tw-flex tw-flex-col tw-gap-6.5">
                  <div>
                      <div className="tw-text-secondary tw-text-sm tw-font-semibold">Specify the coin to be deposit
                      </div>
                      <div className="tw-mt-5">
                          <Button
                              className={styles.Button}
                              additional={{
                                isGray: selectedCoin !== 'usdt',
                                isSoftGreen: selectedCoin === 'usdt',
                            }}
                            onClick={() => setSelectedCoin('usdt')}
                          >
                              <img
                                  src="/images/usdt-icon.svg"
                                  className={`tw-size-6.5 ${selectedCoin === 'usdt' ? '' : 'tw-grayscale'}`}
                                  alt="usdt-icon"
                              />
                              USDT
                          </Button>
                      </div>
                  </div>
                {selectedCoin &&
                    <div>
                        <div className="tw-text-secondary tw-text-sm tw-font-semibold">Chain type</div>
                        <div className="tw-mt-5">
                            <Button
                                className={styles.Button}
                                additional={{
                                  isGray: selectedChain !== 'tron',
                                  isSoftGreen: selectedChain === 'tron',
                                }}
                                onClick={() => setSelectedChain('tron')}
                            >
                                <img
                                    src="/images/tron-icon.svg"
                                    className={`tw-size-6 ${selectedCoin === 'tron' ? '' : 'tw-grayscale'}`}
                                    alt="tron-icon"
                                />
                                Tron
                            </Button>
                        </div>
                    </div>
                }
              </div>
          }
          {
            selectedChain &&
              <div className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-gap-7.5">
                      <div>
                          <div className="tw-text-secondary tw-text-sm tw-font-semibold">Specify the coin to be
                              deposit
                          </div>
                          <Button
                            className={classNames(styles.Button, 'tw-mt-5')}
                            additional={{ isSoftGreen: true }}
                          >
                              <img className="tw-size-6.5" src="/images/usdt-icon.svg" alt="usdt-icon"/>
                              USDT
                          </Button>
                      </div>
                      <div>
                          <div className="tw-text-secondary tw-text-sm tw-font-semibold">Chain type</div>
                          <Button
                            className={classNames(styles.Button, 'tw-mt-5')}
                            additional={{ isSoftGreen: true }}
                          >
                              <img className="tw-size-6" src="/images/tron-icon.svg" alt="tron-icon"/>
                              Tron
                          </Button>
                      </div>
                  </div>
                <div className="tw-mt-9 tw-text-sm tw-font-semibold tw-text-body">
                      To deposit USDT please go to your wallet and send to the USDT wallet address below.
                      To avoid typing address, copy and paste.
                  </div>

                  <div className="tw-flex tw-items-center tw-gap-11 tw-mt-5">
                    {
                      deposit.isLoading
                        ? <div className="tw-flex-centering tw-w-full"><Loader/></div>
                        : <>
                          <div className={classNames(
                            styles.WalletQrCodeWrapper,
                            'tw-flex-centering tw-border tw-border-black tw-rounded-2xl tw-overflow-hidden'
                          )}>
                            <canvas id="WalletQrCode"></canvas>
                          </div>
                          <div>
                            <div className="tw-text-sm tw-font-semibold tw-text-secondary">Wallet address</div>
                            <div className="tw-flex tw-gap-3.5 tw-font-semibold tw-text-body tw-mt-2.5">
                              {deposit.data?.usdtTrcAddress}
                              <CopyValue value={deposit.data?.usdtTrcAddress ?? ''} className={styles.CopyWalletIcon}/>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                  <div className="tw-flex-1 tw-mt-6.5 tw-text-body tw-font-semibold tw-text-sm">
                      <p>Be aware!</p>
                      <div className="tw-mt-5">
                          <p>1. Verify the deposit chain matches the chain you are sending form. Funds sent
                              to an incorrect address may be irrecoverably lost.
                          </p>
                          <p>2. Only send USDT to this address. Depositing any other token may result in permanent
                              of funds.
                          </p>
                      </div>
                  </div>
                  <div className="tw-mt-4 tw-flex tw-justify-end">
                    <Link
                        className="tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-font-semibold"
                        to="/app/cards"
                    >
                        Complete
                        <div className="tw-flex-centering tw-size-8 tw-bg-softGreen tw-rounded-7px">
                            <Icon icon="arrow-right-lined" className={'tw-size-5'}/>
                        </div>
                    </Link>
                  </div>
              </div>
          }
        </div>
      </section>
    </div>
  );
});
