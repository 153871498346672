import { observer } from "mobx-react-lite";
import styles from "./ProfileSummary.module.scss";

import mainStore from "../../../../store/main.ts";

export const ProfileSummary = observer(() => {
  if (!mainStore.user) return null;

  return (
    <div className={styles.ProfileSummary}>
      <img src="/user-avatar.svg" alt="user-avatar" className={styles.userAvatar}/>
      <div className="tw-min-w-0">
        <div className="tw-text-2xl xl:tw-text-3.5xl tw-truncate">
          {mainStore.userFullName() || `User ${mainStore.user._id}`}
        </div>
      </div>
    </div>
  );
});