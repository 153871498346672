import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useRef, useState } from 'react';
import { Calendar, DateRange, RangeKeyDict } from 'react-date-range';
import Icon from '../Icon';
import styles from './index.module.css';

export interface DatePickerStateModel {
  startDate: Date;
  endDate?: Date;
  key?: string;
}

function DatePicker(props: {
  startDate?: Date;
  endDate?: Date;
  hasIcon?: boolean;
  blackBorder?: boolean;
  singleDateDefaultValue?: boolean;
  label?: string;
  onChange?: (state: DatePickerStateModel) => void;
  singleDate?: boolean;
  containerClassName?: string;
  onClose?: (state: DatePickerStateModel) => void;
}) {
  // date state
  const [range, setRange] = useState<DatePickerStateModel[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    props.singleDateDefaultValue ? new Date() : undefined
  );

  // open close
  const [open, setOpen] = useState<boolean | null>(null);

  // get the target element to toggle
  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (!props.singleDate) {
      setRange([
        {
          startDate: props.startDate || new Date(),
          endDate: props.endDate || addDays(new Date(), 7),
          key: 'selection'
        }
      ]);
    } else if (props.singleDateDefaultValue) {
      setSelectedDate(props.startDate || new Date());
    }
  }, [props.startDate, props.endDate, props.singleDate, props.singleDateDefaultValue]);

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
    return () => {
      document.removeEventListener('keydown', hideOnEscape, true);
      document.removeEventListener('click', hideOnClickOutside, true);
    };
  }, []);
  
  useEffect(() => {
    if (open === false) props.onClose?.(range[0]);
  }, [open]);

  // hide dropdown on ESC press
  const hideOnEscape = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (ev: MouseEvent) => {
    if (
      refOne.current &&
      !refOne.current.contains(ev.target as Node) &&
      refTwo.current &&
      !refTwo.current.contains(ev.target as Node)
    ) {
      setOpen(false);
    }
  };

  const changeHandlerRange = (item: RangeKeyDict) => {
    // @ts-ignore
    setRange([item.selection]);
    // @ts-ignore
    props.onChange?.(item.selection);
  };

  const changeHandlerSingle = (date: Date) => {
    setSelectedDate(date);
    props.onChange?.({ startDate: date });
  };

  // Format dates
  const formattedStartDate = props.singleDate
    ? selectedDate
      ? format(selectedDate, 'dd.MM.yyyy')
      : ''
    : range[0].startDate
      ? format(range[0].startDate, 'dd.MM.yyyy')
      : '';

  const formattedEndDate = props.singleDate
    ? ''
    : range[0].endDate
      ? format(range[0].endDate, 'dd.MM.yyyy')
      : '';

  /* DOM */
  return (
    <div className={styles.datePickerWrapper}>
      <div
        ref={refTwo}
        className={classNames(styles.dateContainer, props.containerClassName, {
          blackBorder: props.blackBorder
        })}
        onClick={() => setOpen(!open)}>
        <span>
          <span className={styles.hintDate}>
            {!selectedDate &&
              props.singleDate &&
              (props.label ?? 'Select date')}
          </span>{' '}
          {formattedStartDate} {formattedEndDate && `- ${formattedEndDate}`}
        </span>
        {props.hasIcon && <Icon icon="custom-filter-1" />}
      </div>

      {open && (
        <div className={styles.datePickerContainer} ref={refOne}>
          {props.singleDate ? (
            <Calendar
              date={selectedDate}
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              months={1}
              direction="vertical"
              onChange={changeHandlerSingle}
              color="#BFEA5C"
              classNames={{
                calendarWrapper: styles.datePicker,
                daySelected: styles.datePickerItemActive,
                day: styles.datePickerDay,
                days: styles.days,
                dayNumber: styles.datePickerDayNumber,
                weekDay: styles.weekDay,
                dayPassive: styles.dayPassive,
                month: styles.months,
                weekDays: styles.weekDays,
                monthAndYearPickers: styles.monthAndYearPickers,
                nextButton: styles.nextButton,
                prevButton: styles.prevButton,
                monthAndYearWrapper: styles.monthAndYearWrapper,
                selected: styles.datePickerDayNumber,
                dayHovered: styles.dayHovered,
                dayToday: styles.dayToday,
                dayStartPreview: styles.dayStartPreview,
                dayEndPreview: styles.dayEndPreview
              }}
            />
          ) : (
            <DateRange
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              onChange={changeHandlerRange}
              moveRangeOnFirstSelection={false}
              rangeColors={['#BFEA5C']}
              ranges={range}
              months={1}
              direction="vertical"
              classNames={{
                calendarWrapper: styles.datePicker,
                daySelected: styles.datePickerItemActive,
                day: styles.datePickerDay,
                days: styles.days,
                dayNumber: styles.datePickerDayNumber,
                weekDay: styles.weekDay,
                dayPassive: styles.dayPassive,
                month: styles.months,
                weekDays: styles.weekDays,
                monthAndYearPickers: styles.monthAndYearPickers,
                nextButton: styles.nextButton,
                prevButton: styles.prevButton,
                monthAndYearWrapper: styles.monthAndYearWrapper,
                dayHovered: styles.dayHovered,
                dayToday: styles.dayToday,
                dayStartPreview: styles.dayPreview,
                dayEndPreview: styles.dayPreview,
                startEdge: styles.edge,
                endEdge: styles.edge,
                inRange: styles.inRange
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DatePicker;
