import {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BgIcon from "../../../../../components/BgIcon";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import RowSelect from "../../../../../components/RowSelect";
import TextInput from "../../../../../components/TextInput";
import {soiArrayIndividual} from "../../../../../controllers/users";
import getMe from "../../../../../controllers/users/getMe.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import validate from "../../../../../helpers/validate.ts";
import styles from "./index.module.css";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function IFinancialStep() {
  if(!mainStore.user || (mainStore.user.type !== "individual")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    purposeOfUse: mainStore.user.financialData.purposeOfUse,
    monthlyIncome: mainStore.user.financialData.monthlyIncome,
    monthlyExpense: mainStore.user.financialData.monthlyExpense,
    sourceOfIncome: mainStore.user.financialData.sourceOfIncome,
    soiArray: mainStore.user.financialData.soiArray
  };
  /* Vars */
  const isSubmitAvailable = !(
    mainStore.user.filledKycs.includes("financial")
    ||
    mainStore.user.acceptedKycs.includes("financial")
  );

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("comment256", formState.purposeOfUse, "Purpose of Use"),
        validate("integer", formState.monthlyIncome, "Monthly Income"),
        validate("integer", formState.monthlyExpense, "Monthly Expense"),
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post("/users/edit", {
        financialData: {
          purposeOfUse: formState.purposeOfUse,
          monthlyIncome: formState.monthlyIncome,
          monthlyExpense: formState.monthlyExpense,
          sourceOfIncome: formState.sourceOfIncome,
          soiArray: formState.soiArray
        }
      });
    })
    .then(() => {

      /* Send submit request */
      return apiClient.post("/users/submitKyc", {
        kycStep: "financial"
      });
    })
    .then(() => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `New data sent to our admins for verification`
      });

      /* Change step */
      $setSearchParams(new URLSearchParams({
        ...$searchParams,
        step: "final"
      }));

      /* Update user data */
      getMe()
      .catch(onError);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Financial Information
        </div>

        <div className="page-form-segment-container">

          <TextInput
            placeholder="Purpose of using VPlus"
            value={formState.purposeOfUse}
            onChange={(value) => setFormState({...formState, purposeOfUse: value})}
            isReadOnly={isFormPending || !isSubmitAvailable}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="Monthly income (in your local currency)"
            value={formState.monthlyIncome ?? undefined}
            onChange={(value) => setFormState({...formState, monthlyIncome: +value || null})}
            isReadOnly={isFormPending || !isSubmitAvailable}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="Monthly expense (in your local currency)"
            value={formState.monthlyExpense ?? undefined}
            onChange={(value) => setFormState({...formState, monthlyExpense: +value || null})}
            isReadOnly={isFormPending || !isSubmitAvailable}
          />
        </div>
      </div>

      <div style={{height: 60, flexShrink: 0}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          <div>Source of funds explanation</div>

          <div style={{height: 10}} />

          <div style={{fontSize: '11pt', color: '#B4B4B4'}}>
            (select all choices that apply)
          </div>
        </div>

        <div className="page-form-segment-container">

          <div className="big-heading" style={{fontSize: '16pt'}}>
            Please describe the source of funds, which will be transferred
            to the account.
          </div>

          <div style={{height: 10}} />

          <RowSelect
            options={soiArrayIndividual.map((option) => ({
              value: option, contents: option
            }))}
            values={formState.soiArray}
            onChange={(values) => setFormState({...formState, soiArray: values})}
            isReadOnly={isFormPending || !isSubmitAvailable}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="Other (not required)"
            value={formState.sourceOfIncome}
            onChange={(value) => setFormState({...formState, sourceOfIncome: value})}
            isReadOnly={isFormPending || !isSubmitAvailable}
          />
        </div>
      </div>

      <div style={{height: 60, flexShrink: 0}} />
      <div style={{height: "100%"}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending || !isSubmitAvailable}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon icon="arrow-right-lined" background={isSubmitAvailable ? "var(--soft-green)" : "#ddd"} />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
