import React, {FormEvent, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import BgIcon from "../../../../components/BgIcon";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import apiClient from "../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import {parseFloatFromString} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";

import mainStore from "../../../../store/main.ts";
import AccountModel from "../../../../controllers/accounts/index.ts";
import getMyAccounts from "../../../../controllers/accounts/getList.ts";
import {WithdrawTransactionModel} from "../../../../controllers/transactions";

/* Component */
export default function WithdrawFundsPage() {
  if(!mainStore.user) return null;

  /* Hooks */
  const $navigate = useNavigate()
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    amount: '',
    referenceMessage: '',

    recipient: {
      name: '',
      address: '',
      country: '',
      account: ''
    },

    beneficiaryBank: {
      name: '',
      address: '',
      country: '',
      swift: ''
    },

    intermediaryBank: {
      name: '',
      address: '',
      country: '',
      swift: ''
    }
  };
  const selectedAccountId = $searchParams.get('selectedAccountId')
  if(!selectedAccountId) {
    return null
  }

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [fees, setFees] = useState<{[key: string]: number} | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<AccountModel | null>(null)

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
    .then(() => {

      /* Validate client data */
      for(const validation of [
        validate("comment256", formState.referenceMessage, "Reference Message"),

        validate("comment256", formState.recipient.name, "Recipient Name"),
        validate("comment256", formState.recipient.address, "Recipient Address"),
        validate("comment256", formState.recipient.country, "Recipient Country"),
        validate("comment256", formState.recipient.account, "Recipient Account"),

        validate("comment256", formState.beneficiaryBank.name, "Beneficiary bank Name"),
        validate("comment256", formState.beneficiaryBank.name, "Beneficiary bank Address"),
        validate("comment256", formState.beneficiaryBank.swift, "Beneficiary bank Swift"),
        validate("comment256", formState.beneficiaryBank.country, "Beneficiary bank Country"),

        validate("comment0256", formState.intermediaryBank.name, "Intermediary bank Name"),
        validate("comment0256", formState.intermediaryBank.name, "Intermediary bank Address"),
        validate("comment0256", formState.intermediaryBank.swift, "Intermediary bank Swift"),
        validate("comment0256", formState.intermediaryBank.country, "Intermediary bank Country")
      ]) {
        if(typeof validation === "string") {
          throw new Error(validation);
        }
      }
    })
    .then(() => {

      /* Send API request */
      return apiClient.post<WithdrawTransactionModel>("/transactions/createWithdraw", {
        amount: +formState.amount * 100,
        referenceMessage: formState.referenceMessage,
        fromAccountId: selectedAccountId,

        recipient: formState.recipient,
        beneficiaryBank: formState.beneficiaryBank,
        intermediaryBank: formState.intermediaryBank,
      });
    })
    .then(({data: transaction}) => {

      /* On done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: `You successfully sent withdraw request`
      });

      /* Navigate to profile page */
      $navigate(`/app/transactions?selectedTransactionId=${transaction._id}`);
    })
    .catch(onError)
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* Get accounts & fees on mounted */
  useEffect(() => {

    Promise.resolve()
    .then(() => {
      return apiClient.get('/withdrawFees/get')
    })
    .then(({data: fees}) => {
      setFees(fees)

      return getMyAccounts()
    })
    .then((accounts) => {

      const selectedAccount = accounts.find((account) => account._id === selectedAccountId)
      if(!selectedAccount) {
        throw new Error('No account found. Please go to main page and try again')
      }

      setSelectedAccount(selectedAccount)
    })
    .catch(onError)
  }, [$searchParams])

  if(!fees || !selectedAccount) {
    return (
      <div className="loader-container">
        <Loader spinsPerSecond={3} />
      </div>
    );
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} className="white-block" style={{padding: 30}}>
      <div className="big-heading">
        Withdrawal Funds
      </div>

      <div style={{height: 20}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          General
        </div>

        <div className="page-form-segment-container">

          <TextInput
            placeholder="Amount"
            value={formState.amount || ""}
            onChange={(value) => setFormState({...formState, amount: parseFloatFromString(value).string})}
            isReadOnly={isFormPending}
            rightContents={selectedAccount.currency}
          />

          <div style={{height: 10}} />

          <div
            className="white-block flex flex-aic flex-jcsb"
            style={{flexShrink: 0, background: '#eee', padding: '15px', borderRadius: 4}}
          >
            <div>
              Fee: {fees[selectedAccount.currency]}%
            </div>

            {formState.amount ? (
              <div>
                {' '} Amount with
                fee: {selectedAccount['currency']} {(+formState.amount + +formState.amount * (fees[selectedAccount.currency] / 100)).toFixed(
                2)}
              </div>
            ) : null}
          </div>

          <div style={{height: 30}} />

          <TextInput
            placeholder="Reference message"
            value={formState.referenceMessage}
            onChange={(value) => setFormState({...formState, referenceMessage: value})}
            isReadOnly={isFormPending}
          />
        </div>
      </div>

      <div style={{height: 50}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Recipient
        </div>

        <div className="page-form-segment-container">

          <TextInput
            placeholder="Name"
            value={formState.recipient.name}
            onChange={(value) => setFormState({...formState, recipient: {...formState.recipient, name: value}})}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="Bank account"
            value={formState.recipient.account}
            onChange={(value) => setFormState({...formState, recipient: {...formState.recipient, account: value}})}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <div className="flex">

            <TextInput
              placeholder="Country"
              value={formState.recipient.country}
              onChange={(value) => setFormState({...formState, recipient: {...formState.recipient, country: value}})}
              isReadOnly={isFormPending}
            />

            <TextInput
              placeholder="Address"
              value={formState.recipient.address}
              onChange={(value) => setFormState({...formState, recipient: {...formState.recipient, address: value}})}
              isReadOnly={isFormPending}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Beneficiary Bank
        </div>

        <div className="page-form-segment-container">

          <TextInput
            placeholder="Name"
            value={formState.beneficiaryBank.name}
            onChange={(value) => setFormState({
              ...formState,
              beneficiaryBank: {...formState.beneficiaryBank, name: value}
            })}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="SWIFT"
            value={formState.beneficiaryBank.swift}
            onChange={(value) => setFormState({
              ...formState,
              beneficiaryBank: {...formState.beneficiaryBank, swift: value}
            })}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <div className="flex">

            <TextInput
              placeholder="Country"
              value={formState.beneficiaryBank.country}
              onChange={(value) => setFormState({
                ...formState,
                beneficiaryBank: {...formState.beneficiaryBank, country: value}
              })}
              isReadOnly={isFormPending}
            />

            <TextInput
              placeholder="Address"
              value={formState.beneficiaryBank.address}
              onChange={(value) => setFormState({
                ...formState,
                beneficiaryBank: {...formState.beneficiaryBank, address: value}
              })}
              isReadOnly={isFormPending}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          <div>Intermediary Bank</div>
          <div style={{opacity: .3, fontSize: '12pt'}}>(not required)</div>
        </div>

        <div className="page-form-segment-container">

          <TextInput
            placeholder="Name"
            value={formState.intermediaryBank.name}
            onChange={(value) => setFormState({
              ...formState,
              intermediaryBank: {...formState.intermediaryBank, name: value}
            })}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <TextInput
            placeholder="SWIFT"
            value={formState.intermediaryBank.swift}
            onChange={(value) => setFormState({
              ...formState,
              intermediaryBank: {...formState.intermediaryBank, swift: value}
            })}
            isReadOnly={isFormPending}
          />

          <div style={{height: 30}} />

          <div className="flex">

            <TextInput
              placeholder="Country"
              value={formState.intermediaryBank.country}
              onChange={(value) => setFormState({
                ...formState,
                intermediaryBank: {...formState.intermediaryBank, country: value}
              })}
              isReadOnly={isFormPending}
            />

            <TextInput
              placeholder="Country"
              value={formState.intermediaryBank.address}
              onChange={(value) => setFormState({
                ...formState,
                intermediaryBank: {...formState.intermediaryBank, address: value}
              })}
              isReadOnly={isFormPending}
            />
          </div>
        </div>
      </div>

      <div style={{height: 30}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon
              icon="check-mark-lined"
              background="var(--soft-green)"
            />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
