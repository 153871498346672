import { makeAutoObservable } from 'mobx';
import {
  cardApiService,
  ICardBalanceDto,
  ICardDataActiveDto,
  ICardDto,
} from '../../../../../services/card-api';

const defaultLoadCardDataParams: ILoadCardDataParams  = {
  loadBalance: true,
  loadCardData: true,
  resetOldData: true,
};

export class CardDetailStore<T = ICardDataActiveDto> {
  public card: ICardDto | null = null;
  public balance: ICardBalanceDto | null = null;
  public cardData: T | null = null;
  public loading: boolean = false;
  public isLoadingLock: boolean = false;
  
  private readonly _defaultLoadCardDataParams: ILoadCardDataParams  = {
    loadBalance: true,
    loadCardData: true,
    resetOldData: true,
  };
  private _abortController: AbortController | null = null;
  
  public constructor() {
    makeAutoObservable(this);
  }
  
  public setCard(card: ICardDto | null): void {
    this.card = card;
  }
  
  public async loadCardData(cardId: string, options: ILoadCardDataParams = this._defaultLoadCardDataParams): Promise<void> {
    this.setLoading(true);
    
    if (options.loadBalance && options.resetOldData) {
      this.setBalance(null);
    }
    
    if (options.loadCardData && options.resetOldData) {
      this.setCardData(null);
    }
    
    this._resetRequest();
    
    try {
      const signal = this._abortController?.signal;
      
      const balancePromise = options.loadBalance
        ? cardApiService.getBalance(cardId, { signal })
        : Promise.resolve(null);
      const cardDataPromise = options.loadCardData
        ? cardApiService.getDetailsCardData(cardId, { signal })
        : Promise.resolve(null);
      
      const [balanceResponse, cardDataResponse] = await Promise.allSettled([
        balancePromise,
        cardDataPromise,
      ]);
      
      if (options.loadBalance && balanceResponse) {
        if (balanceResponse.status === 'fulfilled') {
          this.setBalance(balanceResponse.value as ICardBalanceDto);
        } else {
          if (options.resetOldData) this.setBalance(null);
        }
      }
      
      if (options.loadCardData && cardDataResponse) {
        if (cardDataResponse.status === 'fulfilled') {
          this.setCardData(cardDataResponse.value as T);
        } else {
          if (options.resetOldData) this.setCardData(null);
        }
      }
      
    } catch (error) {
      if (options.loadBalance && options.resetOldData) {
        this.setBalance(null);
      }
      if (options.loadCardData && options.resetOldData) {
        this.setCardData(null);
      }
    } finally {
      this.setLoading(false);
    }
  }
  
  public async lockCard(cardId: string): Promise<void> {
    try {
      this.isLoadingLock = true;
      await cardApiService.lockCard(cardId);
      this.updateCardData({ freezed: true } as T);
    } finally {
      this.isLoadingLock = false;
    }
  }
  
  public async unlockCard(cardId: string): Promise<void> {
    try {
      this.isLoadingLock = true;
      await cardApiService.unlockCard(cardId);
      this.updateCardData({ freezed: false } as T);
    } finally {
      this.isLoadingLock = false;
    }
  }
  
  public updateCardData(payload: Partial<T>): void {
    this.cardData = { ...this.cardData, ...payload as T };
    if (this.card) this.card = { ...this.card, ...payload };
  }
  
  public setBalance(balance: ICardBalanceDto | null): void {
    this.balance = balance;
  }
  
  public setCardData(cardData: T | null): void {
    this.cardData = cardData;
  }
  
  public setLoading(value: boolean): void {
    this.loading = value;
  }
  
  public setIsLoadingLock(value: boolean): void {
    this.isLoadingLock = value;
  }
  
  private _resetRequest(): void {
    if (this._abortController) this._abortController.abort();
    this._abortController = new AbortController();
  }
}

export function createCardDetailStore<T = ICardDataActiveDto>() {
  return new CardDetailStore<T>();
}

interface ILoadCardDataParams {
  loadBalance?: boolean;
  loadCardData?: boolean;
  resetOldData?: boolean;
}