import validate from "../../../helpers/validate.ts";
import {FounderModel} from "../models/Founder.ts";

export default async function validateFounder(
  founder: FounderModel
): Promise<true | string> {

  if(!["individual", "business"].includes(founder.type)) {
    return "Invalid founder type";
  }

  /* Validate data */
  for(const validation of founder.type === "individual" ? [

    /* Individual founder data */
    validate("personName", founder.fullName, "Founder full name"),
    validate("dateOfBirth", new Date(founder.dateOfBirth), "Founder date of birth"),

    validate("comment256", founder.personalData.citizenShip, "Founder citizenship"),
    validate("comment256", founder.personalData.passport.number, "Founder passport number"),
    validate("pastDate", new Date(founder.personalData.passport.issuedAt), "Founder passport issue date"),
    validate("comment256", founder.personalData.passport.issuedBy, "Founder passport IA"),
    validate("date", new Date(founder.personalData.passport.expiringDate), "Founder passport expiry date"),
    validate("comment256", founder.personalData.residenceAddress, "Founder residence address"),
    validate("comment256", founder.personalData.residenceCountry, "Founder residence country"),
  ] : [

    /* Business founder data */
    validate("comment256", founder.businessName, "Founder bussiness name"),
    validate("comment256", founder.legalData.address, "Founder legal address"),
    validate("comment256", founder.legalData.country, "Founder legal country"),
    validate("comment256", founder.legalData.registrationNumber, "Founder registration number"),
    validate("pastDate", new Date(founder.legalData.registrationDate), "Founder registration name")
  ]) {
    if(typeof validation === "string") {
      return validation;
    }
  }

  if(founder.type === 'individual') {

    /* Check passport dates */
    const passportIssuedAt = new Date(founder.personalData.passport.issuedAt);
    const passportExpiryDate = new Date(founder.personalData.passport.expiringDate);

    if(passportExpiryDate < passportIssuedAt || passportExpiryDate < new Date()) {
      return 'Passport is expired'
    }
  }

  /* Return true if all correct */
  return true;
}
