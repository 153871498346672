import {availableKycs, UserModel} from "../controllers/users";

export default function getUserVerificationLevel(
  user: UserModel
): "verified" | "pending" | "none" {

  if(user.filledKycs.length) return "pending";

  let isUserVerified = true;
  for(const kycStep of availableKycs[user.type]) {
    if(!user.acceptedKycs.includes(kycStep as any)) {
      isUserVerified = false;
      break;
    }
  }

  return isUserVerified ? "verified" : "none";
}
