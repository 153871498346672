import { useCallback, useMemo, useState } from 'react';

export const useFormState = <TFormData>(initialValues: TFormData, mutable: boolean = false) => {
  const [state, setState] = useState<TFormData>(initialValues);
  
  const handleChange = useCallback(
    (value: string, key?: string) =>
      setState((currState) => {
        const newState: TFormData = { ...currState };
        
        if (key) {
          const keys = key.split('.');
          let obj: any = newState;
          
          for (let i = 0; i < keys.length - 1; i++) {
            obj = obj[keys[i]] as any;
          }
          
          obj[keys[keys.length - 1]] = value;
        }
        
        return newState;
      }),
    []
  );
  
  if (mutable) {
    return useMemo(() => [state, handleChange, setState] as [TFormData, (value: string, key?: string) => void, React.Dispatch<React.SetStateAction<TFormData>>], [state, handleChange]);
  }
  
  return useMemo(() => [state, handleChange, setState] as const, [state, handleChange]);
};
