import { FC } from 'react';
import styles from './ActionButton.module.css';
import classNames from 'classnames';
import Icon from '../../../../../../components/Icon';

interface ActionButtonProps {
  icon: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  pinNotSet?: boolean;
}

const ActionButton: FC<ActionButtonProps> = ({ icon, text, onClick, disabled, pinNotSet }) => {
  return (
    <div className={classNames(styles.actionButtonWrapper, 'tw-relative tw-overflow-hidden')}>
      <button
        className={styles.actionButton}
        disabled={disabled || pinNotSet}
        onClick={onClick}
      >
        <img loading="lazy" src={'/images/' + icon + '.svg'} className={styles.actionIcon} alt={icon + '-icon'}/>
        <span className={styles.actionText}>{text}</span>
      </button>
      {pinNotSet &&
          <div className={classNames(
            styles.NoActiveCard,
            'tw-absolute tw-position-full tw-flex-centering tw-before-absolute',
          )}>
              <Icon icon={'lock'} className="tw-z-1 tw-size-11"></Icon>
          </div>
      }
    </div>
  );
};

export default ActionButton;
