import {
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeVisaPhysical
} from '../../../../services/card-api';

export const cardOptionsGroup = [
  {
    label: 'Physical cards',
    options: [
      { value: CardTypeMastercardPhysical.PhysicalMastercard, label: 'Mastercard Platinum' },
      { value: CardTypeVisaPhysical.PhysicalVisa, label: 'VISA Platinum' }
    ]
  },
  {
    label: 'Virtual cards',
    options: [
      { value: CardTypeApplePay.VirtualApplePay, label: 'Mastercard Apple Pay' },
      { value: CardTypeMastercardVirtual.VirtualMastercardSuperTransfer, label: 'Mastercard' }
    ]
  }
] as const;
