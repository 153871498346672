import {FormEvent, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BgIcon from "../../../../../components/BgIcon";
import Button from "../../../../../components/Button";
import FileUploadBlock from "../../../../../components/FileUploadBlock";
import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import Select from "../../../../../components/Select";
import TextInput from "../../../../../components/TextInput";
import FileModel from "../../../../../controllers/files";
import {ExtendedIndividualUserModel, IndividualUserModel, UserModel} from "../../../../../controllers/users";
import getMe from "../../../../../controllers/users/getMe.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import countriesOptions from "../../../../../helpers/countriesOptions.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import validate from "../../../../../helpers/validate.ts";
import styles from "./index.module.css";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function IPersonalStep() {
  if(!mainStore.user || (mainStore.user.type !== "individual")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    documentType: mainStore.user.personalData.documentType,
    citizenShip: mainStore.user.personalData.citizenShip,
    passport: {
      number: mainStore.user.personalData.passport.number,
      issuedAt: mainStore.user.personalData.passport.issuedAt,
      issuedBy: mainStore.user.personalData.passport.issuedBy,
      expiringDate: mainStore.user.personalData.passport.expiringDate
    },
    residenceAddress: mainStore.user.personalData.residenceAddress,
    residenceCountry: mainStore.user.personalData.residenceCountry
  };
  const isSubmitAvailable = !(
    mainStore.user.filledKycs.includes("personal")
    ||
    mainStore.user.acceptedKycs.includes("personal")
  );

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    personalData: {
      documents: {
        documentProof: mainStore.user.personalData.documents.documentProof,
        addressProof: mainStore.user.personalData.documents.addressProof
      }
    }
  });

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
      .then(() => {

        /* Validate client data */
        for(const validation of [
          validate("comment256", formState.citizenShip, "Citizenship"),
          validate("comment256", formState.residenceAddress, "Residence address"),
          validate("comment256", formState.passport.number, "Passport number"),
          validate("pastDate", new Date(formState.passport.issuedAt), "Passport issue date"),
          validate("date", new Date(formState.passport.expiringDate), "Passport expiring date"),
          validate("comment256", formState.passport.issuedBy, "Passport issuing authority"),
          validate("comment256", formState.residenceAddress, "Residence address")
        ]) {
          if(typeof validation === "string") {
            throw new Error(validation);
          }
        }
      })
      .then(() => {

        /* Check passport dates */
        const passportIssuedAt = new Date(formState.passport.issuedAt);
        const passportExpiringDate = new Date(formState.passport.expiringDate);

        if(passportExpiringDate < passportIssuedAt || passportExpiringDate < new Date()) {
          throw new Error('Passport expire date must be greater then issue date and less then current date')
        }
      })
      .then(() => {

        /* Send API request */
        return apiClient.post("/users/edit", {
          personalData: {
            documentType: formState.documentType,
            citizenShip: formState.citizenShip,
            passport: {
              number: formState.passport.number,
              issuedAt: formState.passport.issuedAt,
              issuedBy: formState.passport.issuedBy,
              expiringDate: formState.passport.expiringDate
            },
            residenceAddress: formState.residenceAddress,
            residenceCountry: formState.residenceCountry,
            documents: {
              documentProofFileId: uploadedFiles.personalData.documents.documentProof?._id,
              addressProofFileId: uploadedFiles.personalData.documents.addressProof?._id
            }
          }
        });
      })
      .then(() => {

        /* Send submit request */
        return apiClient.post("/users/submitKyc", {
          kycStep: "personal"
        });
      })
      .then(() => {

        /* On done */
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Success`,
          contents: `New data sent to our admins for verification`
        });

        /* Change step */
        $setSearchParams(new URLSearchParams({
          ...$searchParams,
          step: "financial"
        }));

        /* Update user data */
        return getMe();
      })
      .catch(onError)
      .finally(() => {
        setIsFormPending(false);
      });
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Personal Information
        </div>

        <div className="page-form-segment-container">

          <div className="flex">

            <Select
              options={[
                {
                  value: "passport", element: "Passport"
                }, {
                  value: "idCard", element: "ID Card"
                }
              ]}
              onSelect={(type: IndividualUserModel["personalData"]["documentType"]) => setFormState({
                ...formState,
                documentType: type
              })}
              value={formState.documentType}
              placeholder={"Document Type"}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Citizenship"
              value={formState.citizenShip}
              onChange={(value) => setFormState({...formState, citizenShip: value})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50, flexShrink: 0}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          {formState.documentType === 'passport' ? 'Passport' : 'ID Card'}
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Number"
              value={formState.passport.number}
              onChange={(value) => setFormState({...formState, passport: {...formState.passport, number: value}})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Issuing Authority"
              value={formState.passport.issuedBy}
              onChange={(value) => setFormState({...formState, passport: {...formState.passport, issuedBy: value}})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">
            <TextInput
              placeholder="Date of Issue (YYYY-MM-DD)"
              value={formState.passport.issuedAt}
              onChange={(value) => setFormState({...formState, passport: {...formState.passport, issuedAt: value}})}
              isReadOnly={isFormPending || !isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />

            <TextInput
              placeholder="Date of Expiry (YYYY-MM-DD)"
              value={formState.passport.expiringDate}
              onChange={(value) => setFormState({...formState, passport: {...formState.passport, expiringDate: value}})}
              isReadOnly={isFormPending || !isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">

            <Select
              options={countriesOptions}
              onSelect={(value) => {
                setFormState({
                  ...formState,
                  residenceCountry: value
                })
              }}
              placeholder="Country of Residence"
              value={formState.residenceCountry}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Residence address"
              value={formState.residenceAddress}
              onChange={(value) => setFormState({...formState, residenceAddress: value})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />
          </div>
        </div>
      </div>

      <div style={{height: 50, flexShrink: 0}} />

      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Documents
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <FileUploadBlock
              file={uploadedFiles.personalData.documents.documentProof ?? undefined}
              title={"ID / Passport\nCopy"}
              icon="custom-scanner-1"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, personalData: {
                  ...uploadedFiles.personalData,
                  documents: {
                    ...uploadedFiles.personalData.documents,
                    documentProof: file
                  }
                }
              })}
            />

            <FileUploadBlock
              file={uploadedFiles.personalData.documents.addressProof ?? undefined}
              title={"Address\nproof"}
              icon="custom-scanner-1"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, personalData: {
                  ...uploadedFiles.personalData,
                  documents: {
                    ...uploadedFiles.personalData.documents,
                    addressProof: file
                  }
                }
              })}
            />
          </div>
        </div>
      </div>

      <div style={{height: 100}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending || !isSubmitAvailable}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon icon="arrow-right-lined" background={isSubmitAvailable ? "var(--soft-green)" : "#ddd"} />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
