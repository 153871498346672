import React, {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BgIcon from "../../../../../components/BgIcon";
import Button from "../../../../../components/Button";
import FileUploadBlock from "../../../../../components/FileUploadBlock";
import Loader from "../../../../../components/Loader";
import RowSelect from "../../../../../components/RowSelect";
import Select from "../../../../../components/Select";
import TextInput from "../../../../../components/TextInput";
import getMe from "../../../../../controllers/users/getMe.ts";
import apiClient from "../../../../../helpers/apiClient.ts";
import countriesOptions from "../../../../../helpers/countriesOptions.ts";
import {getRandomNumber} from "../../../../../helpers/misc.ts";
import onError from "../../../../../helpers/onError.ts";
import validate from "../../../../../helpers/validate.ts";
import styles from "./index.module.css";

import mainStore from "../../../../../store/main.ts";

/* Component */
export default function BBusinessStep() {
  if(!mainStore.user || (mainStore.user.type !== "business")) return null;

  /* Hooks */
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* Vars */
  const initialFormState = {
    legalData: mainStore.user.legalData,
    webSite: mainStore.user.webSite,
    about: mainStore.user.about,
    license: {
      number: "",
      issuedBy: ""
    }
  };

  /* Vars */
  const isSubmitAvailable = !(
    mainStore.user.filledKycs.includes("business")
    ||
    mainStore.user.acceptedKycs.includes("business")
  );

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [hasLicense, setHasLicense] = useState<boolean>(!!mainStore.user.license);
  const [uploadedFiles, setUploadedFiles] = useState({
    license: {
      proof: mainStore.user.license?.proof
    },
    legalData: {
      certificateOfRegistration: mainStore.user.legalData.certificateOfRegistration,
      addressProof: mainStore.user.legalData.addressProof
    },
    maa: mainStore.user.maa,
    registerExtract: mainStore.user.registerExtract
  });

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
      .then(() => {

        /* Validate client data */
        for(const validation of [
          validate("comment256", formState.legalData.address, "Registration address"),
          validate("comment256", formState.legalData.registrationNumber, "Registration number"),
          validate("pastDate", new Date(formState.legalData.registrationDate), "Registration date"),
          validate("comment0256", formState.webSite, "Website"),
          validate("comment256", formState.about, "About business")
        ]) {
          if(typeof validation === "string") {
            throw new Error(validation);
          }
        }
      })
      .then(() => {

        /* Send API request */
        return apiClient.post("/users/edit", {
          legalData: {
            ...formState.legalData,
            certificateOfRegistrationFileId: uploadedFiles.legalData.certificateOfRegistration?._id,
            addressProofFileId: uploadedFiles.legalData.addressProof?._id
          },
          license: hasLicense ? {
            ...formState.license,
            proof: uploadedFiles.license.proof?._id
          } : null,
          webSite: formState.webSite,
          about: formState.about,
          maaFileId: uploadedFiles.maa?._id,
          registerExtractFileId: uploadedFiles.registerExtract?._id
        });
      })
      .then(() => {

        /* Send submit request */
        return apiClient.post("/users/submitKyc", {
          kycStep: "business"
        });
      })
      .then(() => {

        /* On done */
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Success`,
          contents: `New data sent to our admins for verification`
        });

        /* Change step */
        $setSearchParams(new URLSearchParams({
          ...$searchParams,
          step: "founders"
        }));

        /* Update user data */
        return getMe();
      })
      .catch(onError)
      .finally(() => {
        setIsFormPending(false);
      });
  }

  /* DOM */
  return (
    <form onSubmit={onFormSubmit} style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Business information
        </div>

        <div className="page-form-segment-container">

          <div className="flex">
            <TextInput
              placeholder="Registration number"
              value={formState.legalData.registrationNumber}
              onChange={(value) => setFormState({
                ...formState,
                legalData: {...formState.legalData, registrationNumber: value}
              })}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Registration date (YYYY-MM-DD)"
              value={formState.legalData.registrationDate}
              onChange={(value) => setFormState({
                ...formState,
                legalData: {...formState.legalData, registrationDate: value}
              })}
              isReadOnly={isFormPending || !isSubmitAvailable}
              maskOpt={{
                mask: '0000-00-00'
              }}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">

            <Select
              options={countriesOptions}
              onSelect={(value) => {
                setFormState({
                  ...formState,
                  legalData: {
                    ...formState.legalData,
                    country: value
                  }
                });
              }}
              placeholder="Country of Registration"
              value={formState.legalData.country}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Registration address"
              value={formState.legalData.address}
              onChange={(value) => setFormState({...formState, legalData: {...formState.legalData, address: value}})}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />
          </div>

          <div style={{height: 30}} />

          <div className="flex">
            <TextInput
              placeholder="About the business"
              value={formState.about}
              onChange={(value) => setFormState({
                ...formState,
                about: value
              })}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />

            <TextInput
              placeholder="Website"
              value={formState.webSite}
              onChange={(value) => setFormState({
                ...formState,
                webSite: value
              })}
              isReadOnly={isFormPending || !isSubmitAvailable}
            />
          </div>
        </div>
      </div>

      {/* License */}
      {(hasLicense && !isSubmitAvailable) || isSubmitAvailable ? (
        <React.Fragment>
          <div style={{height: 50, flexShrink: 0}} />
          <div className="page-form-segment">

            <div className="page-form-segment-heading">
              License
            </div>

            <div className="page-form-segment-container">

              {isSubmitAvailable ? (
                <React.Fragment>
                  <div className="big-heading" style={{fontSize: "14pt"}}>Does your business require license?</div>

                  <div style={{height: 20}} />

                  <RowSelect
                    values={[hasLicense ? "yes" : "no"]}
                    options={[
                      {value: "yes", contents: "Yes"},
                      {value: "no", contents: "No"}
                    ]}
                    isSingleSelect={true}
                    onChange={([value]) => setHasLicense(value === "yes")}
                  />
                </React.Fragment>
              ) : null}

              {hasLicense ? (
                <React.Fragment>
                  <div style={{height: 30}} />

                  <div className="flex">
                    <TextInput
                      placeholder="License number"
                      value={formState.license.number}
                      onChange={(value) => setFormState({...formState, license: {...formState.license, number: value}})}
                      isReadOnly={isFormPending || !isSubmitAvailable}
                    />

                    <TextInput
                      placeholder="Issuing authority"
                      value={formState.license.issuedBy}
                      onChange={(value) => setFormState({
                        ...formState,
                        license: {...formState.license, issuedBy: value}
                      })}
                      isReadOnly={isFormPending || !isSubmitAvailable}
                    />
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      ) : null}

      {/* Documents */}
      <div style={{height: 50, flexShrink: 0}} />
      <div className="page-form-segment">

        <div className="page-form-segment-heading">
          Documents
        </div>

        <div className="page-form-segment-container">

          <div className="flex flex-wrap">

            <FileUploadBlock
              file={uploadedFiles.legalData.certificateOfRegistration ?? undefined}
              title={`
                Certificate of Incorporation
                (certified and apostilled)
              `}
              icon="plus-lined"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, legalData: {
                  ...uploadedFiles.legalData,
                  certificateOfRegistration: file
                }
              })}
            />

            <FileUploadBlock
              file={uploadedFiles.maa ?? undefined}
              title={`
                Memorandum and Articles of Association
                and any amending resolutions
                or comparable documents
                (certified and apostilled)
              `}
              icon="plus-lined"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles,
                maa: file
              })}
            />

            <FileUploadBlock
              file={uploadedFiles.legalData.addressProof ?? undefined}
              title={`
                Business address proof (e.g., bank statement with address, rent agreement, utility bill)
              `}
              icon="plus-lined"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles, legalData: {
                  ...uploadedFiles.legalData,
                  addressProof: file
                }
              })}
            />

            {hasLicense ? (
              <FileUploadBlock
                file={uploadedFiles.license.proof ?? undefined}
                title={`
                  Licenses, proof of activities, etc.(if applicable) (certified and apostilled)
                `}
                icon="plus-lined"
                acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onUpload={(file) => setUploadedFiles({
                  ...uploadedFiles,
                  maa: file
                })}
              />
            ) : null}

            <FileUploadBlock
              file={uploadedFiles.registerExtract ?? undefined}
              title={`
                Register extract (certified and apostilled)
              `}
              icon="plus-lined"
              acceptFiles="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onUpload={(file) => setUploadedFiles({
                ...uploadedFiles,
                registerExtract: file
              })}
            />
          </div>
        </div>
      </div>

      <div style={{height: "100%"}} />
      <div style={{height: 30, flexShrink: 0}} />

      <div className="flex" style={{justifyContent: "flex-end"}}>

        <Button additional={{isZeroed: true}} disabled={isFormPending || !isSubmitAvailable}>
          <span>Submit</span>

          {!isFormPending ? (
            <BgIcon icon="arrow-right-lined" background={isSubmitAvailable ? "var(--soft-green)" : "#ddd"} />
          ) : (
            <Loader spinsPerSecond={3} />
          )}
        </Button>
      </div>
    </form>
  );
}
