export enum Breakpoints {
  SM = 'sm',
  XL = 'xl',
}

export const screenSizesMap: { [key in Breakpoints]: number } = {
  [Breakpoints.SM]: 0,
  [Breakpoints.XL]: 1440,
};

export const getBreakpoint = (width: number): Breakpoints => {
  if (width >= screenSizesMap[Breakpoints.XL]) {
    return Breakpoints.XL;
  }
  return Breakpoints.SM;
};

export const isBreakpointGreaterThan = (
  currentBreakpoint: Breakpoints,
  minBreakpoint: Breakpoints,
  includeEqual: boolean = true
) => {
  if (includeEqual) return screenSizesMap[currentBreakpoint] >= screenSizesMap[minBreakpoint];
  return screenSizesMap[currentBreakpoint] > screenSizesMap[minBreakpoint];
};
