import cn from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';
import styles from './CardTransactions.module.css';
import { FC, useEffect } from 'react';
import { createCardTransactionsStore } from './../../../Cards/MyCards/store';
import { useNavigate } from 'react-router-dom';
import {
  getOperationSign,
  getOperationTypeText,
  isInflowOperation, isOutFlowOperation,
  transactionTextByStatusMap
} from './../../../../../helpers/transaction.helper.ts';
import { format } from 'date-fns';
import formatBalance from './../../../../../helpers/formatBalance.ts';
import { convertCentsToCurrency } from './../../../../../helpers/convert-cents-to-currency.helper.ts';
import Loader from '../../../../../components/Loader';
import Icon from '../../../../../components/Icon';
import { Tooltip } from 'react-tooltip';

interface ICardTransactionProps {
  cardId?: string;
}

export const CardTransactions: FC<ICardTransactionProps> = observer(({ cardId }) => {
  const transactionsStore = useLocalObservable(() => createCardTransactionsStore());
  const navigate = useNavigate();
  
  useEffect(() => {
    if (cardId) {
      transactionsStore.loadCardTransactions(cardId, { pageSize: 6, pageNumber: 1 });
    }
  }, [cardId]);
  
    return (
      <div className={styles.transactionWrapper}>
        <header className={styles.transactionHeader}>
          <h2 className={cn(styles.recentTransactions, 'tw-flex-centering tw-gap-1.5')}>
            Recent transactions
            <Icon
              id="recent-transaction-info"
              className="tooltip-icon tw-size-5.5 tw-cursor tw-cursor-pointer hover:tw-stroke-secondary"
              icon="info"
            />
            <Tooltip
              anchorSelect="#recent-transaction-info"
              place="bottom-start"
            >
              Card transactions may appear <br/>
              with a slight delay of up to 24 hours. <br/>
              If you don't see recent transactions, don't worry – the information will update soon.
            </Tooltip>
          </h2>
          <button
            className={styles.ViewHistoryBtn}
            disabled={!cardId}
            onClick={() => navigate(`${cardId}/transactions`)}
          >
            View History
          </button>
        </header>
        {!transactionsStore.loading && !!transactionsStore.transactions.length && (
          <div className={styles.Transactions}>
            {transactionsStore.transactions.map(({ id, type, amount, status, time, currency, localAmount, localCurrency }, index) => (
              <div key={id || index} className={styles.TransactionRow}>
                <div className={styles.TransactionCol}>
                  <div className={styles.TransactionOperation}>{getOperationTypeText(type)}</div>
                  <div className={styles.TransactionSecondaryText}>{format(new Date(time), 'dd-MM-yyyy')}</div>
                </div>
                <div className={cn(styles.TransactionCol, 'TextAlignEnd')}>
                  <div className={cn(
                    styles.TransactionOperation,
                    isInflowOperation(type) && styles.TransactionInflow,
                    isOutFlowOperation(type) && styles.TransactionOutflow,
                  )}>
                    {getOperationSign(type)} {formatBalance(convertCentsToCurrency(localAmount))} {localCurrency}

                    {currency !== localCurrency && (
                      <div className={styles.TransactionCurrency}>
                        {getOperationSign(type)} {formatBalance(convertCentsToCurrency(amount))} {currency}
                      </div>
                    )}
                  </div>
                  <div className={styles.TransactionSecondaryText}>{transactionTextByStatusMap[status]}</div>
                </div>
              </div>
            ))}
          </div>
        )
        }
        {transactionsStore.loading && (
          <div className={styles.TransactionsLoading}>
            <Loader />
          </div>
        )}
        {!transactionsStore.loading && !transactionsStore.transactions.length && (
          <div className={styles.noTransactions}>
            <p>You have not made any transactions yet</p>
          </div>
        )}
      </div>
    );
  }
);
