import Button from "../../../components/Button";
import styles from "./index.module.css";

export default function VLRestrictedPage() {

  /* DOM */
  return (
    <div className={styles.VLRestrictedPage}>

      <div className="white-block">

        <div>
          <div className="big-heading" style={{fontSize: '21pt'}}>
            Unlock Your Full Potential:
            <br />
            Complete Your KYC Now!
          </div>

          <div style={{height: 20}} />

          <div style={{fontWeight: 'bold'}}>
            Complete your KYC process now to unlock all the features and benefits of your VPlus account.
          </div>

          <div style={{height: 20}} />

          <Button additional={{isAutoWidth: true}} style={{width: 200}}>
            Upgrade Now
          </Button>
        </div>

        <div>
          <img src="/images/laptop-verify.svg" alt="" />
        </div>
      </div>
    </div>
  );
}
