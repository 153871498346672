import classNames from "classnames";
import React from "react";
import styles from "./index.module.css";

import Icon from "../Icon";

export default function BgIcon(props: {
  icon: string,
  isLightIcon?: boolean,
  background?: string
}) {

  /* DOM */
  return (
    <div
      className={classNames(styles.BgIcon, {isLightIcon: props.isLightIcon})}
      style={{background: props.background}}
    >
      <Icon icon={props.icon} />
    </div>
  );
}
