import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './index.module.css';

export interface SelectOptionModel<Value> {
  value: Value;
  label: string;
}

interface SelectProps<Value> {
  options: SelectOptionModel<Value>[];
  onSelect: (option: SelectOptionModel<Value>) => void;
  selected?: SelectOptionModel<Value>;
  label?: string;
  variant?: 'default' | 'border-bottom';
  id?: string;
  required?: boolean;
  disabled?: boolean;
  isBigger?: boolean;
}

function Select<TValue>(props: SelectProps<TValue>) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    SelectOptionModel<TValue> | undefined
  >(props.selected);
  
  useEffect(() => { setSelectedOption(props.selected) }, [props.selected]);

  const toggleAccordion = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const handleSelect = useCallback(
    (option: SelectOptionModel<TValue>) => {
      setSelectedOption(option);
      props.onSelect(option); // Тут передається option, який містить значення та ключ.
      setIsOpen(false);
    },
    [props.onSelect]
  );

  useOnClickOutside(
    ref,
    useCallback(() => setIsOpen(false), [])
  );

  return (
    <div className={classNames(styles.selectWrapper, { isBigger: props.isBigger })}>
      {props.label && (
        <label htmlFor={props.id} className={styles.label}>
          {props.label}
          {props.required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        className={classNames(styles.accordion, {
          [styles.accordionBorderBottom]: props.variant === 'border-bottom'
        })}>
        <button
          id={props.id}
          className={styles.accordionButton}
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-label={props.label}
          type="button"
          disabled={!props.options.length || props.disabled}>
          <span className={styles.selectedLabel}>
            {selectedOption ? selectedOption.label : ''}
          </span>
          <svg
            className={styles.arrowIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none">
            <path
              d="M4.5 6L0.602886 0.75L8.39711 0.749999L4.5 6Z"
              fill="#1E202E"
            />
          </svg>
        </button>
        {isOpen && (
          <div className={styles.panel} ref={ref}>
            <ul>
              {props.options.map((option, index) => (
                <li key={index} onClick={() => handleSelect(option)}>
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Select;
